.sidePanelDrawer {
	color: var(--text-primary);

	// Prevent wrapper, which covers viewport, from blocking side/nav bar clicks
	pointer-events: none;

	// Prevent drawers opened as children of other drawers from causing the first draw to shift over
	transform: translateX(0px) !important;

	:global .ant-drawer-mask {
		// offset mask so we can append to body but not cover the side/nav bars
		top: var(--navbar-height) !important;
		height: calc(100% - var(--navbar-height)) !important;
		pointer-events: auto;

		// only offset for the side bar if we have not reached the min page width
		@media (min-width: 1280px) {
			left: var(--sidebar-shadow-width) !important;
			width: calc(100% - var(--sidebar-width)) !important;
		}
	}

	:global .ant-drawer-content-wrapper {
		box-shadow: none !important;
		// offset drawer so we can append to body but not cover the nav bar
		top: var(--navbar-height) !important;
		height: calc(100% - var(--navbar-height)) !important;
		pointer-events: auto;

		.ant-drawer-content {
			background-color: var(--layer-01-v2);
		}

		.ant-drawer-body {
			padding: 32px 8px 0 24px;
		}
		// The following styles will be applied to all the side panels
		.genemod-tab-container {
			height: calc(100% - 32px);
			.ant-tabs {
				height: 100%;
			}
			.ant-tabs-bar {
				margin-right: 16px;
				margin-bottom: 0px;
			}
			.ant-tabs-content {
				height: calc(100% - 64px);
			}
			::-webkit-scrollbar {
				width: 8px;
			}
		}

		.sidePanel-description {
			margin-bottom: 32px;
			color: var(--text-secondary);
		}

		.sidePanel-infoView-layout {
			display: flex;
			flex-direction: row;
			height: 48px;
			margin-bottom: 26px;
			justify-content: flex-start;
			align-items: flex-start;
			color: var(--text-secondary-v2);
			.sidePanel-infoView-leftSide {
				color: var(--text-secondary-v2);
				width: 120px;
				margin-right: 24px;
				@media (min-width: 1920px) {
					margin-right: 48px;
				}
			}

			&:first-of-type {
				margin-top: 29px;
			}
		}

		.sidePanel-infoView-layout.multiline {
			height: auto;
			margin-bottom: 24px;
		}

		.sidePanel-analytics-table-style,
		.itemGroup-sidePanel-analytics {
			width: 100%;
			overflow-x: hidden;

			table {
				border-collapse: separate;
			}
			.ant-table .ant-table-tbody > tr {
				cursor: default;
			}
			.ant-table-tbody > tr > td:first-child {
				text-align: left;
			}

			.ant-table-row-level-0 > td:first-child {
				display: flex;
			}

			.ant-table-thead > tr > th:first-child {
				text-align: left;
			}

			//padding for parent
			.ant-table-tbody > tr.ant-table-row:first-child > td:first-child {
				align-items: flex-start;
				// add spacing b/w the caret icon and the text
				div > div:first-of-type {
					margin-right: 24px;
					div {
						margin-right: 0px;
					}
				}
			}

			.ant-table .ant-table-tbody > tr > td {
				color: var(--text-secondary);
			}

			//first row is white
			.ant-table-tbody > tr:first-child {
				color: var(--text-primary);
				border-top: none;
				td {
					color: var(--text-primary);
				}
			}
		}
		.itemGroup-sidePanel-analytics {
			.ant-table .ant-table-tbody > tr > td {
				border-bottom: none;
			}
			//padding for parent
			.ant-table-thead > tr > th:first-child {
				padding-left: 16px;
				align-items: center;
			}
		}

		.sidePanel-analytics-table-style-with-children {
			//padding for the children of the first column
			.ant-table-tbody > tr.ant-table-row > td:first-child {
				padding-left: 64px;
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			//padding for parent
			.ant-table-tbody > tr.ant-table-row:first-child > td:first-child {
				padding-left: 16px;
				align-items: center;
				// add spacing b/w the caret icon and the text
				div > div:first-of-type {
					margin-right: 24px;
					div {
						margin-right: 0px;
					}
				}
			}
		}
	}

	.sidepanelIconHeader {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		.sidepanelHeader {
			margin-left: 16px;
			margin-right: 16px;
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--text-secondary-v2);
		}
		.sidepanelHederNoMarginLeft {
			margin-left: 0;
		}
	}
}

.hiddenMaskDropShadow {
	:global {
		.ant-drawer-content {
			box-shadow: var(--shadow-container);
		}
	}
}
