// The overall table styling

.ant-table-fixed-header {
	// Remove broken scrollbar that shows up on fixed header tables and messes up the fixed header
	.ant-table-scroll .ant-table-header::-webkit-scrollbar {
		border: none !important;
	}

	.ant-table-body {
		// internally antd using a more specific selector to set background color than we normally use on our tables
		// setting this again but with !important for the fixed header tables
		background: transparent !important;
		background-color: transparent !important;
	}
}

.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
	border: none;
}

.ant-table {
	color: var(--text-primary);
	font: var(--font-body);
	line-height: normal;
	letter-spacing: 0.01px;

	table {
		// collapse the borders into a single border
		border-collapse: collapse;
	}
	.ant-table-header,
	.ant-table-body {
		background: transparent;
		background-color: transparent;
	}

	.ant-table-header-column {
		display: flex !important;
		> div .ant-table-column-title {
			display: flex;
		}
		.ant-table-column-sorters {
			flex-grow: 1;
			align-items: center !important;
			justify-content: space-between !important;

			.ant-table-column-sorter {
				width: 18px;
				align-content: center;
			}
		}
	}

	.ant-table-thead * :not(.checkbox-icon, .ant-checkbox-wrapper *) {
		border-left: none !important;
		border-right: none !important;
	}

	// Styling for the header row
	.ant-table-thead > tr > th {
		background: transparent;
		height: 48px;
		padding: 12px 16px;
		white-space: nowrap;
		overflow-x: visible;
		letter-spacing: 0.01px;
		color: var(--text-primary-v2);
		font-size: 14px !important;
		font-weight: var(--font-weight-medium) !important;
		text-align: left;
		border-bottom: var(--border-subtle-solid-v2);
		@media (min-width: 1919px) {
			padding: 16px;
			height: 56px;
		}
		.ant-table-header-column .ant-table-column-sorters:hover::before {
			background: transparent;
		}
	}
	.ant-table-thead
		> tr
		> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
		background: transparent;
	}

	.hasBorderTop {
		border-top: 2px solid var(--border-subtle-v2) !important;
	}

	// Styling for body rows
	.ant-table-tbody > tr > td {
		// borders inbetween body rows
		border-bottom: var(--border-subtle-solid-v2);
		height: 48px;
		padding: 12px 16px;
		// overflow behavior of each cell
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@media (min-width: 1920px) {
			height: 56px;
			padding: 16px;
		}
		&:is(.caret-down-col) {
			padding: 0 12px !important;
		}
	}

	.ant-table-column-has-sorters:hover {
		background: var(--current-layer-hover) !important;
	}

	//removing original ant design hover effects - remove at your own risk!
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: unset;
		color: unset;
	}

	.ant-table-thead > tr > th {
		.ant-table-header-column {
			overflow: initial;
			position: relative;
			display: flex;
			&:before {
				content: "";
				position: absolute;
				width: 1px;
				height: 22px;
				background-color: var(--border-subtle-v2) !important;
				left: -16px;
				align-self: center;
			}
		}

		&:first-of-type {
			.ant-table-header-column {
				&:before {
					width: 0 !important;
					height: 0 !important;
				}
			}
		}
	}

	.ant-table-fixed-left .ant-table-thead > tr > th {
		&:last-of-type {
			.ant-table-header-column {
				display: flex;
				&:before {
					width: 1px;
					height: 22px;
					align-self: center;
				}
				&:after {
					content: "";
					position: absolute;
					width: 1px;
					height: 22px;
					background-color: #d2d4df;
					right: -16px;
					align-self: center;
				}
			}
		}

		&:first-of-type {
			.ant-table-header-column {
				display: flex;
				&:before {
					width: 1px;
					height: 22px;
					align-self: center;
				}
			}
		}
	}

	.borderedHeader {
		.ant-table-header-column {
			&:before {
				width: 0 !important;
				height: 0 !important;
			}
			&:after {
				width: 0 !important;
				height: 0 !important;
			}
		}
		border: var(--border-subtle-solid-v2);
	}

	.ant-table-thead .borderedHeader {
		border-left: var(--border-subtle-solid-v2) !important;
		border-right: var(--border-subtle-solid-v2) !important;
	}

	//remove background color from the columns being sorted
	.ant-table-tbody > tr > td.ant-table-column-sort {
		background: unset;
	}

	// Adding the hover effect for body rows (when grouping the search results)
	.ant-table-tbody > tr {
		cursor: pointer;
		&:hover {
			background: var(--current-layer-hover);
		}
	}

	// Adding the hover effect for body rows (when ungrouping the search results)
	.ant-table-tbody > tr.ant-table-row-hover {
		background-color: var(--current-layer-hover);
	}

	//to return original styling for the table after removing ant design effects - remove at your own risk!
	.ant-table-tbody > tr.ant-table-row > td:first-child {
		background-color: transparent;
	}
	.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
		max-width: 73px;
	}
	@media (min-width: 3800px) {
		.ant-table-body-outer .ant-table-body-inner .ant-table-tbody {
			display: block;
		}
	}

	// remove the border-top radius
	.ant-table-thead > tr:first-child > th:first-child,
	.ant-table-thead > tr:first-child > th:last-child {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.ant-table-footer {
		border-radius: 0px;
	}

	.ant-table-tbody > tr.ant-table-row:nth-of-type(2) > td.emptyTable {
		overflow: visible;
	}

	.ant-table-placeholder {
		border-bottom: var(--border-subtle-solid-v2);
		opacity: 0;
	}

	// Adjust the filled color of the sort icon
	.ant-table-column-sorter .ant-table-column-sorter-inner {
		// when the sorting is applied
		.on {
			color: var(--text-tertiary-v2) !important;
		}
		.off {
			color: var(--border-subtle-v2) !important;
		}
	}
	.ant-table-column-title * {
		color: var(--text-primary-v2) !important;
		font-size: 14px;
		font-weight: var(--font-weight-medium) !important;
	}
	.ant-table-thead
		> tr
		> th
		.ant-table-column-sorter
		.ant-table-column-sorter-inner {
		height: 18px;
		margin-left: 8px;
		.ant-table-column-sorter-down {
			margin-top: 2px;
		}
	}

	.ant-table-tbody
		> tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
			.ant-table-row-selected
		)
		> td {
		background: transparent;
	}

	// Set the background to the grayscale-background color for fixed columns
	.ant-table-fixed-left table,
	.ant-table-fixed-right table {
		background-color: var(--background);
	}
	.ant-table-fixed-left .ant-table-thead > tr:first-child > th:first-child {
		border-left: none !important;
		border-right: none !important;
	}

	// Add the box shadow to the fixed columns
	.ant-table-fixed-right table {
		box-shadow: var(--shadow-container);
	}

	// For the fixed columns (ID, checkbox)
	.ant-table-fixed {
		border-top: none !important;
		border-right: 1px solid var(--border-subtle-v2) !important;

		.ant-table-header-column::after {
			height: 0 !important;
		}
	}
}

// remove the border for the small table in the demo
.ant-table-small {
	border: none !important;
}

.expandIcon {
	&:hover {
		background-color: var(--next-layer-hover);
		border-radius: 50%;
	}
}

.fixedHeaderResponsiveTable {
	height: 100%;
	.ant-table-placeholder {
		opacity: 1;
		background-color: transparent !important;
		color: var(--text-primary);
	}
}

.hideLastColumnBorder {
	.ant-table-row-cell-last {
		.ant-table-header-column::before {
			height: 0 !important;
		}
	}
}

.hideColumnBorders {
	.ant-table-header-column::before {
		height: 0 !important;
	}
}

.shadedHeader {
	.ant-table-thead > tr > th {
		background-color: var(--layer-03-v2);
		border: 2px solid var(--border-subtle-v2);
	}
}
