.printSpinner {
	position: absolute;
	z-index: 2;

	:global(.ant-spin-nested-loading),
	:global(.ant-spin-container) {
		position: unset;
	}

	:global(.ant-spin-container::after) {
		background: none;
	}

	:global(.ant-spin-blur) {
		opacity: 0.7;
	}

	:global(.ant-spin-text) {
		text-shadow: none !important;
		color: var(--text-primary);
		padding-top: 18px !important;
	}

	:global(.ant-spin) {
		max-height: unset !important;
	}
}

.printSpinnerContent {
	position: absolute;
	z-index: 1;
	background-color: var(--background);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
