.genemod-wide-wrapper {
	display: flex;
	justify-content: space-between;
	background-color: var(--layer-02);
	width: 668px;
	height: 100%;
	border-radius: 8px;
	box-shadow: var(--shadow-container);
	margin-bottom: 64px;

	.left {
		padding-top: 32px;
		padding-bottom: 41px;
		padding-left: 48px;
		padding-right: 48px;
		width: 67%;
		height: 100%;
		color: var(--text-primary);
	}

	.right {
		width: 33%;
		background-image: url("./images/wrapper-image.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 8px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

@media (min-width: 1920px) {
	.genemod-wide-wrapper {
		width: 841px;

		.left {
			padding-top: 48px;
			padding-left: 56px;
			padding-right: 56px;
		}
	}
}
