.previewAttachment {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.8);
	.header {
		width: 100%;
		min-height: 56px;
		height: 56px;
		padding: 0 24px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
		.headerIcons {
			display: flex;
			gap: 16px;
		}
	}
}

.attachmentFile {
	width: 100%;
	height: 100%;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading {
	position: absolute;
	left: 50%;
	top: 50%;
}

.closeCapture {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
