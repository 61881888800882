$transition: 0.2s;

.genemodAutoComplete {
	width: 100%;
	background-color: transparent;

	:global {
		.ant-select-selection {
			background-color: transparent;
			border: none !important;
			height: 36px;
			&__rendered {
				line-height: 36px !important;
			}
			&__placeholder {
				color: var(--text-tertiary);
			}
		}
		.ant-select-focused {
			border: none !important;
		}
		.ant-input,
		.ant-input:focus {
			height: 36px;
			color: var(--text-secondary);
			box-shadow: none !important;
			border: var(--border-subtle-solid) !important;
			border-radius: 4px;
		}
		.ant-input {
			background-color: var(--layer-02-v2) !important;
			&:focus-within {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}
		.ant-input[disabled] {
			color: var(--text-disabled) !important;
			&:hover {
				border: 0;
			}
		}

		.ant-select-selection__placeholder {
			z-index: 2;
			font: var(--font-label);
		}

		.ant-select-search {
			&::before {
				position: absolute;
				z-index: 2;
				width: 100%;
				height: 2px;
				bottom: 0;
				content: "";
				opacity: 0;
				transition: $transition;
			}
			&__noFocusUnderline::before {
				content: none;
			}

			&:focus-within {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;

				// Display the bottom line when the input is in focus
				&::before {
					opacity: 1;
					background-color: var(--border-active) !important;
				}
			}
		}

		.ant-select-focused .ant-select-selector,
		.ant-select-selector:focus,
		.ant-select-selector:active,
		.ant-select-open .ant-select-selector {
			border-color: none !important;
			box-shadow: none !important;
		}
	}
}

.genemodAutoComplete:global(.ant-select-disabled) {
	&:hover {
		border: none !important;
	}
}

.dropdown {
	background-color: var(--current-layer);
	:global {
		.ant-select-dropdown-menu {
			padding: 4px 0px;

			.ant-select-dropdown-menu-item-selected {
				background-color: var(--current-layer);
				&:hover {
					background-color: var(--current-layer-hover);
				}
			}
		}

		// Keyboard select bg color
		.ant-select-dropdown-menu-item-active:not(
				.ant-select-dropdown-menu-item-disabled
			) {
			background-color: var(--current-layer-hover);
		}

		.ant-select-dropdown-menu-item {
			padding: 14px 16px;
		}
	}
}
