.type-selector {
	width: 100%;
}

.reagent-wrapper {
	border: var(--border-subtle-solid);
	height: 36px;
	border-radius: 4px;
	background-color: var(--layer-02);
	position: relative;
	&::before {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 2px;
		bottom: 0;
		content: "";
		opacity: 0;
		transition: 0.1s;
	}
	&:hover,
	&:focus-within {
		box-shadow: none;
	}
	&:focus-within {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		&::before {
			opacity: 1;
			background-color: var(--border-active);
		}
	}
	.ant-select-auto-complete.ant-select {
		.ant-input {
			height: 36px;
			border: none;
			color: var(--text-primary);
			background-color: transparent;
			border-radius: 4px;
			&:focus {
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}
	}
}
.reagent-specific-autoselect {
	overflow: hidden;

	.ant-select-selection {
		background-color: var(--current-layer-input-background); //unset;
	}

	.ant-select-selection__placeholder {
		font: var(--font-body);
		margin-left: 16px !important; // make edit cursor line up with start of placehold text
	}

	// hide clear button
	.ant-select-selection__clear {
		background: transparent;
		svg {
			color: var(--text-secondary);
		}
	}
}

.reagent-specific-select-dropdown {
	padding: 8px 0px;
	border-radius: 1px;
	background-color: var(--layer-03);
	.ant-select-dropdown-menu-item,
	.ant-select-dropdown-menu-item-group-title {
		background-color: var(--layer-03);
		color: var(--text-primary);
		height: 32px;
		padding: auto 16px;
		font: var(--font-label);
		letter-spacing: 0.01px;

		&-active,
		&-active:not(.ant-select-dropdown-menu-item-disabled) {
			background-color: var(--layer-hover-03);
		}
	}

	.ant-select-dropdown-menu-item-group-title {
		padding-left: 8px;
	}

	.ant-select-dropdown-menu {
		padding: 0;
		border-radius: 1px;
		box-shadow: var(--shadow-container);
	}

	.ant-select-dropdown-menu-item-selected {
		background-color: var(--layer-hover-03) !important;
	}

	&__isLarge {
		.ant-select-dropdown-menu-item,
		.ant-select-dropdown-menu-item-group-title {
			height: 48px;
			padding: 12px 16px;
			font: var(--font-label);
			letter-spacing: 0.01px;
		}
	}
}

// Remove "x" in the antibiotics selector drop down
.antibiotics-selector [name="exit"] {
	display: none;
	.ant-select-selection--multiple .ant-select-selection__choice {
		background-color: transparent;
		border: var(--border-subtle-solid);
		color: var(--text-primary);
		border-radius: 13px;
	}
}
.antibiotics-selector {
	.ant-select-selection {
		height: fit-content;
	}
	.ant-select-selection--multiple .ant-select-selection__choice {
		background-color: transparent;
		border: var(--border-subtle-solid);
		color: var(--text-primary);
		border-radius: 13px;
	}
	.ant-select-selection--multiple .ant-select-selection__choice__remove {
		margin-left: 4px;
		font-size: 14px;
		color: var(--text-secondary);
		path {
			fill: var(--text-secondary);
		}
	}
}
