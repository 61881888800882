.listPreviewWrapper {
	display: flex;
}

.container {
	padding: 32px 48px 32px 32px;

	&__loading {
		min-width: 100%;
	}
}
.arrowAndName {
	height: 24px;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.navigationLevelText {
	&::first-letter {
		text-transform: uppercase;
	}
}

.rackPreview {
	margin-top: 72px;
	border-radius: 8px;
	width: fit-content;
	height: fit-content;
	background-color: var(--layer-popover);
	box-shadow: 0px 6px 20px 4px rgba(21, 25, 45, 0.15);
}

.navigationItem {
	max-width: 100px;

	&:not(:last-child) {
		cursor: pointer;
	}
}

.linkButton {
	span {
		color: var(--action-link) !important;
	}
}

.caption {
	color: var(--text-tertiary-v2) !important;
}
