.ant-radio-wrapper span.ant-radio + * {
	color: var(--text-secondary-v2);
	font: var(--font-body);
}

.ant-radio-disabled {
	.ant-radio-inner::after {
		background-color: var(--text-disabled) !important;
	}
}
