.formSection {
	margin-bottom: 16px;

	> *:not(.sectionHeader) {
		padding: 0 32px;
	}
}

.sectionHeader {
	padding: 24px 32px 16px 32px;
	border-top: 1px solid var(--border-subtle);
	> div {
		font-weight: bold;
	}
}

.notesSection {
	.notesContent {
		white-space: pre-wrap;
		word-break: break-word;
		padding-top: 13px;
		padding-bottom: 13px;
	}

	.notesClickToEdit {
		> * {
			min-width: 1px;
		}
		width: 100%;
		position: relative;
		display: flex;
		min-height: 48px;
		// Shift textarea to the left to align with the header.
		margin-left: -8px;

		> * {
			flex: 1;
		}
	}
}
.empty {
	color: var(--text-tertiary);
	line-height: 48px;
}

.attachmentSection {
	margin-bottom: 16px;

	:global {
		.ant-collapse-header-text {
			width: 100%;
		}
	}
}

.attachmentTitle {
	display: flex;
	align-items: center;
	gap: 4px;
	width: 100%;
	margin-left: -8px;
	svg {
		flex-shrink: 0;
	}
	.attachmentName {
		max-width: 264px;
		@media (min-width: 1920px) {
			max-width: 320px;
		}
	}

	.googleIconWrapper {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		.genemodIcon {
			width: 14px;
			height: 14px;
		}
	}
}
.uploadBtn {
	margin: 16px 0 16px 8px;
}

.itemAttachmentTable {
	:global .ant-table .ant-table-tbody > tr > td {
		&:first-child {
			padding-left: 8px;
			padding-right: 0px;
		}
	}

	&__view {
		:global .ant-table .ant-table-tbody > tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
}

.strainSelectClickToEdit {
	width: 100%;
	position: relative;
	left: -8px;
	display: flex;
	min-height: 36px;
}

.attachmentsTable {
	border-top: 1px solid var(--border-subtle);
	border-bottom: 1px solid var(--border-subtle);
	:global {
		.ant-table-footer {
			background-color: inherit;
			border-top: none;
			&::before {
				background: transparent;
			}
		}
		.ant-table-placeholder {
			padding: 0;
			border: none;
		}
	}
}
