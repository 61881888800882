.progress_activity {
	padding-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.progress_card {
	height: 65px;
	display: flex;
	gap: 2px;
	padding: 0 35px;
	margin-left: 3px;
	position: relative;

	.progress_circle {
		height: 12px;
		width: 12px;
		background-color: var(--accent-subtle);
		border-radius: 50%;
		position: absolute;
		left: 0;
		margin-top: 4px;

		&::after {
			content: "";
			height: 50px;
			width: 1px;
			background-color: var(--border-subtle);
			position: absolute;
			top: 15px;
			left: 50%;
			transform: translatex(-50%);
		}
	}

	.archive_circle {
		height: 12px;
		width: 12px;
		background-color: var(--accent-subtle);
		border-radius: 50%;
		position: absolute;
		left: 0;
		margin-top: 4px;
	}
}

.last_card {
	height: 65px;
	display: flex;
	gap: 2px;
	padding: 0 35px;
	margin-left: 3px;
	position: relative;

	.last_circle {
		height: 16px;
		width: 16px;
		position: absolute;
		left: 0;
		margin-top: 2px;
	}
}
