.labelWrapper {
	position: relative;
	background-color: white;
	* {
		color: black !important;
		font-family: "lato", sans-serif;
	}

	font-size: 15px;
}

.horizontalLayout {
	display: flex;

	> .vStackData {
		flex: 1;
	}
}

.verticalLayout {
	display: flex;
	flex-direction: column;

	> .vStackData {
		flex: 0;
	}
}

.vStackData {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.vStackCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
