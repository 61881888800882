$searchbar-border-radius: 4px;

.search {
	.genemod-search-container {
		width: 888px;
	}
}

.genemod-search-container {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	background-color: var(--layer-02-v2);
	border-radius: $searchbar-border-radius;
	border: 1px solid var(--border-invisible);
	position: relative;

	@media (min-width: 1920px) {
		height: 56px;
	}

	&-iconLeft {
		flex-direction: row-reverse;
	}

	// select panel
	.ant-select.ant-select-enabled {
		.ant-select-selection.ant-select-selection--single {
			background-color: var(--layer-02-v2);
			border: none;
			border-radius: $searchbar-border-radius;
			cursor: text;
		}
	}

	// icon
	.search-icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 12px;
		cursor: pointer;
	}

	// input
	.genemod-search-input {
		border: none;
		border-radius: $searchbar-border-radius;
	}

	.ant-select .ant-select-selection--single {
		height: 100%;
	}

	.ant-select-focused {
		border: none;
	}

	.ant-select-selection__rendered * {
		outline: none;
		box-shadow: none;
	}

	.ant-select-auto-complete {
		flex: 1;
		height: 100%;
		border-radius: $searchbar-border-radius;

		ul,
		li,
		input {
			height: 100%;
		}

		.ant-select-search__field__wrap {
			display: flex;
			align-items: center;
		}

		&:focus-within .ant-select-selection__placeholder {
			z-index: 0;
		}

		.ant-select-selection__placeholder {
			color: var(--text-tertiary);
			font: var(--font-body);
			line-height: normal;
			letter-spacing: 0.01px;
			z-index: 2;
			display: flex;
			align-items: center;
			margin-left: 24px;
			cursor: text;
		}

		.ant-input {
			// Ant margin left on placeholder + size of icon
			background-color: var(--layer-02-v2);
			border: none;
			position: relative;
			color: var(--text-secondary);
			font: var(--font-body);
			padding: 0 0 0 24px;
		}
	}

	.ant-select-dropdown {
		width: 100%;
		border-radius: $searchbar-border-radius + 1px;
		background-color: var(--next-layer);
	}

	.ant-select-dropdown-menu {
		padding: 8px 0px;
		background-color: var(--next-layer);
		border: var(--border-subtle-solid);
		border-radius: $searchbar-border-radius;
		box-shadow: var(--shadow-container);

		.ant-select-dropdown-menu-item,
		.ant-select-dropdown-menu-item-group-title {
			background-color: var(--next-layer);
			color: var(--text-primary);
			font: var(--font-body);
			letter-spacing: 0.01px;
			height: 48px;
			padding: 12px 24px;

			&-active,
			&-active:not(.ant-select-dropdown-menu-item-disabled) {
				background-color: var(--next-layer-hover);
			}
		}

		.lockedFreezer_class {
			background-color: var(--next-layer);
			pointer-events: none;
			transform: translateY(8px);
			height: 56px;
			border-bottom-left-radius: $searchbar-border-radius;
			border-bottom-right-radius: $searchbar-border-radius;

			.lockedFreezerBanner {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				transform: translateY(4px);
			}
		}
	}

	.autocomplete-result-row {
		display: grid;
		grid-template-columns: max-content 1fr max-content;
		gap: 12px;
		align-items: center;
	}

	&-small {
		height: 34px;
		border-radius: $searchbar-border-radius;
		width: 100%;

		.search-icon-container {
			margin: 4px;
			margin-right: 8px;
		}

		.ant-select-auto-complete {
			.ant-select-selection__placeholder {
				margin-left: 12px;
				margin-right: 0px;
				font: var(--font-label);
			}

			.ant-input {
				padding: 0 0 0 12px;
			}
		}
	}
}
