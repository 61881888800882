.view-edit-form-item {
	display: flex;
	min-height: 48px;

	.form-item-label {
		min-height: 48px;
		width: 148px;
		height: 100%;
		margin-right: 24px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
	}

	@media (min-width: 1920px) {
		.form-item-label {
			margin-right: 48px;
		}
	}

	.form-item-content {
		flex-grow: 1;
		overflow-wrap: anywhere;
		display: flex;
		flex-direction: column;

		// inline links
		a {
			color: var(--link-primary);
			&:hover {
				color: var(--link-primary-hover);
			}
		}
	}
	.form-item-content [class^="Input_container"] {
		margin-bottom: 0px;
	}

	.form-item-content-row {
		min-height: 48px;
		display: flex;
		align-items: center;
		font: var(--font-body);

		.value-display.textarea {
			white-space: break-spaces;
			padding: 12px 0px;
		}
	}

	.user-icon-and-name {
		display: flex;
		align-items: center;

		.username {
			margin-left: 3px;
		}
	}

	.price-input-fields {
		flex-grow: 1;
		display: flex;
		.currency-selector {
			margin-left: 8px;
			min-width: 154px;
		}
	}

	.reagent-specific-autoselect {
		width: 100%;
	}

	.genus-wrapper {
		width: 100%;
	}

	.species-wrapper {
		width: 100%;
	}

	.antibiotics-selector {
		width: 100%;
		.ant-select-selection__rendered {
			transform: translateY(-3px);
		}
		.genemod-select-container {
			width: 100%;
			.genemod-select {
				width: 100%;
			}
		}
	}
}
