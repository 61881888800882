.genemod-invite-team-wrapper {
	// The styling of the area to input emails
	.email-area {
		color: var(--text-primary);
		width: 100%;
		font: var(--font-body);
		letter-spacing: 0.01px;
		border-radius: 4px;
		border: var(--border-subtle-solid);
		.ant-select-selection-search {
			position: relative;
			min-width: 100%;
		}
		.ant-select-selection-search-input {
			background-color: transparent;
			border: none;
			outline: none;
			box-shadow: none;
			appearance: none;
			margin: 0;
			padding: 0;
			width: 100%;
			min-width: 6px;
		}
		.ant-select-selection-search-mirror {
			position: absolute;
			top: 0;
			left: 0;
			white-space: pre;
			visibility: hidden;
		}

		.ant-select-selector {
			height: 228px;
			background-color: var(--layer-03);
			flex-wrap: wrap;
			overflow-y: scroll;
			align-items: flex-start;
			padding: 9px 12px 10px 16px; // 4px reserved for the scrollbar
			&:hover {
				background-color: var(--layer-hover-03);
				transition: 0.2s;
			}
			.ant-select-selection-overflow {
				position: relative;
				display: flex;
				align-items: flex-start;
				flex: auto;
				flex-wrap: wrap;
				width: 100%;
				max-width: 100%;
				.ant-select-selection-overflow-item {
					flex: none;
					align-self: flex-start;
					width: 100%;
				}
			}
			.ant-select-selection-placeholder {
				position: absolute;
				white-space: normal;
				top: 9px;
				left: 16px;
				right: 16px;
				color: var(--text-tertiary);
			}
		}

		// Set the vertical and horizontal scrollbars
		::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		::-webkit-scrollbar-track-piece,
		::-webkit-scrollbar-track,
		::-webkit-scrollbar-corner {
			color: transparent;
			background-color: transparent;
		}
	}

	.ant-select-focused,
	.email-area-error {
		border-radius: 4px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		.ant-select-selector {
			border-radius: 4px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			border-bottom: 2px solid var(--button-primary);
			background-color: var(--layer-03);
			transition: 0.2s;
		}
	}

	.email-area-filled {
		border-radius: 4px;
		border: none;
		.ant-select-selector {
			border-radius: 4px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			background-color: var(--layer-03);
			transition: 0.2s;
		}
		&:hover {
			border: none;
		}
	}
	.email-area-error {
		.ant-select-selector {
			border-bottom: 2px solid var(--red);
		}
	}

	.email-errors {
		color: var(--red);
		margin-top: 4px;
		height: 16px;
		white-space: pre-line;
	}
}
