.container {
	height: 607px;
	position: relative;
}

.body {
	display: flex;
	height: 100%;
}

.right {
	flex: 1;
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: var(--layer-01-v2);
	border-bottom-right-radius: 8px;
}

.closeIcon {
	top: 50% !important;
	transform: translateY(-50%);
}
