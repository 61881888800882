.locationSelect {
	:global(.ant-select-selection__clear) {
		display: grid;
		right: 17px;
		place-items: center;
		transform: translateY(-1px);
	}
}

.boxSelectedItem {
	background-color: black;
}

.suffixArrows {
	background-color: var(--layer-03) !important;
}

.inputWidth {
	width: 100%;
}

:global(.ant-input-number-input-wrap) {
	width: 100%;
}

.boxNameContainer {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}
