.newBoxModal {
	padding-top: 8px;
	padding-bottom: 8px;

	.itemTypeContainer {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		gap: 10px;
		> div:first-child {
			width: 100%;
			:global .genemod-select-container {
				width: 100%;
			}
		}
	}

	.custom {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.customSize {
	display: flex;
	background-color: var(--layer-accent-01-v2);
	height: 48px;
	align-items: center;
	justify-content: space-evenly;
	border-radius: 4px;
}

.inputName {
	margin-bottom: 24px;
}
