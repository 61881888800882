.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	color: var(--text-primary);
}

.progressContainer {
	width: 240px;
}

.label {
	height: fit-content;
	width: 100%;
	margin-bottom: 4px;
	@media (min-width: 1920px) {
		height: 20px;
	}
}

.bar {
	display: flex;
	align-items: center;
	width: 100%;
}

.progress {
	height: 2px;
	width: 100%;
	background-color: var(--border-subtle);
	flex: 1 1 auto;

	&__inner {
		border-radius: 2px;
		height: 100%;
		transition: width 0.5s ease-out;
	}
}

.progressInner {
	border-radius: 500px;
}

.progressBackground {
	background-color: rgba(var(--neutral-gray-rgb), 0.3);
	height: 5px;
	border-radius: 500px;
}

.suffix {
	margin-left: 8px;
}

.flex {
	display: flex;
	align-items: center;
}

.progressBarWithComplete {
	position: relative;

	.checkIcon {
		position: absolute;

		&:before {
			position: absolute;
			border-radius: 50%;
			content: "";
			background-color: white;
			height: 14px;
			width: 14px;
			left: 5px;
			top: 5px;
		}

		&__complete {
			right: -12px;
			:before {
				content: "";
				background-color: white;
			}
		}

		&__inprogress {
			left: -12px;

			:before {
				content: "";
				background-color: var(--layer-hover-01);
			}
		}

		:hover {
			opacity: 0.8;
		}
	}
}
