.selectedOptionFlex {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.dropdownCheck {
		display: none;
	}
}

:global(.ant-select-dropdown-menu-item-selected)
	.selectedOptionFlex
	.dropdownCheck {
	display: unset;
}
