.shelf {
	padding: 16px 24px;
	border-bottom: 1px solid var(--border-invisible);
	min-height: 212px;
	display: flex;
	flex-direction: column;
}

.clickable {
	cursor: pointer;
}

.toolbar {
	display: flex;
	align-items: center;
	gap: 8px;
}

.inputName {
	margin-bottom: 24px;
}

.options {
	min-height: 24px;
	min-width: 24px;
	border-radius: 4px;
	border: 1px solid var(--border-invisible);
	display: grid;
	place-items: center;
	background-color: var(--layer-03);
}

.optionsIcon {
	& svg {
		height: 14px;
		width: 14px;
	}
}

.grid {
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 12px;
	margin-top: 12px;
	padding: 0 8px;
}

.rowEmpty {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.clickToEdit {
	font-weight: 700;
	min-width: 120px;
}

.customSize {
	display: flex;
	background-color: var(--layer-accent-01-v2);
	height: 48px;
	align-items: center;
	justify-content: space-evenly;
	border-radius: 4px;
}

.addStorageBtn {
	background: var(--layer-03-v2) !important;
}

.editable {
	:global(.row-header) {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
