.container {
	background-color: var(--layer-01);
	padding: 2px;
	padding-bottom: 12px;
	color: var(--text-primary);
	text-align: center;
	user-select: none;
}

.table {
	border-collapse: separate;
	border-spacing: 2px;
	margin-bottom: 8px;
	user-select: none;

	td {
		height: 12px;
		width: 12px;
		background-color: var(--layer-03);
		cursor: pointer;
		user-select: none;
		border: 1px solid var(--border-subtle);
	}

	.inRange {
		background-color: var(--button-primary);
		border: none;
	}
}
