.notesSectionBody {
	.notesDisplay {
		white-space: pre-wrap;
		word-break: break-word;
		padding-top: 13px;
		padding-bottom: 13px;
		&.empty {
			color: var(--text-tertiary);
			text-align: center;
			line-height: 48px;
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}
}

.inputClickToEdit {
	textarea {
		width: 100% !important;
	}
	> * {
		min-width: 1px;
		flex: 1;
		line-height: 32px;
	}
	width: 100%;
	position: relative;
	left: -8px;
	display: flex;
	min-height: 36px;
	margin-bottom: 0px !important;
}
