.boxWrapper {
	display: flex;
	padding: 15px 12px 12px 15px;
	border-radius: 8px;
	box-shadow: var(--shadow-container);
	background-color: var(--layer-hover-01);

	.cells {
		display: grid;
		gap: 1px;

		.headerCell {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 6px;
			line-height: 18px;
			letter-spacing: 0.01px;
		}

		.cell {
			outline: 1px solid var(--border-subtle);
			background-color: var(--layer-02);
			display: flex;
			padding: 7px 2px;
		}

		.itemCell {
			display: -webkit-box !important;
			font-size: 6px;
			line-height: 8px;
			letter-spacing: 0.01px;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			text-align: left;
			white-space: pre-wrap;
			word-break: break-word;
		}
	}
}
