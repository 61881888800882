$background-color: var(--layer-03);

.genemod-popover {
	.ant-popover-inner-content {
		box-shadow: var(--shadow-container);
		background-color: var(--current-layer);
		font: var(--font-body);
		letter-spacing: 0.01px;
		color: var(--text-primary);
		padding: 8px;
	}

	.ant-popover-arrow {
		border-right-color: var(--current-layer) !important;
		border-bottom-color: var(--current-layer) !important;
		border-top-color: var(--current-layer) !important;
		border-left-color: var(--current-layer) !important;
	}
}
