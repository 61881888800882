.animationContainer {
	height: 102px;
	width: 102px;
	background-image: url(./empty-flask-dark.png);
	background-size: cover;
	z-index: 0;
	$waterFColor: rgba(71, 199, 177, 0.9);
	$waterBColor: rgba(18, 189, 218, 0.9);
	position: relative;
	.box {
		height: 56px;
		width: 56px;
		transform: translate(41%, 60%);
		border: transparent;
		background-color: transparent;
		border-radius: 100%;
		overflow: hidden;

		// Water moving from bottom to top
		.water {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom right,
				#47c7b1,
				#12bdda 75%
			);
			transition: all 0.2s;
			animation-fill-mode: forwards;
			// Wave on top of the water
			&_wave {
				width: 400%;
				position: absolute;
				bottom: 100%;
				&_back {
					right: 0;
					fill: $waterBColor;
					margin-bottom: -1px;
					animation: wave-back 0.8s infinite linear;
				}
				&_front {
					left: 0;
					fill: $waterFColor;
					margin-bottom: -1px;
					animation: wave-front 1.2s infinite linear;
				}
			}
		}
	}
	&__light {
		background-image: url(./empty-flask-light.png);
	}
}
@keyframes wave-front {
	100% {
		transform: translate(-50%, 0);
	}
}

@keyframes wave-back {
	100% {
		transform: translate(50%, 0);
	}
}

@keyframes fill {
	0% {
		opacity: 0.5;
		transform: translate(0, 120%);
	}
	100% {
		transform: translate(0, 0%);
	}
}
