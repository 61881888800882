.genemod-popup-message {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: #00000063;
	z-index: 1001;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: appear 0.6s ease backwards;
	animation-delay: 0.2s;

	@keyframes appear {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.content {
		position: relative;
		background-color: white;
		border-radius: 28px;
		height: calc(50% - 20px);
		width: calc(50% - 40px);
		display: flex;
		overflow: hidden;
		padding: 10px 20px;

		.left,
		.right {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.right {
			flex-direction: column;
			align-items: center;
			width: calc(50% - 40px);
			margin-left: auto;
			padding: 15px 0;

			.title,
			.message {
				text-align: center;
			}

			.title {
				color: #444444;
				text-align: center;
				font-size: 38px;
			}

			.message {
				color: #b2b1b1;
				font-size: 20px;
				width: 80%;
			}

			.button {
				background-color: #64c1c0;
				border-radius: 35px;
				color: white;
				width: 150px;
				height: 50px;
				margin-top: auto;

				&:hover {
					color: white;
					outline: none;
				}
			}
		}
	}
}

.genemod-popup-message-hidden {
	animation: disappear 0.6s ease forwards;
	@keyframes disappear {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			display: none;
		}
	}
}
