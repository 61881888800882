.activity {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	.noActivity {
		line-height: 48px;
		color: var(--text-tertiary);
	}
}

.dateContainer {
	margin-bottom: 12px;
}

.activityTimestamp {
	color: var(--text-tertiary-v2) !important;
	margin-bottom: 12px;
	font-weight: bold !important;
	text-transform: uppercase;
}

.logLayout {
	display: flex;
	align-items: flex-start;
	padding: 16px 0;

	.avatarBox {
		width: 24px;
		height: 24px;
		margin-right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		object-fit: contain;
	}
	.activityDetails {
		text-overflow: ellipsis;

		.reactionPath {
			color: var(--text-secondary);
			word-break: break-word;
		}

		.timestamp {
			color: var(--text-secondary);
			margin-top: 2px;
		}
		.action {
			color: var(--text-primary);
			display: flex;
			white-space: normal;
			margin-bottom: 2px;
			margin-top: 8px;
			word-break: break-word;
		}
	}
}
