.input {
	border-radius: 4px;
	justify-content: flex-start;

	&:hover {
		border: 1px solid var(--border-active);
		border-radius: 4px;
	}

	&:focus-within {
		border: 1px solid var(--border-active);
		border-radius: 4px;

		&::before {
			opacity: 1;
			background-color: var(--border-active) !important;
		}
	}

	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: var(--padding-8xs) var(--padding-xs);
	z-index: 0;
	border: var(--border-subtle-solid);
	background: var(--layer-02-v2);
	color: var(--text-secondary);
	:global {
		.ant-input-number-handler-wrap {
			background: transparent;
			border-left-color: var(--text-tertiary);
		}
		.ant-input-number-handler-down {
			border-top-color: var(--text-tertiary);
		}
	}
}
