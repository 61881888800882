.teamPanelContainer {
	height: 100%;
	width: 100%;
	background-color: var(--layer-01);
	color: var(--text-primary);
	position: absolute;
	top: 0;
	left: 0;
	.header,
	.teamRow,
	.addWorkspace {
		height: 56px;
		padding: 0 16px 0 24px;
	}
	.header {
		margin-bottom: 20px;
		margin-top: 33px;
		padding-left: 24px;
		padding-right: 24px;
		cursor: default;
		> div {
			line-height: 56px;
		}
	}
	.teamList {
		height: calc(100% - 72px);
		overflow: hidden;
		overflow-y: auto;
	}
	.teamRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 24px;
		width: 100%;
		gap: 12px;
		cursor: pointer;

		&:hover {
			background-color: var(--layer-hover-01);
			.switch {
				display: flex;
				transition: 0.15s;
				cursor: pointer;
			}
		}
	}
	.teamInfo {
		display: flex;
		align-items: center;
		gap: 12px;
		width: 90%;

		.teamName {
			max-width: 186px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			@media (min-width: 1920px) {
				max-width: 204px;
			}
		}
	}
	.current {
		font-style: italic;
		color: var(--text-secondary);
	}
	.switch {
		display: none;
	}
	.addWorkspace {
		display: flex;
		align-items: center;
		cursor: pointer;
		color: var(--button-primary);
		position: absolute;
		bottom: 12px;
		padding-left: 32px;
	}
}

.collapsibleOrgRowContainer {
	width: 100%;

	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 24px;
		background-color: var(--layer-02);
		cursor: pointer;
		gap: 12px;
	}
}

.newWorkspaceContainer {
	display: flex;
	justify-content: center;
}

.newWorkspace {
	background-color: var(--border-subtle);
	border-radius: 14px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	overflow: hidden;
	text-align: center;
	width: 276px;
}

.img {
	position: absolute;
	top: -50px;
	left: 0;
}

.fixHeight {
	height: calc(100% - 340px) !important;
}
