.pageNumbersContainer :global {
	.ant-pagination-options-size-changer .ant-select-selector {
		background-color: var(--layer-02-v2);
		border-color: var(--border-subtle);
	}
	.ant-select-arrow {
		svg {
			fill: var(--text-secondary-v2);
		}
	}
	.ant-pagination-item-active {
		border-color: var(--informative);
		border-radius: 2px;
	}
	.ant-pagination-item {
		border-radius: 2px;
		background-color: transparent;
	}
	.ant-pagination-item a {
		color: var(--text-secondary-v2);
		font: var(--regular-v2);
		line-height: 30px;
		border-radius: 2px;
	}
	.ant-select-dropdown {
		background-color: var(--layer-popover);
		.ant-select-item-option-content {
			color: var(--text-secondary-v2);
		}
		.ant-select-item-option-selected {
			background-color: var(--layer-hover-02-v2);
		}
	}
	.ant-pagination-mini .ant-pagination-item a {
		line-height: 22px;
	}
	.ant-pagination-item-active a {
		color: var(--informative);
	}
	.ant-pagination-prev svg,
	.ant-pagination-next svg {
		color: var(--text-secondary-v2);
	}
	.ant-pagination-disabled svg {
		color: var(--text-disabled);
	}
	.ant-pagination-jump-next svg {
		color: var(--text-tertiary);
	}
	.ant-select-selection-item {
		color: var(--text-secondary-v2) !important;
		font: var(--regular-v2);
	}
	.ant-pagination-options-quick-jumper {
		font: var(--regular-v2);
	}
}
