.genemod-userlist {
	display: flex;
	align-items: center;

	.avatar {
		position: relative;
		border: 1px solid white;
		margin-right: 2px;
	}

	.ellipsis {
		align-self: flex-end;
		position: relative;
		height: 12px;
	}

	.edit-btn {
		border-radius: 50%;
		border: 2px solid #3bafda;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.7;
		transition: 0.2s;

		&:hover {
			opacity: 1;
		}
	}
}

.userlist-modal-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.userlist-modal {
		margin: 0;
		min-height: 520px;
		height: 50%;
		top: unset;

		.ant-modal-content {
			height: 100%;
			width: 100%;
			padding: 5px;
			background-color: #21262c;
			margin: 0;

			> div,
			.ant-modal-title {
				color: white;
				background-color: #21262c;
			}

			.ant-modal-title {
				font-size: 18px;
			}

			.ant-modal-header {
				border-bottom: 1px solid #2d343e;
			}

			.ant-modal-footer {
				border-top: 1px solid #2d343e;
			}

			.ant-modal-header,
			.ant-modal-footer {
				height: 55px;
			}

			.ant-modal-body {
				height: calc(100% - 110px);
				padding: 15px;
			}

			.ant-modal-header,
			.ant-modal-footer,
			.ant-modal-body {
				width: 100%;
			}
		}
	}
}
