/** Shared stylings of the table-cell */
#freezer .table-cell,
.rack-view-change-dimension-modal .table-cell,
.rearrange-modal .table-cell {
	margin: 8px 4px;
	color: var(--text-primary);
	background-clip: content-box;
	position: relative;
	cursor: pointer;
	border-radius: 4px;
	border: var(--border-subtle-emph-width) solid transparent;
	@media (min-width: 1920px) {
		margin: 12px;
	}

	&__focused {
		.box-handle .cell-options {
			display: block;
			opacity: 1;
		}
		border-color: var(--border-active);
	}

	.box-handle {
		width: 100%;
		height: 28px;
		box-shadow: var(--box-card-handle-shadow);
		@media (min-width: 1920px) {
			height: 32px;
		}
		&__focused {
			.cell-options {
				display: block;
				opacity: 1;
			}
		}
	}

	.cell-content {
		width: 100%;
		height: calc(100% - 28px);
		position: relative;
		vertical-align: middle;
		text-align: center;
		@media (min-width: 1920px) {
			height: calc(100% - 32px);
		}
	}

	.cell-options {
		display: none;
		opacity: 0;
		position: absolute;
		cursor: pointer;
		top: 2px;
		right: 2px;
		margin-left: auto;
		&:hover {
			opacity: 1;
		}
	}

	.cell-name,
	.cell-name-in-modal {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(100% - 16px);
		height: calc(100% - 16px);
		text-align: center;
		@media (min-width: 1920px) {
			width: calc(100% - 32px);
			height: calc(100% - 48px);
		}

		letter-spacing: 0.01px;
		display: -webkit-box !important;
		word-break: break-word;
		white-space: pre-wrap;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-height: 20px;
		height: 40px;
	}
	.empty-cell {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
		border: 1px dashed rgba(var(--border-active-rgb), 0.3);

		&-hovered {
			background-color: var(--ui_level01_hover) !important;
		}
	}
	&.not-empty {
		background-color: var(--layer-02);
		box-shadow: 0px 2px 3px var(--shadow-container-color);
	}
}

/** Stylings only for RackView */
#freezer .freezer-contents .right {
	height: 100%;
	padding-bottom: 32px;

	.parent-container {
		padding-top: 72px;
		padding-right: 12px;
		padding-left: 12px;
		height: 100%;

		@media (min-width: 1920px) {
			padding-top: 76px;
		}

		.rack-header {
			width: auto;
		}
		.rack-contents {
			display: flex;
			border-radius: 16px;
			padding: 12px 0px 12px 12px;
			background-color: var(--layer-rack);
			box-shadow: var(--shadow-container);
			flex-direction: column;
			height: 100%;
			max-height: 100%;

			.middle {
				height: 100%;
				overflow-y: scroll;
			}
			::-webkit-scrollbar-thumb {
				background-color: var(--freezer_scrollbar_bg);
				border: solid 1px var(--freezer_scrollbar_border);
			}
		}

		.table {
			flex: 1 1 auto;
			display: flex;
			flex-wrap: wrap;
			background-color: var(--layer-rack);
			.table-cell {
				&:hover {
					.cell-options {
						display: block;
						opacity: 1;
					}
				}
				.cell-content {
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}
				.plus-icon {
					opacity: 0;
				}
				.empty-cell {
					box-shadow: none;
					background-color: var(--layer-rack);
					border-style: dashed;
					&:hover {
						border: 2px solid var(--border-active);
						background-color: var(--layer-rack);
						.plus-icon {
							opacity: 1;
						}
					}
					.plus-icon {
						opacity: 0.5;
					}
				}
				&.not-empty {
					&:hover {
						border-color: var(--border-active);
					}
				}
			}
		}
	}
}

.noHover {
	&:hover {
		border: 1px dashed rgba(var(--border-active-rgb), 0.3) !important;
	}
}

/** Stylings for both rearrange & change dimension modals */
.rack-view-change-dimension-modal,
.rearrange-modal {
	.ant-modal-content {
		height: 100%;
		width: 100%;
		box-shadow: none;
		border: none !important;
		background-color: transparent !important;

		.ant-modal-footer {
			background-color: transparent;
		}

		.ant-modal-body {
			height: 92%;
			background-color: transparent;

			.parent-container {
				.rack-header {
					display: none;
				}

				.rack-contents {
					display: flex;
					background-color: transparent;
					flex-direction: column;
					justify-content: center;
					height: 100% !important;
					width: 100% !important;

					.middle {
						display: flex;
						background-color: transparent;
						max-height: 100%;
						justify-content: center;

						.table {
							display: flex;
							flex-wrap: wrap;
							background-color: var(--layer-rack);
							box-shadow: var(--shadow-container);
							border-bottom-right-radius: 8px;
							.table-cell {
								background-clip: content-box;
								position: relative;
								border-radius: 4px;
								margin: 8px;
								@media (min-width: 1920px) {
									margin: 12px;
								}
								.empty-cell {
									cursor: default;
									background-color: transparent;
									box-shadow: none;
									.plus-icon {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.rearrange-modal .ant-modal-content .genemod-modal {
	border: none;
	background-color: transparent;
}
/** Special stylings rearrange modal */
.rearrange-modal {
	.table.table-editing {
		justify-content: center;
		padding: 16px 0;
	}
	.table {
		border-radius: 8px;
		@keyframes shake {
			0% {
				transform: rotate(0deg);
			}
			25% {
				transform: rotate(3deg);
			}
			50% {
				transform: rotate(0deg);
			}
			75% {
				transform: rotate(-3deg);
			}
			100% {
				transform: rotate(0deg);
			}
		}

		.table-cell.box__rearranging.not-empty {
			animation: shake 0.4s infinite both;
			cursor: move;
		}
	}
}
/** Special stylings for change dimension modal */
.rack-view-change-dimension-modal {
	.rack-contents {
		border-radius: 8px;
	}
	.top {
		height: 42px;
		width: 100%;
		padding-left: 66px;
		padding-right: 24px;
		background-color: var(--layer-02);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		display: grid;
		gap: 16px;
		justify-content: center;
		.col-header {
			flex: 1;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			color: var(--text-secondary);
			user-select: none;
			&__selected {
				background-color: var(--layer-rack);
			}
		}
	}

	.left-col {
		display: grid;
		gap: 16px;
		padding-top: 24px;
		background-color: var(--layer-02);
		border-bottom-left-radius: 8px;
		width: 42px;
		padding-bottom: 32px;
		.row-header {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 4px 0 4px 16px;
			cursor: pointer;
			color: var(--text-secondary);
			&__selected {
				background-color: var(--layer-rack);
			}
		}
	}
	.table.table-editing {
		justify-content: center;
		padding-right: 16px;
		padding-bottom: 16px;
		border: none !important;
		box-shadow: none !important;
	}
	.table-cell.box-handle {
		height: 0px;
		border: none;
	}
	.table-cell.not-empty {
		box-shadow: none;
		border: 1px solid var(--border-subtle);
		pointer-events: none;
		opacity: 0.4 !important;
	}
}
.rack-view-change-dimension-modal .ant-modal-body,
.rearrange-modal .ant-modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}
