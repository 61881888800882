@use "../../Table.module.scss" as table;

.cell {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	text-align: center;
	color: var(--text-secondary-v2);
	background-color: var(--layer-02-v2);
	border: 1px solid var(--border-subtle-v2);
	border-top: 0px;
	border-left: 0px;
	padding: 4px;
	user-select: none;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&.emptyCell {
		background-color: var(--layer-02-v2);
		display: flex;
		justify-content: center;
		align-items: center;

		&.viewOnly {
			pointer-events: none;
		}

		.emptyCellPlusIcon {
			display: none;
		}
	}
	&.hoveredCell {
		background-color: var(--layer-hover-02-v2);
		&:not(.viewOnly) .emptyCellPlusIcon {
			display: unset;
		}
	}
	&.cutModeCell {
		background-color: var(--layer-accent-02-v2) !important;
	}
}

.cellContents {
	font: var(--font-body);
	font-size: var(--small-v2);
	// for text overflow in cell
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	line-break: anywhere;
	width: 100%;

	@media (min-height: 1000px) {
		-webkit-line-clamp: 5;
	}

	&.less {
		line-height: 14px;
		-webkit-line-clamp: 3;

		@media (min-height: 1000px) {
			-webkit-line-clamp: 4;
		}
	}
}

.cutModeCellContent {
	color: var(--text-ghost); // here
}

.loadingCell {
	justify-content: flex-start !important;
}

.focusedCell {
	border: 2px solid var(--border-active);
	background-color: var(--layer-hover-02-v2) !important;
	padding: 3px;

	// Correct for border not actually being in the upper left portion of the cell
	margin-left: -1px;
	margin-top: -1px;

	&_broken {
		border: 2px solid var(--border-active);
		background-color: table.$freezer_cell_focused;
		padding: 3px;
	}
}

.bookmarkIcon {
	position: absolute !important;
	right: 4px;
	bottom: 3px;
}

.resultCell {
	background-color: var(--layer-hover-02-v2);
}

.resultCell.focusedResult {
	background-color: var(--layer-hover-02-v2);
}
