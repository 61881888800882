.betaTag {
	width: 54px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--layer-rack);
	border-radius: 50px;
	color: var(--text-secondary);
	&__dark {
		background-color: rgb(var(--layer-rack-rgb), 0.7);
		color: var(--text-inverse);
	}
}
