.wrapper {
	display: flex;
	gap: 8px;
	align-items: center;
}

.plusNumber {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.teamCard {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 9px 16px;
}
