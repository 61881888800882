@use "./TableGrid.module.scss" as grid;

$freezer_cell_selected: rgba(var(--layer-hover-01-rgb), 0.12);
$freezer_cell_focused: rgba(var(--layer-hover-01-rgb), 0.24);

.infoWrapper {
	width: 100%;
	height: 100%;

	.boxWrapper {
		height: 100%;
		width: 100%;
		gap: 48px;
		display: flex;
		justify-content: center;
		padding: 32px;
		padding-top: 24px;
	}
}

:global {
	///////////////////////////////////
	// Reminder Modal for Edge cases //
	///////////////////////////////////
	div.ant-modal-wrap.ant-modal-centered
		> .ant-modal.reminder-modal.genemod-modal {
		.ant-modal-content {
			.ant-modal-footer {
				.genemod-modal-default-footer {
					.ant-btn.genemod-button.ghost {
						display: none;
					}
				}
			}
		}
	}
}
