.wrapper {
	background: var(--layer-02-v2);
	border-radius: 8px;
	box-shadow: 0px 2px 6px 0px rgba(21, 25, 45, 0.12);
}

.main {
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 12px 24px;
	user-select: none;
	height: 58px;
}

.mainOpen {
	border-bottom: 1px solid var(--border-subtle-v2);
}

.premade {
	background-color: var(--gray-03);
	border: 1px solid var(--gray-06);
	border-radius: 4px;
	padding: 0 8px;
	height: 22px;
	display: flex;
	align-items: center;
	margin-left: 8px;
}

.list {
	display: flex;
	flex-direction: column;
	height: 0;
	overflow: hidden;
	padding: 0;
}

.listOpen {
	height: 100%;
	padding: 8px 12px;
}

.row {
	display: flex;
	align-items: center;
	gap: 8px;
	height: 48px;
	padding: 0 28px 0 0;
}

.menuItem {
	display: flex;
	align-items: center;
	gap: 8px;
}

.edit {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-left: auto;
	cursor: pointer;
}

.editing {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.chevron {
	transition: transform 0.2s ease;
}

.chevronOpen {
	transform: rotate(90deg);
}

.radioContainer {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-left: auto;
}

.radio {
	margin-right: 0;

	& span {
		padding: 0 !important;
	}
}

.rowContainer {
	border-bottom: 1px solid var(--border-subtle-v2);
}
