// Normalized path length, must have px or the keyframe calc does not work for some reason
.normalizedPathLength {
	--path-length: 100px;
}

.connectingPath {
	stroke-dasharray: var(--path-length);
	stroke-dashoffset: var(--path-length);
	animation: connectingKeyframe 1.5s linear;
	animation-direction: reverse;
	animation-iteration-count: infinite;
}

@keyframes connectingKeyframe {
	to {
		// We need to go through 2 "dash" lengths, plus one because of the offset
		stroke-dashoffset: calc(var(--path-length) * 3);
	}
}

.checkPath {
	stroke-dasharray: var(--path-length);
	animation: checkPathKeyframe 0.5s linear;
	animation-direction: reverse;
}

@keyframes checkPathKeyframe {
	to {
		stroke-dashoffset: var(--path-length);
	}
}
