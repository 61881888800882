.simpleTable {
	border-collapse: collapse;
	width: fit-content;
	grid-area: table;
}

.simpleTableCell {
	border: 1px solid var(--border-subtle-v2);
	padding: 7px 8px;
	position: relative;
	max-width: 250px;
	user-select: none;
}

.resizeBar {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	height: calc(100% + 1px);
	width: 2px;
	background-color: var(--border-active);
	z-index: 1;
	cursor: col-resize;
}

.cellButton {
	position: absolute;
	top: -8px;
	width: 16px;
	border: 1px solid var(--border-subtle-v2);
	border-radius: 2px;
	cursor: pointer;
}

.vertical {
	left: 50%;
}

.horizontal {
	top: 50%;
	left: -8px;
	transform: translateY(-50%);
}

.menuLabel {
	display: flex;
	align-items: center;
	gap: 8px;
}

@mixin addButtons {
	display: grid;
	background-color: var(--layer-01-v2);
	border: 1px solid var(--border-subtle-v2);
	place-items: center;
	cursor: pointer;
}

.addRowButton {
	@include addButtons();
	height: 16px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	border-top: none;
	grid-area: rowButton;
}

.addColumnButton {
	@include addButtons();
	grid-area: columnButton;
	border-left: none;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.tableWrapper {
	display: grid;
	width: fit-content;
	grid-template-areas:
		"title title"
		"table columnButton"
		"rowButton .";
	padding: 10px;
	cursor: pointer;
	border-radius: 4px;
}

.tableSelected {
	background-color: var(--layer-hover-01-v2);
}

.draggableButton {
	position: absolute;
	height: 8px;
	width: 8px;
	background-color: var(--brand-08);
	border-radius: 50px;
	right: -4px;
	bottom: -4px;
	cursor: pointer;
	z-index: 2;
}

.simpleTableCell.isSelected.borderTop {
	border-top: 1px solid var(--brand-08);
}

.simpleTableCell.isSelected.borderRight {
	border-right: 1px solid var(--brand-08);
}

.simpleTableCell.isSelected.borderBottom {
	border-bottom: 1px solid var(--brand-08);
}

.simpleTableCell.isSelected.borderLeft {
	border-left: 1px solid var(--brand-08);
}

.hasRightNeighbor {
	border-right-color: var(--brand-08);
}

.hasBottomNeighbor {
	border-bottom-color: var(--brand-08);
}

.title {
	grid-area: title;
	width: fit-content;
	margin-bottom: 5px;
}
