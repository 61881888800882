.labelFilterContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	width: fit-content;
	height: 24px;
	overflow-y: hidden;

	&__expanded {
		overflow-y: unset;
		height: unset;
	}
}

.label {
	border: var(--border-subtle-solid);
	border-radius: 21px;
	padding: 0px 16px;
	height: 24px;
	cursor: pointer;
	white-space: nowrap;
	display: flex;
	align-items: center;

	&:hover {
		border-color: var(--border-active);
	}

	&__selected {
		background-color: var(--blue);
		color: var(--text-on-color);
	}
}

.showMoreContainer {
	border: 1px var(--text-secondary) solid;
	border-radius: 50%;
	padding: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 24px;
	width: 24px;
}

.showMoreButton {
	&__expanded {
		transform: rotate(180deg);
	}
}

.expandAndReset {
	display: flex;
	gap: 12px;
}
