$dash-offset: 220;
$duration: 5s;
@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $dash-offset;
	}
	50% {
		stroke-dashoffset: $dash-offset/4;
		transform: rotate(135deg);
	}
	95% {
		stroke-dashoffset: $dash-offset;
		transform: rotate(450deg);
	}
	100% {
		stroke-dashoffset: $dash-offset;
		transform: rotate(450deg);
	}
}

.genemodSpinner {
	animation: rotator $duration linear infinite;
	height: 20px;
	width: 20px;
	:global {
		.spin,
		.spin-background {
			stroke-width: 4px;
		}
		.spin:local {
			stroke: var(--accent-subtle);
			transform-origin: center;
			stroke-dashoffset: 0;
			animation: dash $duration ease-in-out infinite;
			stroke-dasharray: $dash-offset;
		}
		.spin-background {
			stroke: var(--border-subtle);
		}
	}
	&__small {
		height: 14px;
		width: 14px;
		:global {
			.spin {
				stroke-width: 2;
				stroke: var(--border-subtle);
			}
			.spin-background {
				stroke: transparent;
			}
		}
	}
	&__large {
		height: 32px;
		width: 32px;
		:global {
			.spin {
				stroke-width: 6;
			}
		}
	}
}
