.bannerContainer {
	width: 100%;
}
.banner {
	width: 100%;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 24px;
	padding-right: 16px;
	padding-top: 15px;
	padding-bottom: 16px;
}
.iconAndText {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	color: var(--text-primary);
}
