#freezer .freezer-contents .left {
	height: 100%;
	padding-bottom: 32px;
	position: relative;

	.freeze-image-container {
		display: flex;
		justify-content: flex-end;
		height: calc(100% - 72px);
		width: 472px;
		background-color: var(--layer-02);
		border: 1px solid var(--border-subtle);
		box-shadow: var(--shadow-container);
		border-radius: 16px;
		position: relative;
		@media (min-width: 1920px) {
			width: 698px;
		}
	}

	.freezer-image {
		height: 100%;
		width: 472px;
		// Apply 8px padding to prevent scrollbar from exceeding the container
		padding: 8px 0;
		@media (min-width: 1920px) {
			width: 698px;
		}
		.shelf-table {
			height: 100%;
			overflow-y: auto;
		}
		::-webkit-scrollbar-thumb {
			background-color: var(--freezer_scrollbar_bg);
			border: solid 1px var(--freezer_scrollbar_border);
		}
	}
}

// Rearrange Racks modal
.rearrange-racks-view {
	height: 100%;
	.freezer-image {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		background-color: var(--layer-02);
		border: 1px solid var(--border-subtle);
		box-shadow: var(--shadow-container);
		border-radius: 16px;
		position: relative;
		.shelf-name-section {
			height: fit-content;
			white-space: nowrap;
			padding: 0 16px;
			color: var(--text-primary);
			.shelf-name {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.shelf-content {
			width: 100%;
			padding: 0 0 0 4px;
			margin-bottom: 8px;

			.rack__hovered {
				&__left {
					box-shadow: inset 7px 0 10px -7px var(--link-primary);
				}

				&__right {
					box-shadow: inset -7px 0 10px -7px var(--link-primary);
				}
			}

			@keyframes shake {
				0% {
					transform: rotate(0deg);
				}
				25% {
					transform: rotate(3deg);
				}
				50% {
					transform: rotate(0deg);
				}
				75% {
					transform: rotate(-3deg);
				}
				100% {
					transform: rotate(0deg);
				}
			}

			.rack__rearranging {
				animation: shake 0.4s infinite both;
				cursor: move;
			}
		}
	}
}

// Freezer change dimension modal
.change-dimension-view {
	height: 100%;

	.freezer-image {
		width: 100%;
		height: 100%;
		background-color: var(--layer-02);
		border-radius: 16px;
		border: 1px solid var(--border-subtle);
		box-shadow: var(--shadow-container);
		.shelf-table {
			width: 100%;
			.freezer-shelf {
				user-select: none;
				width: 100%;
				border-bottom: var(--border-subtle-solid);
				position: relative;
				display: flex;
				padding-left: 0px;
				&:first-child {
					padding-top: 8px;
				}
				&:last-child {
					border-bottom: none;
				}
				.shelf-name-section {
					padding: 10px 16px 0 4px;
					color: var(--text-primary);
					.icon-and-name {
						.empty-shelf-name {
							padding: 6px;
						}
					}
				}
				.shelf-content-wrapper {
					width: 368px;
					// Area for racks
					.shelf-content {
						width: 100%;
						padding: 0 0 0 4px;
						margin-bottom: 8px;

						.rack__hovered {
							&__left {
								box-shadow: inset 7px 0 10px -7px var(--link-primary);
							}

							&__right {
								box-shadow: inset -7px 0 10px -7px
									var(--link-primary);
							}
						}

						@keyframes shake {
							0% {
								transform: rotate(0deg);
							}
							25% {
								transform: rotate(3deg);
							}
							50% {
								transform: rotate(0deg);
							}
							75% {
								transform: rotate(-3deg);
							}
							100% {
								transform: rotate(0deg);
							}
						}

						.rack__rearranging {
							animation: shake 0.4s infinite both;
							cursor: move;
						}
					}
				}
			}
		}
	}
}

.boxSelectItem {
	height: 48px !important;
	padding: 6px 8px !important;
}

.boxNameContainer {
	display: flex;
	margin-bottom: 4px;
	justify-content: space-between;
}

.locationSelect {
	:global(.ant-select-selection__clear) {
		display: grid;
		right: 17px;
		place-items: center;
		transform: translateY(-1px);
	}
}
