.restrictionWarning {
	width: 100%;
	background-color: transparent;
	border: solid 1px var(--yellow);
	outline-color: transparent;
	border-radius: 4px;
	padding: 16px;
	transition: all 0.25s ease-in-out;
	margin: 16px 0 8px 0;

	.flex {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		.iconContainer {
			cursor: default;
			min-width: 32px;
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 8px;
		}
	}

	&__active {
		outline: 1px solid var(--yellow);
		box-shadow: 0 2px 3px rgba(247, 181, 16, 0.4);
	}
}

.actions {
	display: flex;
	align-items: center;
	margin-top: 8px;
	gap: 16px;
	margin-left: 32px;
}

.iconTopAligned {
	align-items: flex-start;
}

.text {
	display: flex;
	align-items: center;
	gap: 8px;
}
