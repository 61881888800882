.cardContainer {
	display: flex;
	flex-direction: row;
	padding: 32px;
	border-radius: 14px;
	box-shadow: var(--shadow-container);
	background-color: var(--layer-02);
}

.leftSide {
	width: 314px;
	.title {
		display: flex;
		align-items: center;
		gap: 11px;
		margin-top: 16px;
		margin-bottom: 24px;

		.discount {
			width: 94px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(var(--accent-strong-rgb), 0.15);
			border-radius: 16px;
		}
	}

	.applyButton {
		width: 100%;
		border-radius: 6px;
		height: 48px;
		margin-top: 32px;
		background-color: var(--button-primary);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.divider {
	width: 2px;
	background-color: var(--border-subtle);
	margin: 0 48px;
}

.rightSide {
	display: flex;
	flex-direction: row;
	gap: 64px;
	width: 668px;

	&__institution {
		flex-direction: column;
		gap: 32px;
		width: 543px;
	}
	@media (max-width: 1280px) {
		gap: 32px;
	}
}

.eligibleContent {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 300px;

	.eligibleList {
		display: flex;
		flex-direction: column;
		column-gap: 38px;
		row-gap: 17px;
		flex-wrap: wrap;
		width: 308px;
		&__grid {
			width: 413px;
			height: 65px;
		}

		.eligibleText {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}
}

.featureContent {
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-width: 543px;
	.featureList {
		display: flex;
		flex-direction: column;
		height: 140px;
		column-gap: 46px;
		row-gap: 16px;
		flex-wrap: wrap;

		.feature {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;
			width: max-content;
		}
	}
}
