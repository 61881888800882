@use "common/styles/mixins";

.container {
	width: 100%;
	flex-shrink: 0;
	border-radius: 8px;
	background-color: var(--layer-02-v2);
	overflow: hidden;
	// The design says spread 30px but the shadow extends into the next card.
	// Lower spread looks better w/ this component
	box-shadow: var(--shadow-container);
	color: var(--text-primary);
	transition: 0.2s;
	border: 1px solid var(--border-invisible-v2);
}

.body {
	cursor: pointer;
}

.orderHeader {
	display: flex;
	justify-content: space-between;
	padding: 16px 16px 0;
	position: relative;

	.headerContent {
		display: flex;
		flex-direction: row;
		gap: 8px;

		.avatarBox {
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.nameAndDate {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}
	}

	.updatedDot {
		position: absolute;
		top: 12px;
		right: 14px;
	}
}

.orderInfo {
	margin-top: 16px;
	padding-left: 48px;
	padding-right: 8px;

	.orderGridContainer {
		display: grid;
		grid-template-columns: 162px 70px 32px;
		column-gap: 32px;
		row-gap: 24px;
		@media (min-width: 1920px) {
			grid-template-columns: 218px 88px 54px;
		}
	}

	.orderBrief {
		@extend .orderGridContainer;
		transition: 0.2s;
		grid-template-areas: "name order_number order_number";
	}
	.orderCompleted {
		@extend .orderGridContainer;
		grid-template-areas:
			"completed_by completed_at completed_at"
			". . .";
	}
	.orderDetails {
		@extend .orderGridContainer;
		grid-template-areas:
			"total_price grant_id grant_id"
			"price quantity quantity"
			"source source source"
			"catalog lot lot"
			". . .";
	}
}

.orderFieldContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.orderFieldValue {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include mixins.linkStyle();
	}
}

.footerContainer {
	width: 100%;
	padding: 12px 16px 12px 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: var(--layer-02);
	border-top: var(--border-subtle-solid);

	.buttons {
		display: flex;
		flex-direction: row;
		gap: 32px;
	}
}
