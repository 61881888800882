.projectKeyTag {
	padding: 2px 5px;
	width: fit-content;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.large {
	min-height: 22px;
	min-width: 45px;
	& div {
		line-height: 0;
	}
}

.extraLarge {
	width: 50px;
	height: 42px;
	& div {
		line-height: 0;
		font-size: 20px;
		font-weight: 700;
	}
}

.small {
	height: 16px;
}
