.boxInfoWrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.boxNameAndOptions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		margin-bottom: 16px;

		.boxTitle {
			font: var(--headline3-v2);
			z-index: 1;
			max-width: calc(100% - 80px);
			flex: 0 1 auto;

			> div {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.fixedHeaderItem {
			flex: 0 0 32px;
		}
	}

	.boxStats,
	.boxStat {
		display: flex;
		align-items: center;
	}
	.boxStats {
		gap: 24px;
		justify-content: space-between;
		margin: 0 8px;
	}
	.boxStat {
		gap: 8px;
	}

	.generalInfo {
		display: flex;
		flex-wrap: wrap;
		row-gap: 24px;

		margin: 0 8px;

		.boxInfo {
			flex: 0 0 50%;
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.activity {
		overflow-y: auto;
		::-webkit-scrollbar {
			display: none;
		}
	}
}

.divider {
	margin: 24px 0;
	height: 0px;
	border-top: 1px solid var(--border-subtle);
}
