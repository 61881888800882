.previewAttachment {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	// The preview attachment modal should be over all modals
	z-index: 1001;
	background-color: rgba(0, 0, 0, 0.8);
	.header {
		width: 100%;
		min-height: 56px;
		height: 56px;
		padding: 0 24px;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		background-color: var(--layer-inverse);
		.headerLeft {
			display: flex;
			justify-content: flex-start;
			flex: 1;
		}
		.headerMiddle {
			display: flex;
			justify-content: center;
			flex: 1;
			.openTextContainer {
				display: flex;
				gap: 8px;
				justify-content: center;
				align-items: center;
				background-color: var(--layer-01);
				border-radius: 4px;
				padding: 3px 8px;
				cursor: pointer;
			}
			.openTextContainerDisabled {
				cursor: not-allowed;
				background-color: var(--text-disabled) !important;
			}
		}
		.headerRight {
			display: flex;
			gap: 16px;
			justify-content: flex-end;
			align-items: center;
			flex: 1;

			.downloadContainer {
				display: flex;
				align-items: center;
				gap: 16px;
				cursor: pointer;
			}
		}
	}
}

.attachmentFile {
	width: 100%;
	height: 100%;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.loading {
	position: absolute;
	left: 50%;
	top: 50%;
}

.closeCapture {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
