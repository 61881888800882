.main {
	position: fixed;
	bottom: 32px;
	right: 32px;
	width: 340px;
	background-color: var(--layer-02);
	border-radius: 4px;
	box-shadow: 0 20px 40px 0 var(--shadow-container-color);
	padding: 24px;
	display: flex;
	flex-direction: column;
	display: none;
}

.visible {
	display: flex;
}

.img {
	width: 100%;
	object-fit: fill;
	margin-top: 15px;
	height: 140px;
}

.content {
	flex: 1;
	margin-top: 16px;
}

.actions {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}
