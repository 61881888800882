.backgroundGrid {
	display: grid;
	column-gap: 16px;
	row-gap: 16px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr 1fr;
	margin-bottom: 32px;
}

.backgroundSpaceCardWithOutTransparency {
	position: relative;
	width: 100%;
	height: 48px;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border: var(--border-subtle-solid-v2);
	box-shadow: var(--shadow-container);

	&__selected {
		border: solid 2px var(--border-active-v2);
	}

	// Fading the background color and image withoud affecting the text and border
	&__selected::before {
		position: absolute;
		content: "";
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		border-radius: 8px;
	}
}

.backgroundSpaceCard {
	position: relative;
	width: 100%;
	height: 48px;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border: var(--border-subtle-solid-v2);
	box-shadow: var(--shadow-container);

	&__selected {
		border: solid 2px var(--border-active-v2);
	}

	&:hover {
		opacity: 1;
	}

	// Fading the background color and image withoud affecting the text and border
	&__selected::before {
		position: absolute;
		content: "";
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		border-radius: 8px;
		opacity: 1;
	}
}

.spaceName {
	margin-bottom: 24px;
}

.spaceBackground {
	margin-top: 24px;
}

.checkIcon {
	margin: auto;
	// Set the index to 1 so that it doesn't get faded with the low opacity background
	z-index: 1;
}

.spaceCardPreviewGridModifier {
	grid-template-rows: 1fr;
	margin-bottom: 24px;
}

.spaceCardPreview {
	grid-column-start: 1;
	grid-column-end: 4;
}

.backgroundLabel {
	color: var(--text-primary-v2);
	margin-bottom: 16px;
}

.selectorContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	padding-bottom: 8px;
	border-bottom: var(--border-subtle-solid-v2);
	height: 100%;
	.arrowContainer {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: var(--layer-03-v2);
		display: flex;
		align-items: center;
		justify-content: center;
		object-fit: contain;
		&__hide {
			visibility: hidden;
		}
	}
}

.selectedTemplateImage {
	width: 504px;
	height: auto;
}

.templateSelectorContainer {
	height: 100%;
	width: 100%;
	margin-top: 16px;
}

.templateIndexIdentifier {
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
}
