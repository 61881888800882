.bookmark {
	border-radius: 4px;
	height: 32px;
	width: 32px;
	&:hover {
		background-color: var(--layer-hover-02);
	}

	& div {
		transform: none;
	}
}

.small {
	height: 24px;
	width: 24px;
}

.large {
	height: 40px;
	width: 40px;

	& svg {
		height: 16px;
		width: 16px;
	}
}
