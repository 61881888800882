@import "../Typography/index.module.scss";

.main {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px;
	padding-left: 0;
	border-radius: 4px;

	&:hover {
		text-decoration: none;
		background-color: var(--layer-hover-02);
		.objectName__link {
			text-decoration: underline;
			color: var(--link-primary-hover);
		}
	}
}

.avatarWrapper {
	position: relative;
	align-self: flex-start;
}

.activity {
	display: flex;
	flex-direction: row;
	word-break: break-word;
}

.objectName {
	display: inline;
}

.pointerStyle {
	cursor: pointer;
}
