.segmented {
	padding: 2px 4px;

	:global {
		.ant-segmented-item {
			.ant-segmented-item-label {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.ant-segmented-item-selected {
			background-color: var(--layer-02);
		}
		.ant-segmented-thumb {
			background-color: var(--layer-02);
		}
	}
}
