.filtersToggle {
	width: 24px !important;
	height: 24px !important;

	&.hasText {
		padding-left: 8px;
		padding-right: 8px;
		width: auto !important;
	}

	&.hasText.isLarge {
		width: auto !important;
	}

	&.isToggled {
		path {
			fill: var(--text-on-color) !important;
			stroke: var(--text-on-color) !important;
		}
	}

	> div {
		.isTextToggled {
			color: var(--text-on-color) !important;
		}
	}

	&.isLarge {
		width: 32px !important;
		height: 32px !important;
	}

	.dot {
		display: none;
		position: absolute;
		top: -2px;
		right: -2px;

		&.show {
			display: block;
			cursor: pointer;
		}
		&.showWithoutIcon {
			top: 2px;
			right: -8px;
		}
		&.showWithIconTitle {
			top: 2px;
			left: 10px;
		}
	}

	&.borderless {
		border: 0 !important;
		background-color: transparent !important;
		> div {
			.isTextToggled {
				color: var(--text-secondary-v2) !important;
			}
		}
		&:hover {
			background-color: none !important;
		}
	}

	&.borderless.isToggled {
		background-color: var(--gray-03) !important;
		> .isTextToggled {
			color: var(--text-secondary-v2) !important;
		}
		path {
			fill: var(--text-secondary-v2) !important;
			stroke: var(--text-secondary-v2) !important;
		}
		&:hover {
			background-color: none !important;
		}
	}
}
