.genemod-demowrapper {
	.ant-anchor-wrapper {
		background: transparent;
		position: absolute;
		top: 120px;
	}

	.ant-anchor-link-title-active {
		color: var(--link-primary) !important;
	}
	.ant-anchor-link-title {
		color: var(--text-primary);
	}
}
