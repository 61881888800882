.createNewItem {
	width: 100%;
	height: 100%;
	border-radius: 16px;
}

.attachmentTableCreation {
	margin-bottom: 16px;
}

.isRepositoryPage {
	> form {
		> div {
			> div {
				padding: 0 !important;
			}
		}
	}
}

.header {
	width: 100%;
	padding: 32px;
	padding-bottom: 16px;
	display: flex;
	border-bottom: var(--border-subtle-emph-width) solid var(--border-subtle);
	justify-content: space-between;
	align-items: center;

	.itemName {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 32px;
		color: var(--text-primary);
	}
	.formButtons {
		display: flex;
	}
}

.boxNameContainer {
	display: flex;
	margin-bottom: 4px;
	justify-content: space-between;
}

.boxSelectItem {
	height: 48px !important;
	padding: 6px 8px !important;
}

.locationSelect {
	> div {
		:global(.ant-select-selector) {
			background-color: var(--layer-03) !important;
			border: 1px solid var(--border-subtle-v2);
		}
	}
	:global(.ant-select-selection__clear) {
		display: grid;
		right: 17px;
		place-items: center;
		transform: translateY(-1px);
	}
}

.inputWidth {
	width: 100%;
}

.advancedInfo {
	> div {
		background-color: var(--layer-01-v2);
		margin-left: -15px;
		padding-top: 24px;
		width: 103%;
		border: none !important;
		box-shadow: none !important;
		margin-bottom: 0 !important;
	}
}

.divider {
	margin-top: 24px;
	margin-bottom: 24px;
}
