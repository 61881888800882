.genemod-team-avatar {
	.ant-avatar-string {
		font-weight: var(--font-weight-bold) !important;
		font-family: var(--font-family-lato);
	}
	cursor: pointer;
}

.deactivated {
	background-color: var(--text-ghost) !important;
}
