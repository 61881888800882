.empty {
	color: var(--text-ghost);
}

.static {
	color: var(--text-primary) !important;
}

.datepickerContainer {
	display: none;
	user-select: none;
	&__open {
		display: block;
	}
}

// adjust box-shadows to make the input and calendar appear as a single component
.dropdown {
	background-color: var(--layer-03);
	box-shadow: var(--shadow-container) !important;
}

.cursor {
	position: absolute;
	top: -2px;
	bottom: 0;
	left: 0;
	width: 1px;

	> span {
		width: 1px;
	}
}

.highlighted .cursor {
	opacity: 0;
}
