.tableWithItemContainer {
	padding: 16px 24px 8px 24px;
}

.tableContainer {
	padding: 16px 24px;
}

.tableContentContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.changeLocationContainer {
	padding: 8px 24px;
	cursor: pointer;
}

.separator {
	width: 100%;
	height: 1px;
	background-color: var(--border-invisible-v2);
}

.pathLinkText {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: var(--button-text);
	font-weight: 500;
	cursor: pointer;
	&:hover {
		text-decoration: underline !important;
		color: var(--button-hover-text);
	}
}

.locationLink {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	path {
		fill: var(--text-tertiary);
	}
}
