$rightSideWidth: 480px; // 8px roughly for the scrollbar

@mixin useResponsiveRightSideWidth {
	--rightSideWidth: 480px;
}

@mixin infoControllerHeader {
	grid-row-start: infoControllerRowStart;
	grid-row-end: infoControllerRowDivider;
	height: min-content;
}
@mixin infoControllerContent {
	grid-row-start: infoControllerRowDivider;
	grid-row-end: infoControllerRowEnd;
}
@mixin infoControllerLeft {
	grid-column-start: infoControllerColStart;
	grid-column-end: infoControllerColDivider;
}
@mixin infoControllerRight {
	grid-column-start: infoControllerColDivider;
	grid-column-end: infoControllerColEnd;

	@include useResponsiveRightSideWidth();
	max-width: var(--rightSideWidth);
}

.boxGridLeftHeader {
	@include infoControllerHeader();
	@include infoControllerLeft();
}

.boxGridLeftContent {
	@include infoControllerContent();
	@include infoControllerLeft();
}

.boxGridRightHeader {
	@include infoControllerHeader();
	@include infoControllerRight();
}

.boxGridRightContent {
	@include infoControllerContent();
	@include infoControllerRight();
}

@mixin boxViewGrid {
	display: grid;
	justify-content: center;

	@include useResponsiveRightSideWidth();
	grid-template-columns:
		[infoControllerColStart] 50% [infoControllerColDivider] var(
			--rightSideWidth
		)
		[infoControllerColEnd];
	grid-template-rows: [infoControllerRowStart] 60px [infoControllerRowDivider] auto [infoControllerRowEnd];

	column-gap: 45px;
	row-gap: 24px;
}
