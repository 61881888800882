.orderInfoPanel {
	background-color: var(--layer-02);
	:global {
		.ant-drawer-body {
			display: flex;
			flex-direction: column;
			justify-content: start;
			gap: 32px;
			padding-right: 32px !important;
		}
	}
}
.orderInfoTabList {
	overflow: hidden;
	:global(.ant-tabs) {
		height: 100%;
	}

	:global(.ant-tabs-content-holder) {
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
.info {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 80px minmax(0, 1fr);
	align-items: center;
	column-gap: 73px;
	row-gap: 24px;

	@media (min-width: 1920px) {
		grid-template-columns: 90px minmax(0, 1fr);
	}

	.customInput {
		width: 100%;
	}
}

.header {
	padding-right: 0px;
	width: 100%;

	.orderName {
		max-width: 420px;
		font-weight: 500;
		margin-left: -8px;
		color: var(--text-secondary-v2) !important;
	}
}

.statusSelectWrapper {
	display: flex;
	align-items: center;
	gap: 16px;

	.statusSelect {
		:global(.ant-select-selection) {
			height: 32px;
		}
	}

	:global(.ant-select-dropdown-menu-item-group:not(:last-child)) {
		border-bottom: var(--border-subtle-solid) !important;
	}
}

.dropdown {
	:global(.ant-select-dropdown-menu) {
		max-height: max-content;
	}
	padding-left: 4px;
}

.option {
	display: flex;
	align-items: center;
	width: 160px;
	justify-content: space-between;

	.optionLabel {
		display: flex;
		gap: 11px;
		align-items: center;
	}
}

@mixin statusDropdownOptionColorMixin($backgroundColor, $textColor) {
	:global {
		.ant-select-selection {
			background-color: rgba($backgroundColor, 0.2);
		}
		.ant-select-arrow-icon svg {
			stroke: $textColor !important;
			fill: $textColor !important;
		}
	}
	& div {
		color: $textColor !important;
	}
}

.requested {
	@include statusDropdownOptionColorMixin(
		var(--neutral-gray-rgb),
		var(--text-tertiary)
	);
}

.approved {
	@include statusDropdownOptionColorMixin(
		var(--pastel-violet-rgb),
		var(--pastel-violet-contrast)
	);
}

.ordered {
	@include statusDropdownOptionColorMixin(
		var(--pastel-navy-rgb),
		var(--pastel-navy)
	);
}

.received {
	@include statusDropdownOptionColorMixin(
		var(--pastel-green-rgb),
		var(--pastel-green-contrast)
	);
}

.denied {
	@include statusDropdownOptionColorMixin(
		var(--pastel-pink-rgb),
		var(--pastel-pink-contrast)
	);
}

.archived {
	background-color: var(--neutral-gray);
	border-radius: 4px;
	padding: 2px 4px;
}

.goBackButton {
	display: flex;
	align-items: center;
}
