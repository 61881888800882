.container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
}

.tagContainer {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 3px 8px;
	margin-right: 8px;
	background-color: var(--layer-accent-01);
	border-radius: 2px;
}

.newTagContainer {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 3px 8px;
	border: 1px solid var(--border-subtle);
	border-radius: 2px;
	height: 26px;
	cursor: pointer;
}

.input {
	height: 26px;

	&:focus-within {
		border: 1px solid var(--border-active);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;

		&::before {
			opacity: 0;
		}
	}
}

.loadingContainer {
	padding-top: 4px;
}
