.svgContainer,
.svgContainerSmall,
.svgContainerLarge,
.svgContainerXLarge .svgContainerCard {
	--small-icon-size-numeric: 12;
	--medium-icon-size-numeric: 12;
	--default-icon-size: 14px;
	--default-icon-size-share: 16px;
	--large-icon-size: 24px;
	--XLarge-icon-size: 32px;
	--card-icon-size: 80px;
	--small-card-icon-size: 68px;
	--default-icon-size-numeric: 18;
	--large-icon-size-numeric: 24;
	--XLarge-icon-size-numeric: 32;
	--card-icon-size-numeric: 80;
	--small-card-icon-size-numeric: 68;

	display: flex;
	align-items: center;
	justify-content: center;
	fill: var(--text-secondary);
}

.svgContainerShare {
	height: var(--default-icon-size-share);
	width: var(--default-icon-size-share);
	min-width: var(--default-icon-size-share);

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	svg {
		height: var(--default-icon-size-share);
		width: var(--default-icon-size-share);
	}
}

.svgContainer {
	height: var(--default-icon-size);
	width: var(--default-icon-size);
	min-width: var(--default-icon-size);

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	svg {
		height: var(--default-icon-size);
		width: var(--default-icon-size);
	}
}

.svgContainerSmall {
	> div {
		transform: scale(
			calc(
				var(--small-icon-size-numeric) /
					var(--default-icon-size-numeric)
			)
		);
	}
}

.svgContainerLarge {
	height: var(--large-icon-size);
	width: var(--large-icon-size);
	min-width: var(--large-icon-size);

	> div {
		transform: scale(
			calc(
				var(--large-icon-size-numeric) /
					var(--default-icon-size-numeric)
			)
		);
	}
}

.svgContainerXLarge {
	height: var(--XLarge-icon-size);
	width: var(--XLarge-icon-size);
	min-width: var(--XLarge-icon-size);

	> div {
		transform: scale(
			calc(
				var(--XLarge-icon-size-numeric) /
					var(--default-icon-size-numeric)
			)
		);
	}
	@media (min-width: 1920px) {
		> div {
			transform: scale(calc(36 / var(--default-icon-size-numeric)));
		}
	}
}

.svgContainerCard {
	height: var(--card-icon-size);
	width: var(--card-icon-size);
	min-width: var(--card-icon-size);

	> div {
		transform: scale(
			calc(
				var(--card-icon-size-numeric) / var(--default-icon-size-numeric)
			)
		);
	}
}

.svgContainerSmallCard {
	height: var(--small-card-icon-size);
	width: var(--small-card-icon-size);
	min-width: var(--small-card-icon-size);

	> div {
		transform: scale(
			calc(
				var(--small-card-icon-size-numeric) /
					var(--default-icon-size-numeric)
			)
		);
	}
}

.iconDemoWrapper {
	display: flex;
	flex-wrap: wrap;
}

.iconDemo {
	display: flex;
	align-items: center;
	margin: 8px 16px;
	white-space: nowrap;
	width: 20%;

	span {
		margin-left: 10px;
	}
}

.iconDemoResult {
	display: flex;
	align-items: center;
	height: 36px;

	&.light {
		background-color: var(--text-primary);
	}

	span {
		margin-right: 10px;
	}
}

.decorative {
	cursor: default;
}

.disabled {
	cursor: not-allowed;
}

.notification {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 999;
	div {
		border: 1px solid var(--current-layer);
	}
}

.interactive {
	cursor: pointer;
	background-color: none;
	border-radius: 2px;

	&.hoverEffect:hover {
		transition: 0.2s;
		background-color: var(--current-layer-hover);
	}

	/** Used for Rack Card & Large Category Card */
	&.shadowHover {
		background-color: var(--ui_level02);
		box-shadow: var(--shadow-container);
		border-radius: 2px;
	}
}

/** Defualt Icon styles */
.edit {
	stroke: var(--text-secondary);
	fill: var(--text-secondary);
}
