.notificationIconWrapper {
	position: relative;
	.indicator {
		position: absolute;
		top: 0;
		right: 0;
		border: 1px solid var(--text-on-color);
		border-radius: 50%;
	}

	.icon {
		&:hover,
		&__active {
			background-color: var(--layer-hover-02);
			border-radius: 2px;
		}
	}
}
