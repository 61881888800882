.formContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.headerAndForm {
		display: flex;
		flex-direction: column;
		gap: 31px;
		padding: 32px;

		.panelHeader {
			display: flex;
			align-items: center;
			width: 100%;
			gap: 16px;
		}
	}

	.submitFooter {
		border-top: var(--border-subtle-solid);
		padding: 24px 32px;
		display: flex;
		justify-content: flex-end;
	}
}

.success {
	display: grid;
	place-items: center;
	height: 100%;
	padding: 32px;
}

.successWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}
