.container {
	display: flex;
	flex-direction: column;
	background-color: var(--layer-01);
	width: 100%;
	height: 100%;
}

.categoryView {
	border: 1px solid var(--border-subtle);
	width: 100%;
	background-color: var(--layer-02);
	border-radius: 16px;
	height: 100%;
	margin-right: 32px;
	display: flex;
	flex-direction: column;
}

.title {
	display: flex;
	align-items: center;
	gap: 8px;
	background-color: var(--layer-03);
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
	border-bottom: 1px solid var(--border-subtle);
	padding: 12px 16px;
	height: 56px;
}

.boxes {
	background-color: var(--layer-accent-01);
	padding: 24px 21px;
	margin: 24px;
	margin-bottom: 32px;
	border-radius: 4px;
}

.boxesToolbar {
	display: flex;
}

.boxesDisplay {
	display: grid;
	gap: 8px;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 96px;
	margin-top: 12px;

	@media (min-width: 1920px) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.categoryMenu {
	border-radius: 4px;
	transition: background-color 0.2s ease;
	&:hover {
		background-color: var(--background-hover);
	}
}

.itemGroups {
	padding: 0 24px;
}

.itemGroupToolbar {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
}

.wrapper {
	position: relative;
	overflow: scroll;
	flex: 1;
}

.content {
	position: absolute;
	top: 0;
	left: 0;
	padding-bottom: 32px;
	width: 100%;
}

.right {
	grid-column: span 7;
}

.itemGroupsGrid {
	display: flex;
	flex-wrap: wrap;
}

.clickToEdit {
	min-width: 120px;
}
