.genemod-sort-select {
	.icon-and-label {
		display: flex;
		gap: 8px;
	}

	/** Position the caret-down icon */
	.ant-select-arrow {
		right: 4px;
	}
	.ant-select-selection--single .ant-select-selection__rendered {
		margin-right: 22px;
	}

	.genemod-select {
		border: none !important;
		.ant-select-selection {
			transition: 0s;
			background-color: var(--current-layer);
			height: 24px;
			line-height: 24px;
			padding: 0 0 0 4px;
			&:hover {
				background-color: var(--current-layer-hover);
			}
		}
	}
}
