.dot {
	position: absolute;
	top: 50%;
	left: -18px;
	transform: translateY(-50%);
}

.footer {
	width: 100%;
}

.footerContent {
	display: flex;
	align-items: center;
	gap: 12px;
	border-top: 1px solid var(--border-subtle);
	padding-top: 16px;
	padding: 16px 32px;
}

.newsCard {
	border-bottom: 1px solid var(--border-subtle);
	padding-bottom: 16px;

	&:not(:first-child) {
		padding-top: 32px;
	}
}

.modal {
	:global(.ant-modal-content) {
		padding: 0 !important;
	}
}

.title {
	padding: 32px 32px 0 32px;
}

.content {
	padding: 0 32px 32px 32px;
}

.img {
	height: 240px;
	width: 100%;
	margin-top: 16px;
	margin-bottom: 16px;
	object-fit: cover;
	border-radius: 4px;
}

.actionText {
	color: var(--link-primary);
}
