$editorContentMinMargin: 48px;

.editorContentContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	scrollbar-gutter: stable;
	display: flex;
	justify-content: center;
	background: var(--background);

	// Default text styles
	font-family: var(--font-family-lato);
	color: var(--text-secondary);

	.editorGradient {
		width: 100%;
		height: fit-content;
	}
	.editorContent {
		position: relative;

		/**Prevent margin collapse*/
		> div {
			display: flex;
			flex-direction: column;
		}
		*:focus {
			outline: none;
		}

		.editor {
			margin: 0 auto;
			max-width: min(calc(100% - (2 * $editorContentMinMargin)), 712px);
		}

		// Override the default placeholder styling, we will style it ourselves when we do custom placeholder rendering.
		:global {
			[data-slate-placeholder="true"] {
				opacity: unset !important;
			}
		}
		@media screen and (max-width: 1024px) {
			.editor {
				overflow-x: scroll;
			}
		}
	}

	// gives visual response when highlighter is applied to the text
	::-webkit-selection {
		background: var(--highlighter);
		color: unset;
	}
	::selection {
		background: var(--highlighter);
		color: unset;
	}
}

.cursor {
	position: absolute;
	bottom: 0;
	height: calc(1em + 6px);
	width: 2px;
	user-select: none;

	.hitbox {
		width: 7px;
		height: 100%;
		position: relative;
		left: -3px;

		.name {
			width: 6px;
			height: 6px;
			transition: 0.2s;
			overflow: hidden;
			left: 3px;
			> span {
				position: relative;
				transition: 0.2s;
				bottom: 0;
				left: 0;
				opacity: 0;
			}
		}

		&:hover .name {
			width: auto;
			height: 18px;
			padding: 0 4px;
			> span {
				opacity: 1;
			}
		}
	}
}

.name {
	position: absolute;
	left: 0;
	display: inline-flex;
	align-content: center;
	transform: translateY(-100%);
	color: var(--text-primary);
	white-space: nowrap;
}

// Editor title
.editorTitle.editorTitle.editorTitle.editorTitle {
	margin: 48px auto 24px auto;
	transform: translateX(-8px);

	.editorTitleContainer {
		background-color: unset !important;
	}
}

.hiddenHelper {
	padding: 4px 8px;
	position: absolute;
	visibility: hidden;
	white-space: pre-wrap;
	word-wrap: break-word;
	overflow-wrap: break-word;
}
