:global {
	.ant-tooltip-placement-top .ant-tooltip-arrow::before {
		transform: translateY(3.535534px) rotate(180deg);
	}

	.ant-tooltip-placement-topLeft,
	.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
		bottom: 8.928932px;
	}
	.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
	.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
		transform: translateY(3.535534px) rotate(0deg);
	}

	.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
	.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
		top: 15.928932px;
	}

	.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
	.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
	.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
		transform: translateY(-3.464466px) rotate(0deg);
	}

	.ant-tooltip-placement-left .ant-tooltip-arrow::before,
	.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
	.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
		transform: translateX(3.464466px) rotate(90deg);
	}

	.ant-tooltip-placement-right .ant-tooltip-arrow::before,
	.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
	.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
		transform: translateX(-3.464466px) rotate(-90deg);
	}
}

.overlay :global {
	max-width: 350px;

	.ant-tooltip-inner {
		border-radius: 0px;
		padding: 5px 8px 5px 8px;
		font: var(--font-body);
		letter-spacing: 0.01px;
		box-shadow: var(--shadow-container);
		border-radius: 4px;
	}

	.underlineText {
		text-decoration: underline;
		text-decoration-style: dotted;
		text-underline-offset: 4px;
		cursor: pointer;
	}
}
