.genemod-modal {
	padding: 0;
	border-radius: 8px;
	border: 1px solid var(--border-invisible-v2);
	box-shadow: 0px 4px 10px -2px rgba(21, 25, 45, 0.1);
	background-color: var(--layer-01-v2);

	.ant-modal-content {
		background-color: transparent;
		padding: 32px;
		border-radius: 8px;
	}

	.ant-modal-header,
	.ant-modal-body,
	.ant-modal-footer {
		background-color: var(--layer-01-v2);
		padding: 0;
		border: none;
	}

	.ant-modal-title {
		color: var(--text-primary);
		font: var(--font-title);
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.04px;
	}

	.ant-modal-header {
		margin-bottom: 24px;
	}

	.ant-modal-body {
		font: var(--font-body);
		color: var(--text-primary);
	}

	.ant-modal-footer {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
	}

	.ant-modal-footer button + button {
		margin-left: 0;
	}

	&-default-footer {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		button {
			margin-left: 8px;
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}
		}
		&.switch-buttons {
			justify-content: end;
			flex-direction: row-reverse;
		}
	}
}
.preview-experiment-modal {
	.ant-modal-content {
		padding: 32px 48px;
	}
	.ant-modal-title {
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 36px;
	}
}
.ant-modal-centered {
	padding: 32px 0px;
}
.genemod-modal-small-gutter {
	.ant-modal-header {
		margin-bottom: 8px;
	}
}
