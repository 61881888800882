.hoveringToolbar {
	position: absolute;
	opacity: 1;
	color: white;
	z-index: 999;

	flex-direction: row;
	border-radius: 4px;
	box-shadow: var(--shadow-container);
	background-color: var(--layer-popover);
	max-width: 390px !important;

	.toolbarContent {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 8px 16px;
		height: 40px;
	}

	.link {
		user-select: none;
		color: var(--color-2B);
		width: 100%;
		max-width: 260px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		font: var(--font-body);
		font-size: 14px;
		letter-spacing: 0.01px;
		margin-right: 12px;
	}

	.linkButtons {
		display: flex;
		gap: 4px;
	}
}
