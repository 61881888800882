.collapsibleContainer {
	width: 100%;
	background-color: var(--layer-02-v2);
	border: var(--border-invisible-solid-v2);
	border-radius: 8px;
	margin-bottom: 8px;
	box-shadow: var(--shadow-container);
}

.header {
	display: flex;
	width: 100%;
	align-items: center;
	height: 48px;
	justify-content: space-between;
	background-color: var(--layer-01-v2);
	border-radius: 8px 8px 0px 0px;

	&__collapsed {
		border-radius: 8px;
	}
}

.titleHeader {
	display: flex;
	align-items: center;

	.itemGroupType {
		margin-left: 16px;
		text-transform: capitalize;
	}
}
.iconBox {
	width: 48px;
	height: 48px;
	object-fit: contain;
	display: flex;
	justify-content: center;
	align-items: center;
}
.contentHeader {
	display: flex;
	margin-right: 16px;
}

.content {
	border-top: 1px solid var(--border-invisible-v2);
	display: flex;
	flex-direction: column;
}

.rearrangeableCol {
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div:nth-child(1) {
		margin-left: 16px;
	}
}
