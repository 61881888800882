@use "common/styles/mixins";

.regular {
	font: var(--regular-v2);
}
.headline1 {
	font: var(--headline1-v2);
	letter-spacing: 0em;
}

.headline2 {
	font: var(--headline2-v2);
}

.headline3 {
	font: var(--headline3-v2);
}

.headline4 {
	font: var(--headline4-v2);
	letter-spacing: 0em;
}

.headline5 {
	font: var(--headline5-v2);
	letter-spacing: 0em;
}

.small {
	font: var(--small-v2);
	letter-spacing: 0em;
}
.footnote {
	font-family: var(--font-family-lato);
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
}

.footnote {
	font: var(--footnote-v2);
	letter-spacing: 0em;
}

.caption {
	font: var(--caption-v2);
}

.h1 {
	font-family: "futura-pt", sans-serif;
	font-weight: 500;
	font-size: 32px;
	line-height: 32px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 40px;
		line-height: 40px;
		letter-spacing: 0.04px;
	}
}

.h2 {
	font-family: "futura-pt", sans-serif;
	font-weight: 600; //Demi is 600
	font-size: 24px;
	line-height: 38px;
	letter-spacing: 0.02px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 30px;
		letter-spacing: 0.06px;
	}
}

.h3 {
	font-family: "futura-pt", sans-serif;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 0.01px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 26px;
		letter-spacing: 0.05px;
	}
}

.h4 {
	font-family: "futura-pt", sans-serif;
	font-weight: 600; // Demi is 600
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.02px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 20px;
	}
}

.h5 {
	font-family: "futura-pt", sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.01px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 14px;
	}
}

.h6 {
	font-family: "futura-pt", sans-serif;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01px;
	// doesn't have a spec for 1920px and above
}

.subtitle1 {
	font-family: "Lato", sans-serif;
	font-weight: 600; //semibold is not in the font weights listed but its 600
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.03px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 16px;
	}
}

.subtitle2 {
	font-family: "Lato", sans-serif;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.02px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 14px;
		letter-spacing: 0.01px;
	}
}

.smallprint {
	font-family: "Lato", sans-serif;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.02px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 12px;
		letter-spacing: 0.02px;
	}
}

// Body1 in 1920 become body2 in 1280
.body1 {
	font-family: "Lato", sans-serif;
	font-weight: normal; // regular is normal
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.02px;

	@media (max-width: 1919px) {
		font-weight: normal; // regular is normal in this case
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.01px;
	}
}

// Body2 in 1920 becomes body3 in 1280
.body2 {
	font-family: "Lato", sans-serif;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.01px;

	// 1920px and above
	@media (min-width: 1920px) {
		font-size: 14px;
		line-height: 22px;
	}
}

/** New Typography*/
.h2V2 {
}
.headingOne {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-semibold);
	font-size: 24px;
	line-height: 38px;
	letter-spacing: 0.04px;
}

.largeTitle {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-semibold);
	font-size: 22px;
	line-height: 38px;
	letter-spacing: 0.04px;
}

.title {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-bold);
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 0.04px;
}

.headline {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-regular);
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 0.02px;
}

.subheadline {
	font-family: var(--font-family-lato);
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.01px;
}

.body {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-regular);
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.01px;
}

.label {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-regular);
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01px;
}

.normal {
	font-family: var(--font-family-lato);
	font-weight: var(--font-weight-regular);
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.01px;
}

.editorTitle {
	font-family: var(--font-family-lato);
	font-size: 30px;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: -0.02em;
}

.bold {
	font-weight: var(--font-weight-bold);
}

.medium {
	font-weight: var(--font-weight-medium);
}

.semibold {
	font-weight: var(--font-weight-semibold);
}

.bold.caption {
	font-weight: var(--font-weight-heavy);
}

@media (min-width: 1920px) {
	.semibold {
		font-weight: var(--font-weight-bold);
	}

	.resize.largeTitle {
		font-size: 26px !important;
		line-height: 42px;
		letter-spacing: 0.05px;
		font-weight: var(--font-weight-semibold);
	}

	.resize.largeTitle.semibold {
		font-weight: var(--font-weight-bold);
	}

	.resize.title {
		font-size: 22px;
		line-height: 34px;
	}

	.resize.title.bold {
		font-weight: var(--font-weight-bold);
	}

	.resize.title.bold {
		font-weight: var(--font-weight-heavy);
	}

	.resize.headline {
		font-size: 19px;
		line-height: 28px;
	}

	.resize.headline.bold {
		font-weight: var(--font-weight-bold);
	}

	.resize.subheadline {
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.02px;
		font-weight: var(--font-weight-semibold);
	}

	.resize.subheadline.bold {
		font-weight: var(--font-weight-heavy);
	}

	.resize.body {
		font-size: 14px;
		line-height: 26px;
	}

	.resize.label {
		font-size: 14px;
		line-height: 18px;
	}

	.resize.label.bold {
		font-weight: var(--font-weight-heavy);
	}

	.resize.caption {
		font-size: 12px;
		line-height: 16px;
	}
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/** With links on typography variant, except for label */
.h1.withLinks,
.h2.withLinks,
.h3.withLinks,
.h4.withLinks,
.h5.withLinks,
.h6.withLinks,
.largeTitle.withLinks,
.title.withLinks,
.headline.withLinks,
.subheadline.withLinks,
.body.withLinks,
.body1.withLinks,
.body2.withLinks {
	@include mixins.linkStyle();
}
