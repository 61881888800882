.sharingOptionsContainer {
	display: flex;
	gap: 24px;
	margin-top: 24px;
}

.sharingOption {
	background-color: var(--layer-02-v2);
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	gap: 8px;
	padding: 12px 8px;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: 0px 1px 4px 0 var(--shadow-container-color);
	width: 100%;
	height: 160px;

	.buttonIcon {
		> div {
			height: min-content;
			width: min-content;
			transform: scale(1);
		}

		& svg {
			height: 72px;
			width: 72px;
		}
	}

	&:hover {
		border: var(--layer-hover-02-v2);
		background-color: var(--layer-hover-02-v2);
	}

	& > span {
		display: grid;
		place-items: center;
	}

	&Active {
		border: 2px solid var(--border-active);
		outline: var(--border-subtle-emph-width) solid var(--button-disabled);

		&:hover {
			border: 2px solid var(--border-active);
			outline: var(--border-subtle-emph-width) solid
				var(--button-disabled);
		}
	}
}

.sharingOptionSmall {
	width: 122px;
	height: 116px !important;

	.buttonIcon {
		> div {
			height: min-content;
			width: min-content;
			transform: scale(1);
		}

		& svg {
			height: 56px;
			width: 56px;
		}
	}
}

.accessBox {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 16px 16px 16px 16px;
	border-radius: 8px;
	box-shadow: 0px 1px 4px rgba(21, 25, 45, 0.08);
	background-color: var(--layer-02-v2);
}

.buildingCircle {
	width: 42px;
	height: 42px;
	margin: 0 16px 0 0;
	padding: 9px 9.1px 10.4px 10px;
	border-radius: 21px;
	background-color: var(--layer-03-v2);
}

.arrowCircle {
	width: 32px;
	height: 32px;
	margin: 0 16px 0 0;
	padding: 9px;
	border-radius: 100px;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
	border: 1px solid var(--border-invisible-v2);
	background-color: var(--layer-03-v2);
	margin-left: auto;
	margin-right: 5px;
}

.workspaceAvatar {
	margin-right: 8px;
}

.hoverDisabled {
	background-color: var(--action-disabled);
}

.small {
	height: 122px;
	padding: 8px;
}
