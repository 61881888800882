@use "../table/TableGrid.module.scss" as grid;

.categoryWrapper {
	width: 100%;
	height: 100%;
	padding: 32px 24px;
	display: flex;
	gap: 48px;

	.left {
		height: 100%;
		min-width: 1px;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
	}

	.right {
		height: 100%;
		width: 37%;
		flex: 0 0 37%;
		border-radius: 16px;
		position: relative;
		background-color: var(--box-category-info);
	}
	.boxCategoryInfoWrapper {
		height: 100%;
		width: 100%;
		padding: 24px 32px;
	}

	.itemGroupList {
		overflow: auto;
	}

	.backToResults {
		display: flex;
		gap: 4px;
		align-items: center;
		margin-bottom: 24px;
		cursor: pointer;
	}

	.itemInfo {
		height: 100%;
		width: 100%;
		border-radius: 16px;

		.spin {
			height: 100%;
			width: 100%;

			> :global(.ant-spin-container) {
				padding: 24px 0;
			}
		}

		.itemViewAndEditWrapper {
			flex-grow: 1;
			height: 100%;
			overflow-y: auto;
		}

		:global {
			.ant-spin-nested-loading,
			.ant-spin-container {
				height: 100%;
				width: 100%;
			}
		}

		.header {
			padding: 0 32px;
			margin-bottom: 16px;
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: space-between;

			.headerOptions {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 16px;
			}

			.itemName {
				font: var(--font-largeTitle);
				font-weight: bold;
				// Make the floating buttons visible
				z-index: 1;
				// Align with the header
				margin-left: -8px;
				min-width: 1px;

				> div {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.tabs {
			flex: 1;
			// Height must be applied for the scrollbar to appear within
			// the tabs content.
			height: calc(100% - 76px); // Subtract height of the header

			:global {
				.ant-tabs {
					height: 100%;
					display: flex;
					flex-direction: column;
				}
				.ant-tabs-tab:first-child {
					margin-left: 32px !important;
				}
				.ant-tabs-content-holder {
					flex: 1;
					height: 100%;
					overflow: auto;
				}
				.ant-tabs-nav {
					margin-bottom: 16px !important;
				}
			}
		}

		.activityContainer {
			padding: 6px 32px;
		}
	}
}

.descriptionTextarea {
	> * {
		min-width: 1px;
	}
	width: 100%;
	position: relative;
	left: -8px;
	display: flex;
	min-height: 36px;

	> * {
		flex: 1;
	}
}
