.resetTitle {
	margin: 24px 0 32px;
}
.resultTitle {
	margin: 48px 0 24px;
}
.successDescription {
	margin-bottom: 48px;
}
.resultDescription {
	text-align: center;
	margin-bottom: 48px;
}
.loginButton {
	margin-top: 24px;
	text-decoration: underline;
}
.passwordRequirements {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.passwordVisibility {
	transform: translateX(4px);
	cursor: pointer;
}
@media (min-width: 1920px) {
	.resetTitle {
		margin: 32px 0 48px;
	}
	.resetButton {
		margin-top: 16px;
	}
}
