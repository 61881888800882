.title {
	margin-bottom: 16px;
}

.tableContainer {
	margin-top: 16px;
	margin-bottom: 24px;
}

.lastTableContainer {
	padding-bottom: 24px;
}

.lastTableContainer,
.tableContainer {
	:global {
		.ant-table-thead {
			border: none;
			background-color: var(--layer-02-v2);
		}

		.ant-table-row-cell-last > .ant-table-header-column {
			padding-left: 24px !important;
		}

		.ant-table-thead > tr > th {
			.ant-table-header-column {
				&:before {
					width: 0 !important;
					height: 0 !important;
					background-color: transparent !important;
				}
			}
		}

		.ant-table-tbody * {
			color: var(--text-secondary-v2) !important;
		}

		.ant-table-tbody tr:nth-child(1) * {
			font-weight: var(--font-weight-bold);
		}
	}
}
