.insertRow {
	min-height: 24px !important;
	height: 24px !important;

	th,
	td {
		min-height: 24px !important;
		height: 24px !important;
		padding: 0;
	}
	th {
		background-color: var(--layer-01) !important;
		cursor: pointer;
	}
}

.insertCol {
	min-width: 24px !important;
	width: 24px;
	padding: 0px !important;
	cursor: pointer;
	background-color: var(--layer-01) !important;
}

.insertColPlaceholder {
	padding: 0px !important;
	min-width: 24px !important;
	width: 24px;
}
