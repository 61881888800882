.genemodFilterSelect {
	.iconAndCount {
		display: flex;
		align-items: center;
		gap: 8px;

		@media (min-width: 1920px) {
			align-items: flex-start;
		}
	}

	:global {
		.genemod-select {
			border: none !important;

			.ant-select-selection {
				transition: 0s;
				background-color: var(--layer-02-v2);
				padding: 0 6px 0 4px;
				line-height: 24px;
				height: 32px;

				&:hover {
					background-color: var(--current-layer-hover);
				}

				div {
					align-items: center;
					font: var(--font-label);
					letter-spacing: 0.01px;
					max-width: 246px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.ant-select-arrow {
			right: 10px;
		}

		.ant-select-selection--single .ant-select-selection__rendered {
			margin-right: 22px;
		}

		.genemod-select-dropdown .ant-select-dropdown-menu {
			min-width: 152px;
			max-height: unset;

			div {
				max-width: 246px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.genemod-select-dropdown__isSmall {
			padding: 4px 0 0;

			.ant-select-dropdown-menu-item,
			.ant-select-dropdown-menu-item-group-title {
				height: 42px;
				padding: 0 16px;
			}
		}
	}

	&__default {
		:global {
			.genemod-select .ant-select-selection {
				padding-left: 14px;
			}
		}
	}

	.clearAll {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		height: 46px;
		background-color: var(--layer-03) !important;
		color: var(--link-primary);
	}
}

.bordered {
	border: 1px solid var(--border-invisible-v2);
}

.wrapperButton {
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	background-color: var(--layer-02-v2);
	border-radius: 4px;
	border: 1px solid var(--border-invisible-v2);
}
