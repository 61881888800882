.breadcrumb-button {
	z-index: 1;
	height: 26px;
	margin-top: 8px;
	margin-right: 8px;
	padding-left: 12px;
	padding-right: 7px;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--layer-02);
	border-radius: 50px;

	.breadcrumb-label {
		color: var(--text-secondary-v2);
		cursor: default;
	}

	&:hover {
		background-color: var(--layer-hover-01);
	}
}
