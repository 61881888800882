.datepicker {
	:global(.ant-picker),
	:global(.ant-picker-outlined) {
		width: 100%;
		background-color: var(--layer-02-v2);
		border: 1px solid var(--border-subtle-v2);

		&:hover {
			background-color: var(--layer-02-v2);
			border-color: var(--border-hover) !important;
		}

		&:focus {
			border-color: var(--border-active);
			box-shadow: 0 0 0 2px var(--border-subtle-v2);
		}
	}

	:global(.ant-picker-suffix) {
		color: var(--text-placeholder);
	}

	:global(.ant-picker-input) {
		& input {
			color: var(--text-secondary-v2);

			&::placeholder {
				color: var(--text-placeholder);
			}
		}
	}

	:global(.ant-picker-disabled) {
		background-color: var(--action-disabled);
		border: 1px solid var(--border-subtle-v2);
		&:hover {
			background-color: var(--action-disabled) !important;
			border-color: var(--border-subtle-v2) !important;
		}
		:global(.ant-picker-input) {
			& input {
				color: var(--text-placeholder);
			}
		}
		:global(.ant-picker-suffix) {
			color: var(--text-placeholder);
		}
	}

	:global(.ant-picker-status-error) {
		border-color: var(--error) !important;

		:global(.ant-picker-suffix) {
			color: var(--text-placeholder) !important;
		}
	}
	:global(.ant-picker-clear) {
		background: transparent;
	}
	:global(.anticon-close-circle) {
		color: var(--text-on-color);
	}
}

.popup {
	:global(.ant-picker-date-panel-container),
	:global(.ant-picker-year-panel),
	:global(.ant-picker-month-panel),
	:global(.ant-picker-time-panel),
	:global(.ant-picker-decade-panel) {
		border-radius: 4px;
		background-color: var(--layer-popover);
	}

	:global(.ant-picker-content) {
		& th {
			color: var(--text-secondary-v2);
		}
	}

	:global(.ant-picker-cell) {
		color: var(--text-disabled);
	}

	:global(.ant-picker-cell-in-view) {
		color: var(--text-secondary-v2);
	}

	:global(.ant-picker-footer) {
		background-color: var(--layer-popover);
		border-top: 1px solid var(--border-invisible-v2);
	}

	:global(.ant-picker-now-btn) {
		color: var(--action-default);
	}
	:global(.ant-picker-header) {
		color: var(--text-secondary-v2);
		border-bottom: 1px solid var(--border-invisible-v2);

		& button {
			color: var(--text-secondary-v2);
		}
	}
	:global(.ant-picker-cell-selected) {
		& div {
			background: var(--informative) !important;
			color: var(--text-on-color) !important;
		}
	}
	:global(.ant-picker-cell-today) {
		& div::before {
			border: 1px solid var(--informative) !important;
		}
	}
	:global(.ant-picker-cell-hover) {
		& div {
			background: var(--informative) !important;
			color: var(--text-on-color);
		}
	}
	:global(.ant-picker-panel-container) {
		background-color: var(--layer-popover);
	}
	:global(.ant-picker-ok) {
		& button {
			background-color: var(--action-default);
			color: var(--text-on-color);
			border-color: var(--action-default);

			&:hover {
				background-color: var(--action-default);
				color: var(--text-on-color);
				border-color: var(--action-default);
			}
		}
	}
	:global(.ant-picker-time-panel-cell-inner) {
		color: var(--text-secondary-v2) !important;
	}
	:global(.ant-picker-time-panel-cell-selected) {
		& div {
			background-color: var(--informative) !important;
		}
	}
	:global(.ant-picker-time-panel-column:not(.first-child)) {
		border-left: 1px solid var(--border-subtle-v2);
	}
}
