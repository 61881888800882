$success: var(--accent-subtle);
$darker: var(--button-disabled);

.passwordRequirements {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.passwordInput {
	margin-bottom: 12px;
}

.passwordReq {
	width: 50%;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	transform: translateY(-4px);
	.statusContainer {
		width: 14px;
		height: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		object-fit: contain;
		margin-right: 8px;
		cursor: default;
		.requirementStatus {
			height: 12px;
			width: 12px;
			border-radius: 50%;
			background-color: $darker;
		}
	}
	.requirementDescription {
		color: var(--text-secondary);
	}
	&__valid {
		.statusContainer .requirementStatus {
			background-color: $success;
		}
	}

	&__error {
		.statusContainer .requirementStatus {
			background-color: $darker;
		}
	}
}

.agreementLinks {
	color: var(--text-secondary) !important;
	text-decoration: underline !important;
}

.centerText {
	text-align: center;
}

.alreadyHaveAccount {
	margin-top: 24px;
	margin-bottom: 32px;

	@media (min-width: 1920px) {
		margin-top: 32px;
		margin-bottom: 48px;
	}
}

.nameInputsContainer {
	display: flex;
	gap: 24px;

	@media (min-width: 1920px) {
		gap: 32px;
	}
}

.betaBanner {
	height: 32px !important;
	width: 100vw !important;

	background-color: var(--accent-subtle);

	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 999;
}
