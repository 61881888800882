.boxCell {
	height: calc(100% - 2px);
	min-height: 80px;
	width: calc(100% - 2px);
	background-color: var(--layer-02-v2);
	border-radius: 8px;
	cursor: pointer;
	position: relative;
	border: 1px solid var(--border-invisible);
	transition: border 0.2s ease;
	aspect-ratio: 2/1;

	&:hover {
		border: 2px solid var(--border-hover);

		& .options {
			visibility: visible;
			opacity: 1;
		}
	}

	&.disabled {
		background-color: var(--layer-hover-02-v2);
		cursor: default;
		div {
			cursor: default !important;
		}
		& .cellName {
			color: var(--text-disabled);
		}
		&:hover {
			border: 1px solid var(--border-invisible);

			& .options {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
}

.line {
	height: 20px;
	border-bottom: 1px solid var(--border-invisible);
}

.options {
	position: absolute !important;
	right: 4px;
	top: 4px;
	border: 1px solid var(--border-invisible);
	background-color: var(--layer-03);
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s ease, opacity 0.2s ease;
}

.boxContentContainer {
	height: 100%;
}

.content {
	margin: 8px;
	height: calc(100% - 36px);
	overflow: hidden;
	position: relative;
	min-height: 44px;
}

.boxRearranging {
	animation: shake 0.4s infinite both;
	cursor: move;
}

.emptyCell {
	display: grid;
	place-items: center;
	background-color: var(--layer-accent-01-v2);

	& svg {
		color: var(--text-tertiary);
	}

	&:hover {
		& svg {
			color: var(--border-hover);
		}
	}
}

.shadow {
	box-shadow: var(--shadow-container);
}

.emptyCellContainer {
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
}

.noHover {
	pointer-events: none;
}

.cellName {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 16px);
	text-align: center;
	@media (min-width: 1920px) {
		width: calc(100% - 32px);
	}

	letter-spacing: 0.01px;
	display: -webkit-box !important;
	word-break: break-word;
	white-space: pre-wrap;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 20px;
}

@keyframes shake {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(3deg);
	}
	50% {
		transform: rotate(0deg);
	}
	75% {
		transform: rotate(-3deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes shake-rotate {
	0%,
	100% {
		transform: translate(0, 0) rotate(0deg);
	}
	10% {
		transform: translate(0, 0) rotate(-2deg);
	}
	20% {
		transform: translate(0, 0) rotate(2deg);
	}
	30% {
		transform: translate(0, 0) rotate(-2deg);
	}
	40% {
		transform: translate(0, 0) rotate(2deg);
	}
	50% {
		transform: translate(0, 0) rotate(-2deg);
	}
	60% {
		transform: translate(0, 0) rotate(2deg);
	}
	70% {
		transform: translate(0, 0) rotate(-2deg);
	}
	80% {
		transform: translate(0, 0) rotate(2deg);
	}
	90% {
		transform: translate(0, 0) rotate(-2deg);
	}
}

.shake {
	animation-name: shake-rotate;
	animation-duration: 2500ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
