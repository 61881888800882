.nameColumn {
	display: flex;
	flex-direction: row;
	align-items: center;
	div[name="archive"] {
		margin-right: 8px;
	}
	path {
		fill: var(--text-tertiary);
	}
}

.spacing {
	display: flex;
	gap: 8px;
	align-items: center;
}

.checkboxTable {
	:global {
		.genemod-checkbox-container {
			width: 32px;
			height: 32px;
			justify-content: center;
			gap: 0;
		}
		.checkbox-label {
			width: 0;
		}
	}
}

.locationContainer:hover > .noLocation {
	display: none;
}

.locationContainer:hover > .assignLocation {
	display: block;
}

.noLocation {
	display: block;
}

.assignLocation {
	display: none;
	cursor: pointer;
}
