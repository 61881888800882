.genemod-radiogroup {
	.ant-radio-group {
		padding-top: 16px;
		width: 100%;
	}
}
.genemod-radiobutton {
	min-height: 40px;
	display: flex;
	align-items: center;
	width: 100%;
	--button-dims: 14px;
	--button-inner-dims: 8px;
	--border-width: 1px;
	--offset: 2px;
	--fill-color: var(--button-primary);
	&__noLabel {
		width: fit-content;
	}

	.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus + .ant-radio-inner {
		border-color: var(--fill-color);
		width: var(--button-dims);
		height: var(--button-dims);
		border-width: var(--border-width);
		background-color: transparent;
	}

	.ant-radio-wrapper > .ant-radio {
		height: var(--button-dims);
	}

	.ant-radio-inner {
		border-color: var(--text-secondary);
		width: var(--button-dims);
		height: var(--button-dims);
		border-width: var(--border-width);
		background-color: transparent;

		&::after {
			background-color: var(--fill-color);
			width: var(--button-inner-dims);
			height: var(--button-inner-dims);
			top: var(--offset);
			left: var(--offset);
		}
	}
	.ant-radio-checked .ant-radio-inner {
		border-color: var(--fill-color);
	}

	span.ant-radio + * {
		padding: 0 0 0 16px;
		font: var(--font-body);
		letter-spacing: 0.01px;
		color: var(--text-secondary);
	}

	.ant-radio-wrapper {
		display: flex;
		align-items: baseline;
		margin-right: 0px;
		width: 100%;
		.ant-radio {
			padding-top: 2px;
		}
		> span:nth-child(2) {
			flex: 1;
			max-width: calc(100% - 18px);
			width: calc(100% - 18px);
		}
	}

	&__lightweight {
		--button-dims: 14px;
		--button-inner-dims: 8px;
		--border-width: 1px;
		--fill-color: var(--text-secondary);
		border-color: var(--text-secondary);

		.ant-radio {
			margin-top: 4px;
		}
		.ant-radio-checked {
			--fill-color: var(--text-secondary);
			border-color: var(--fill-color);
		}
		span.ant-radio + * {
			padding: 0 0 0 12px;
		}
	}
}

.genemod-radiobutton__lightweight.genemod-radiobutton__checked {
	--fill-color: var(--text-secondary);
}

.genemod-radiobutton__disabled {
	opacity: var(--disabled-opacity);
}
