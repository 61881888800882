$large-border-radius: 4px;
$small-border-radius: 2px;

.genemod-select-container {
	display: flex;
	flex-direction: column;
}

.genemod-select {
	border: 1px solid transparent;
	border-radius: $large-border-radius;
	height: 100%;
	border: var(--border-subtle-solid-v2) !important;
	&:hover {
		border-color: var(--border-hover) !important;
	}

	.ant-select-search__field {
		width: auto;
	}

	.ant-select-selector {
		border-radius: $large-border-radius !important;
		height: 32px !important;
		border: none !important;
		color: var(--text-secondary-v2) !important;
		display: flex !important;
		align-items: center !important;
		box-shadow: none !important;
		background-color: var(--layer-02-v2) !important;
		pointer-events: all !important;
		cursor: pointer !important;
	}

	&__hasTags {
		.ant-select-selector {
			margin: 6px 0 6px 6px !important;
		}
		padding-right: 6px !important;
	}

	.ant-select-selection-placeholder {
		font: var(--font-body);
		line-height: 24px;
		color: var(--text-placeholder) !important;
		letter-spacing: 0.01px;
	}

	&__isStrokeIcon {
		.ant-select-selector {
			&[aria-expanded="true"] {
				path,
				circle {
					fill: none !important;
					stroke: var(--link-primary) !important;
				}
			}
		}
	}

	.ant-select-selection-overflow {
		height: auto !important;
		.ant-select-selection-item {
			background-color: var(--layer-hover-03-v2) !important;
		}
	}

	.ant-select-selection-item {
		margin-left: 0 !important;
		font: var(--font-label) !important;
		color: var(--text-secondary-v2) !important;
		letter-spacing: 0.01px !important;
		margin-right: 4px !important;
		line-height: 18px !important;
	}

	&.ant-select-focused {
		border-radius: $large-border-radius;
		box-shadow: 0 0 0 2px var(--border-subtle) !important;
	}

	.ant-select-arrow {
		color: var(--text-primary);
		top: 0;
		margin-top: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.ant-select-item-option-selected {
		font: var(--font-label) !important;
		color: var(--brand-08) !important;
		letter-spacing: 0.01px !important;
		line-height: 18px !important;
	}

	&__isSmall {
		border-radius: 2px !important;
		.ant-select-selector {
			height: 24px !important;
			border-radius: $small-border-radius !important;
		}
		&.ant-select-focused {
			border-radius: $small-border-radius !important;
			box-shadow: 0 0 0 2px var(--border-subtle) !important;
		}
	}

	&__isLarge {
		border-radius: 2px !important;
		.ant-select-selector {
			height: 40px !important;
			border-radius: $small-border-radius !important;
		}
		&.ant-select-focused {
			border-radius: $small-border-radius !important;
			box-shadow: 0 0 0 2px var(--border-subtle) !important;
		}
	}

	&__hasTags {
		background-color: var(--layer-02-v2) !important;
		.ant-select-selector {
			height: auto !important;
			margin: 4px 0 4px 8px !important;
		}
		padding-right: 8px !important;
	}

	/** Select components displayed along with Input component */
	&__isInput,
	&__error {
		width: 100% !important;
		border: var(--border-subtle-solid) !important;
		.ant-select-selector {
			width: 100% !important;
			height: 32px !important;
			background-color: var(--layer-02-v2) !important;
		}
		.ant-select-selection-placeholder {
			font: var(--font-body);
			line-height: 24px;
			color: var(--text-placeholder) !important;
			letter-spacing: 0.01px;
		}
		.ant-select-item-option-selected {
			font: var(--font-body) !important;
			letter-spacing: 0.01px !important;
		}
		&::before {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 2px;
			bottom: 0;
			content: "";
			opacity: 0;
		}
		&.ant-select-focused {
			border: 1px solid var(--border-subtle) !important;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			box-shadow: 0 0 0 2px var(--border-subtle) !important;
			&::before {
				opacity: 1;
				background-color: var(--border-active);
			}
			.ant-select-item-option-selected {
				color: var(--brand-08) !important;
			}
			.ant-select-arrow {
				path {
					fill: var(--text-secondary);
				}
			}
		}
		&.ant-select-focused .ant-select-selector {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	&__error {
		border: 1px solid var(--red) !important;
	}

	.ant-select-selector {
		width: 100%;
		background-color: var(--current-layer-input-background);
	}

	&__borderless {
		border: none !important;
		.ant-select-selector {
			border: 1px solid transparent !important;
			background-color: transparent !important;
			padding-left: 8px !important;
			padding-right: 0px !important;
			&:hover {
				background-color: var(--layer-hover-03-v2) !important;
				border-radius: 4px !important;
			}
		}
		&.ant-select-focused .ant-select-selector {
			border: 1px solid var(--border-active) !important;
			border-radius: 4px !important;
			box-shadow: 0 0 0 2px var(--border-subtle) !important;
		}
	}
}

.genemod-select__hasTags.genemod-select__borderless {
	.ant-select-selector {
		margin: 0 !important;
	}
	padding-right: 0 !important;
}

.ant-select-disabled {
	cursor: default !important;
	&:hover {
		border: var(--border-subtle-solid-v2) !important;
	}
	.ant-select-selector {
		cursor: default !important;
		background-color: var(--action-disabled) !important;
	}
	.ant-select-selection-item {
		color: var(--text-disabled) !important;
	}
	.ant-select-selection-search {
		input {
			cursor: default !important;
		}
	}
}

.ant-select-clear {
	top: 40% !important;
	width: 18px !important;
	height: 18px !important;
}

.genemod-select-dropdown {
	padding: 0 !important;
	border-radius: $large-border-radius;
	background-color: var(--layer-popover);
	.ant-select-item,
	.ant-select-item-option {
		background-color: var(--layer-popover);
		color: var(--text-secondary-v2);
		height: 48px;
		padding: 14px 16px;
		font: var(--font-label);
		letter-spacing: 0.01px;

		&-active,
		&-active:not(.ant-select-item-option-disabled) {
			background-color: var(--layer-hover-02-v2) !important;
		}
	}

	.ant-select-dropdown-menu-item-group-title {
		padding-left: 8px;
	}

	.ant-select-dropdown-menu {
		padding: 0;
		box-shadow: var(--shadow-container);
		max-height: 256px;
	}

	.ant-select-item-option-selected {
		color: var(--brand-08) !important;
		background-color: transparent !important;

		&:hover {
			background-color: var(--layer-hover-03) !important;
		}
	}

	&__isSmall {
		padding: 4px 0px;
		border-radius: $small-border-radius;

		.ant-select-item,
		.ant-select-item-option {
			display: flex;
			align-items: center;
			height: 42px;
			line-height: 42px;
			padding: 11px 16px;
			font: var(--font-label);
			letter-spacing: 0.01px;
		}
	}

	&__isInput,
	&__error {
		padding: 4px 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border: var(--border-subtle-solid);
		.ant-select-item,
		.ant-select-item-option {
			height: 42px;
			padding: 12px 16px;
		}
	}

	/**Styling for SelectedOpt*/
	.selected-option-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.ant-select-item-option:hover:not(.ant-select-item-option-disabled) {
	background-color: var(--layer-hover-03);
}
