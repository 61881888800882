.customDatePicker {
	display: flex;
	flex-direction: column;
	padding: 16px;
	width: 280px;
	background-color: var(--layer-03);
	border-radius: 2px;
	border: var(--border-subtle-solid);
	border-bottom: none;
	color: var(--text-primary);
	.header {
		.iconAndText {
			display: flex;
			flex-direction: row;
		}
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;
		padding-right: 11px;
	}

	.dateInputContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}
	.dataInputLabel {
		display: flex;
		flex-direction: column;
		gap: 31px;
	}
}

.pickerFooter {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	pointer-events: visible;
	cursor: pointer;
}

/** style of date range picker inputs*/
.custom_picker {
	:global {
		flex-direction: column;
		gap: 12px;
		width: 256px;
		background-color: transparent;
		border: none;
		box-shadow: none;
		.ant-picker-input {
			border: var(--border-subtle-solid);
			height: 36px;
			padding: 16px 10px;
			border-radius: 4px;
			> input {
				color: var(--text-primary);
				font: var(--font-body);
				letter-spacing: 0.01px;
			}
			&.ant-picker-input-active {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				border-bottom: 2px solid var(--button-primary);
				background-color: var(--layer-03);
			}
			&:hover {
				background-color: var(--layer-hover-03);
			}
		}
		.ant-picker-range-separator {
			display: none;
		}
		.ant-picker-active-bar {
			display: none;
		}
		.ant-picker-suffix {
			display: none;
		}
	}
}

/** style of dropdown date range picker*/
.custom_picker_dropdown {
	:global {
		width: 280px;
		left: 0 !important;
		top: 172px !important;
		@media (min-width: 1920px) {
			top: 176px !important;
		}
		padding: 0px;
		border: var(--border-subtle-solid);
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
		background-color: var(--layer-03);
		color: var(--text-primary) !important;
		.ant-picker-header {
			border-bottom: none;
		}
		.ant-picker-range-arrow {
			display: none;
		}
		button,
		button:hover {
			color: var(--text-primary);
		}
		.ant-picker-panel {
			border: 0;
		}
		.ant-picker-panel-container {
			background-color: var(--layer-03);
			box-shadow: none;
			th {
				color: var(--text-secondary);
				font: var(--font-caption);
			}

			.ant-picker-content {
				.ant-picker-cell {
					color: var(--text-primary);
					font: var(--font-label);
					background-color: transparent;
					padding: 3.5px 0px;
					// hide if dates are out of current view month
					&:not(.ant-picker-cell-in-view) {
						visibility: hidden;
					}
					.ant-picker-cell-inner {
						transition: none;
						line-height: 21px;

						&::before {
							border: none;
							border-radius: 50%;
						}
					}

					&.ant-picker-cell-disabled .ant-picker-cell-inner {
						color: var(--text-tertiary);
					}
					&.ant-picker-cell-range-start,
					&.ant-picker-cell-range-end {
						.ant-picker-cell-inner {
							color: var(--text-primary);
							background-color: var(--link-primary);
							border-radius: 50%;
						}
						&::before {
							background-color: var(--link-primary-hover);
						}
					}
					&.ant-picker-cell-range-start-single,
					&.ant-picker-cell-range-end-single {
						&::before {
							background-color: transparent;
						}
					}

					&.ant-picker-cell-in-range {
						&::before {
							background-color: var(--link-primary-hover);
						}
					}
				}
				// hover effect on dates
				.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)
					.ant-picker-cell-inner {
					background-color: var(--link-primary-hover);
					border-radius: 50%;
					&::after {
						background-color: transparent;
					}
				}
				// background color on selected date
				.ant-picker-cell-in-view.ant-picker-cell-selected
					.ant-picker-cell-inner {
					background-color: var(--link-primary);
				}
				// border color of hover dates
				.ant-picker-cell.ant-picker-cell-in-view {
					&.ant-picker-cell-range-hover-start,
					&.ant-picker-cell-range-hover-end,
					&.ant-picker-cell-range-hover {
						&::after {
							border-color: var(--border-active);
						}
					}
					&.ant-picker-cell-range-hover-end {
						&::after {
							border-top-right-radius: 50% !important;
							border-bottom-right-radius: 50% !important;
						}
					}
					&.ant-picker-cell-range-hover-start {
						&::after {
							border-top-left-radius: 50% !important;
							border-bottom-left-radius: 50% !important;
						}
					}
				}
			}
		}
		/** disable to change month or year mode */
		.ant-picker-header-view {
			pointer-events: none;
		}
		/** show only one date picker*/
		.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
			visibility: visible !important;
		}

		.ant-picker-panels
			> *:first-child
			button.ant-picker-header-super-next-btn {
			visibility: visible !important;
		}

		.ant-picker-panels > *:last-child {
			display: none;
		}

		.ant-picker-panel-container,
		.ant-picker-footer {
			width: 280px !important;
		}

		.ant-picker-footer-extra {
			padding: 16px;
			padding-top: 0px;
			> div {
				flex-wrap: wrap !important;
			}
		}
	}
}
