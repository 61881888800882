.dateRangeFilter {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.dateRangeRow {
		display: grid;
		grid-template-columns: 32px 1fr;
		gap: 8px;
		align-items: center;
	}
}

.optionLabel {
	display: grid;
	grid-template-columns: 1fr max-content;
	gap: 8px;
	align-items: center;
}

.filterCardSkeleton {
	height: 133px;
	width: 216px;
	background-color: var(--layer-02);
	border: 1px solid var(--border-subtle);
	border-radius: 8px;
	box-shadow: var(--shadow-container);
	margin-bottom: 24px;

	.skeletonHeader {
		background-color: var(--layer-03);
		height: 42px;
		border-bottom: 1px solid var(--border-subtle);
		padding: 0 12px;
		display: flex;
		align-items: center;
	}

	.skeletonBody {
		padding: 8px;
		.skeletonRow {
			display: flex;
			align-items: center;
			gap: 12px;
			height: 36px;
		}
	}
}
