.colorPanel {
	display: flex;
	flex-wrap: wrap;
}

.color {
	height: 32px;
	width: 32px;
	position: relative;
	justify-content: center;

	&Indicator {
		height: 14px;
		width: 14px;
		border-radius: 50%;
		z-index: 1;
	}

	&Selected {
		height: 18px;
		width: 18px;
		position: absolute;
		border-radius: 50%;
		background-color: transparent;
		border: 1px solid var(--layer-01);
		z-index: 0;
		justify-content: center;
	}
}

.nullColor {
	height: 14px;
	width: 14px;
	border-radius: 50%;
	background-color: var(--layer-03);
	border: 1px solid var(--text-secondary);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		height: 13px;
		width: 1px;
		position: absolute;
		content: "";
		transform: translateX(-0.75px) translateY(-0.25px) rotate(45deg);
		background-color: var(--text-secondary);
	}
}

.children {
	z-index: 1;
}

.colorPickerIndicatorWrapperContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	.colorPickerIndicator {
		height: 2px;
		width: 13px;
	}
}

.select {
	background-color: var(--layer-01-v2);
}

.selectDropdown {
	:global(.rc-virtual-list-holder-inner) {
		display: grid !important;
		grid-template-columns: repeat(3, 32px);
		grid-template-rows: repeat(3, 32px);
	}

	:global(.ant-select-item) {
		padding: 0 !important;
		height: 32px !important;
	}
}

.highlight {
	:global(.rc-virtual-list-holder-inner) {
		grid-template-rows: repeat(2, 32px);
	}
}
