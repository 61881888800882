.freezer-shelf {
	min-height: 74px;
	user-select: none;
	width: 100%;
	border-bottom: var(--border-subtle-solid);
	padding: 24px 0 0 4px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	&:first-child {
		padding: 16px 0 0 4px;
	}
	&:last-child {
		border-bottom: none;
	}
	.remaining-rack-number {
		display: flex;
		color: var(--text-primary);
		align-items: center;
	}
	.row-header {
		display: flex;
		align-items: flex-start;
		padding: 18px 0 8px 10px;
		width: 42px;
		margin-right: 12px;
		color: var(--text-primary);
		div {
			cursor: pointer;
		}
	}
}
.shelf-name-section {
	width: 100%;
	display: flex;
	align-items: center;
	padding-right: 8px;
	padding-left: 12px;

	.icon-and-name {
		display: flex;
		align-items: center;
		.shelf-name,
		.empty-shelf-name {
			max-width: 350px;

			color: var(--text-primary);
			font: var(--font-subheadline);
			font-weight: bold;

			> div {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.empty-shelf-name {
			color: var(--text-tertiary);
			font-style: italic;
			cursor: pointer;
		}
	}
}
.shelf-content-wrapper {
	width: 100%;
	.empty-shelf {
		width: 100%;
		padding: 56px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.modal-empty-shelf {
		height: 112px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.shelf-options {
		position: absolute;
		top: 8px;
		right: 8px;
		opacity: 0;
		transition: 0.1s;
	}
	&:hover .shelf-options {
		opacity: 1;
	}
	// Area for racks
	.shelf-content {
		width: 100%;
		height: fit-content;
		margin-bottom: 8px;
		display: flex;
		flex-wrap: wrap-reverse;
		align-items: flex-end;
		align-content: flex-start;

		&::-webkit-scrollbar {
			display: none;
		}
	}
	.shelf-add-icon {
		flex: 0 0 16px;
		margin-left: 8px;
		height: 100%;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
}

.shelf-icon {
	&:hover {
		background-color: var(--layer-hover-01) !important;
	}
}
