.freezer-content {
	height: 100%;
	min-width: 1280px;

	#freezer-navbar {
		height: var(--navbar-height);
	}

	#freezer {
		height: calc(100% - var(--navbar-height));
		width: 100%;
		color: var(--text-primary);
		position: relative;
		display: flex;
	}
}
