.progressBar {
	display: flex;
	align-items: center;
	gap: 12px;
}

.progressCircle {
	background: var(--button-disabled);
	color: var(--text-on-color);
	border-radius: 50%;
	width: 24px;
	height: 24px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 24px;
	text-align: center;
	cursor: not-allowed;
	&.isActive {
		background: var(--informative);
		cursor: pointer;
	}
	&.isPreviousStep {
		cursor: pointer;
	}
}

.progressLine {
	width: 60px;
	height: 2px;
	background: var(--button-disabled);
	&.isActive {
		background: var(--informative);
	}
}
