.content {
	width: 188px;
}

// Double selector for specificity
.overlay.overlay {
	:global(.ant-popover-inner-content) {
		padding: 16px;
	}
}

.separator {
	height: 1px;
	margin: 16px 0;
	background-color: var(--border-subtle);
	position: relative;

	// We want a full width line.
	// Adding padding and shift over
	width: calc(100% + 32px);
	left: -16px;
}

.usageRow {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.circleWrapper {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.circleSvg {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		transform: rotate(-90deg);
		stroke-linecap: round;

		.progress {
			animation: offset 1.1s ease forwards;
		}

		@keyframes offset {
			from {
				stroke-dashoffset: 100;
			}
		}
	}
}
