@use "common/styles/mixins";

$active-card-opacity: 1;
.container {
	display: flex;
	flex-direction: column;
}

.container,
.containerPreview {
	width: 100%;
	height: 192px;
	border-radius: 8px;
	box-shadow: var(--shadow-container);
	position: relative;
	cursor: pointer;
	background-color: var(--layer-01-v2) !important;

	.content {
		margin-top: auto;
		height: 50%;
		padding: 16px;
		display: flex;
		gap: 16px;
		align-items: center;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;

		.iconContainer {
			height: 48px;
			aspect-ratio: 1;
			background-color: var(--layer-accent-01-v2);
			display: grid;
			place-items: center;
			border-radius: 50px;
		}

		.spaceName {
			max-width: 186px;
			word-break: break-word;
			white-space: pre-wrap;
			display: -webkit-box !important;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
	.type {
		display: flex;
		align-items: center;
		gap: 4px;
		z-index: 2;
		color: var(--text-primary-v2);
		margin-top: auto;
	}
	.option {
		opacity: 0;
		align-self: flex-end;
		position: absolute;
		top: 12px;
		right: 12px;
	}
	.darkEffect {
		width: 100%;
		height: 50%;
		top: 0;
		left: 0;
		background-size: cover;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}

	outline: 2px solid transparent;
	transition: outline 0.2s ease-in, border 0.2s ease-in;
	&:hover,
	&__focused {
		outline: 4px solid var(--border-subtle-v2);
		border: 4px solid var(--border-hover);
		border-radius: 12px;

		.option {
			opacity: 1;
			transition: opacity 0.15s;
			z-index: 3;

			margin: 0 !important;
			top: 8px;
			box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
			border-radius: 4px;
			background-color: var(--layer-03-v2);
			border: 1px solid var(--border-invisible-solid-v2);
			box-sizing: border-box;
			overflow: hidden;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.darkEffect {
			transition: 0.1s;
			opacity: $active-card-opacity;
		}
	}
	&__archived {
		.content {
			.name {
				color: var(--text-secondary-v2);
			}
		}
	}
	&__locked {
		cursor: default;
		.content {
			.name {
				color: var(--text-secondary-v2);
			}
		}
		.type {
			color: var(--text-secondary-v2);
		}
		.darkEffect {
			opacity: $active-card-opacity;
		}
		&:hover {
			@include mixins.absoluteBorder(var(--border-subtle-v2));
			.darkEffect {
				opacity: $active-card-opacity;
			}
		}
	}
}

/** Special stylings for preview only Spacecards*/
.containerPreview {
	cursor: default;
	&:hover {
		.darkEffect {
			opacity: $active-card-opacity;
		}
	}
}
