.XS_COLUMN_WIDTH {
	width: 88px;
	// 1920px and above
	@media (min-width: 1920px) {
		width: 140px;
	}
}

.S_COLUMN_WIDTH {
	width: 140px;
	// 1920px and above
	@media (min-width: 1920px) {
		width: 212px;
	}
}

.M_COLUMN_WIDTH {
	width: 192px;
	// 1920px and above
	@media (min-width: 1920px) {
		width: 308px;
	}
}

.L_COLUMN_WIDTH {
	width: 240px;
	// 1920px and above
	@media (min-width: 1920px) {
		width: 384px;
	}
}

.VIEW_COLUMN_WIDTH {
	width: 64px;
}

.BOOK_MARK_COLUMN_WIDTH {
	width: 56px;
}

.content_created_by {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	.date {
		margin-left: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
