.container {
	padding: 32px;
	display: grid;
	grid-template-columns: 624px 111px;
	justify-content: space-between;
}

.shelfRow {
	display: flex;
	gap: 8px;
	min-height: 132px;
	border-bottom: 1px solid var(--border-subtle);
	padding: 24px 8px;
}

.selectedShelf {
	background-color: var(--layer-02);
}

.enablePointer {
	cursor: pointer;
}

.actions {
	width: 148px;
}

.racks {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 8px;
}

.rackCard {
	cursor: pointer;
	background-color: var(--layer-accent-01);
	height: 84px;
	width: 84px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 4px;
	position: relative;
}

.selected {
	background-color: var(--layer-03);
	border: 1px solid var(--border-subtle);

	&::after {
		opacity: 0.3;
	}
}

.rack {
	&::after {
		content: "";
		height: 6px;
		width: 52px;
		position: absolute;
		top: 16px;
		left: 50%;
		background-color: var(--accent-subtle);
		opacity: 0.1;
		border-radius: 50px;
		transform: translateX(-50%);
	}
}

.icon {
	position: absolute !important;
	top: 4px;
	left: 4px;
	opacity: 0.3;
}

.rackNameContainer {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 100%;
	width: 100%;
	max-width: 70px;
	margin-top: 10px;
}

.rackName {
	max-height: 48px;
	display: -webkit-box;
	white-space: break-spaces;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
