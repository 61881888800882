.iconOpen {
	border-radius: 2px;
	background-color: var(--layer-hover-01);
}

.panelOpen {
	border-radius: 2px;
	background-color: var(--current-layer-hover);
}

.crumbs {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.waffleMenu {
	.link {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.multipleCrumbs {
	&:hover * {
		color: var(--button-text) !important;
		stroke: var(--button-text) !important;
	}
}

.buttonFocused {
	transition: 0.2s;
	background-color: var(--current-layer-hover);
}

.navbarSearch {
	display: flex;
	align-items: center;
	border-radius: 4px;
	overflow: hidden;
	transition: 0.4s;
	height: 32px;

	.searchBtn {
		cursor: pointer;
		margin-right: 8px;
	}

	&__expanded {
		width: 320px !important;
		background-color: var(--layer-01);
		user-select: none;
		padding: 6px 8px;

		.searchbarContents {
			width: calc(100% - 35px) !important;
			display: flex !important;
		}
	}

	.searchbarContents {
		display: none;
		align-items: center;
		justify-content: space-between;
		width: 0;
		font: var(--font-body);
		letter-spacing: 0.01px;

		.numResults {
			user-select: none;
			display: flex;
			white-space: nowrap;
			color: var(--text-secondary);
		}

		.resultsNavigation {
			display: flex;
		}
	}

	input {
		background-color: transparent;
		border: none;
		outline: none;
		flex: 1 1 50%;
		width: calc(50% - 8px);
		margin-right: 8px;

		&::placeholder {
			color: var(--text-ghost);
		}
	}
}
