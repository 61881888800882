.tabCount {
	background: #5a617c1a;
	padding: 4px 8px;
	border-radius: 10px;
	font: var(--footnote-v2);
	color: var(--text-tertiary-v2);
}

.genemodTabContainer {
	:global {
		.tab-pane-rendering {
			overflow-y: auto;
			height: 100%;
		}
		.ant-tabs-top > .ant-tabs-nav {
			margin: 0 0 24px 0;
		}
		.ant-tabs {
			max-width: 100%;
			.ant-tabs-nav {
				width: 100%;
			}
			.ant-tabs-nav-operations {
				display: none;
			}
			.ant-tabs-nav-wrap {
				.ant-tabs-ink-bar {
					bottom: -0.5px;
					background-color: var(--informative);
				}
				.ant-tabs-nav-list {
					border-bottom: 1px solid var(--border-subtle-v2);
					width: 100%;
				}
				.ant-tabs-tab {
					height: 18px;
					font: var(--regular-v2);
					letter-spacing: 0.01px;
					padding: 0;
					color: var(--text-secondary-v2);
					margin: 13px 0;
					&:last-child {
						margin-right: 0px;
					}
					&:hover,
					&:hover .text-primary-typography {
						color: var(--informative);
					}
				}
				.ant-tabs-tab-btn {
					&:focus-visible {
						outline: none;
					}
					&:focus {
						outline: none;
					}
				}
			}
		}
	}
}

:global {
	.ant-tabs-tab-with-remove {
		&:hover {
			& div,
			& span {
				color: var(--action-hover) !important;
				fill: var(--action-hover) !important;
			}
		}
	}
	.ant-tabs-tab-active.ant-tabs-tab-with-remove {
		& div,
		& span {
			color: var(--text-secondary-v2) !important;
			fill: var(--text-secondary-v2) !important;
		}
	}
	.ant-tabs-tab-active {
		& div,
		& span {
			color: var(--informative) !important;
			fill: var(--informative) !important;
		}
	}
	.ant-tabs-tab-disabled {
		& div,
		& span {
			color: var(--text-disabled) !important;
			fill: var(--brand-08) !important;
		}
		&:hover .text-primary-typography {
			color: var(--text-disabled) !important;
		}
	}
}

.genemodTabContainer.smallSize {
	:global {
		.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
			margin: 7px 0;
		}
	}
}
.genemodTabContainer.largeSize {
	:global {
		.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
			margin: 16px 0;
		}
	}
}

.withInSettingsScrollHeight {
	:global {
		.ant-tabs-content-holder {
			height: calc(100vh - 220px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}
.genemodTabContainerSettings {
	:global {
		.ant-tabs {
			.ant-tabs-nav {
				width: calc(100% - 24px);
			}
			.ant-tabs-content-holder {
				padding-right: 24px;
			}
		}
	}
}
.genemodTabContainerCentered {
	:global {
		.ant-tabs-nav-wrap {
			display: flex;
			justify-content: center;
		}

		.ant-tabs-tab {
			flex: 1;
			text-align: center;
		}
	}
}

.fullHeight {
	height: 100%;
}

.tabs {
	:global(.ant-tabs-extra-content) {
		border-bottom: 2px solid var(--border-subtle);
		height: 34px;
		padding-right: 10px;
	}
}
