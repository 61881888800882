.main {
	display: flex;
	align-items: center;
	background-color: var(--current-layer);
	padding: 8px 12px;
	border-radius: 4px;
	margin-bottom: 16px;
}

.link {
	margin-left: auto;
	cursor: pointer;
}
.learnMore {
	margin-left: auto;
}
