.information-form {
	height: 100%;

	.form-body {
		padding-top: 0;
		width: 100%;
		height: calc(100% - 80px); // Subtract height of header
		overflow: auto;

		.divider {
			width: 100%;
			position: relative;
			padding: 0;

			&:before {
				content: "";
				width: 100%;
				height: 1px;
				border-radius: 10px;
				position: absolute;
				top: calc(50% - 2px);
				background: var(--border-subtle);
			}
		}
	}
	::-webkit-scrollbar {
		width: 8px;
	}

	.form-label {
		margin-bottom: 8px;
	}
	.form-input {
		input:disabled {
			background-color: transparent;
		}
	}

	.form-buttons {
		display: flex;
	}

	.antibiotics-selector .ant-select-selection__rendered {
		transform: translateY(-3px);
	}
	.amoutOfCopiesInput {
		margin-bottom: 24px;
		> div {
			background-color: var(--layer-03) !important;
			border: 1px solid var(--border-subtle-v2);
		}
	}
}
.leftDropdown {
	top: 10px;
	right: 10px;
}
.rigthDropdown {
	top: 10px;
	right: 10px;
}
.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 24px; // minus the border height
	border-bottom: var(--border-subtle-emph-width) solid var(--border-subtle);

	.item-name {
		margin-right: 32px;
		color: var(--text-primary);
	}
	.form-buttons {
		display: flex;
	}
}
