.RatingAndCommentWrapper {
	display: flex;
	flex-direction: column;
	gap: 11px;
	align-items: center;
	.ratings {
		display: flex;
		flex-direction: row;
		gap: 24px;
		.emojiContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			.emoji {
				opacity: 0.3;
				font-size: 40px;
				height: 53px;
				line-height: 1.5;
				img {
					width: 40px;
				}
			}
			.emojiPlaceholder {
				opacity: 0;
			}
			&:hover {
				.emoji {
					opacity: 0.7;
				}
				.emojiPlaceholder {
					opacity: 1;
				}
			}
			&__selected {
				.emoji {
					opacity: 1 !important;
				}
				.emojiPlaceholder {
					opacity: 1 !important;
				}
			}
		}
	}
}
