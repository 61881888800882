.wrapperButton {
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	border-radius: 4px;
	display: flex;
	border: 1px solid var(--border-invisible-v2);

	:global(.ant-select),
	:global(.ant-select-outlined),
	:global(.ant-select-selection-wrap) {
		width: 32px !important;
		height: 32px !important;
	}

	:global(.ant-select-selection-wrap) {
		position: initial;
	}

	:global(.ant-select-selection-search) {
		inset-inline-start: 0px !important;
		inset-inline-end: 0px !important;
		border-radius: 4px;
		&:hover {
			border: 1px solid var(--action-hover) !important;
		}
	}
}
