// -----
// Styles of the Form Item component
// -----

.formItem {
	position: relative;
	.formLabel {
		margin-bottom: 8px;
		color: var(--text-primary);
	}

	.formInput {
		input:disabled {
			background-color: transparent;
		}

		input[type="number"] {
			color: var(--text-secondary);
			background-color: var(--layer-03) !important;
		}

		:global(.ant-picker),
		:global(.ant-input),
		:global(.ant-select-selector),
		:global(.ant-picker-outlined) {
			width: 100%;
			background-color: var(--layer-03) !important;
			border: 1px solid var(--border-subtle-v2);

			&:hover {
				background-color: var(--layer-02);
				border-color: var(--border-active) !important;
			}

			&:focus {
				background-color: var(--layer-02);
				border-color: var(--border-active) !important;
			}
		}
	}
}

// -----
// Styles of the Form Section component
// -----

.formSection {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	padding: 0 32px 32px;
}
.formSectionTitleWrapper {
	width: 100%;
	height: 24px;
	display: flex;
	align-items: center;
	margin: 24px 0;
	cursor: pointer;
}

.formSectionTitle {
	margin-left: 0;
	font-weight: bold !important;
}

// Provides a strict unit so we can divide up grid evenly in all cases
// See: https://stackoverflow.com/a/61240964/16098999
$strict-grid-fraction: minmax(0, 1fr);

.sectionItemsContainer {
	display: grid;
	width: 100%;
	// divided into 6 columns so we can arrange by halves and thirds
	grid-template-columns: [full-start first-half-start first-third-start] $strict-grid-fraction $strict-grid-fraction [first-third-end second-third-start] $strict-grid-fraction [first-half-end second-half-start] $strict-grid-fraction [second-third-end third-third-start] $strict-grid-fraction $strict-grid-fraction [full-end second-half-end third-third-end];
	column-gap: 24px;
	row-gap: 24px;
}

.datePicker {
	margin-bottom: 32px;
}

.textAreaContainer {
	border: none;
}
