.editable-settings-container {
	width: calc(100% + 24px);
	// This is usually in a gradient container so we subtract the height of the gradient so it doesn't overflow
	height: calc(100% - 4px);
	overflow: auto;
	.settings-inner-content {
		padding-right: 24px;
		padding-bottom: 32px;
	}
	.setting-header {
		.setting-label {
			margin-bottom: 8px;
			color: var(--text-tertiary);
		}
		.title-and-edit {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}
		.buttons {
			display: flex;
			gap: 8px;
		}
		.setting-title {
			float: left;
			width: 95%;
			flex: 1;
			white-space: normal;
		}
		.edit-btn {
			float: right;
		}
	}
	.edit-top-section {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 16px;
		border-bottom: var(--border-subtle-solid);
		margin-bottom: 16px;
		.edit-view-buttons {
			display: flex;
			align-items: center;
			#cancel-button {
				margin-right: 16px;
			}
		}
	}
	table {
		width: 100%;
		font-size: 16px;

		tr {
			height: 48px;
			td {
				.modal-button.disabled {
					display: none;
				}

				.modal-button.disabled:hover {
					cursor: not-allowed;
				}
			}
		}
	}
}
