.tableContainer {
	width: 618px;
	border: 1px solid var(--editor-table-cell-selected-border);
	border-radius: 3px;
}

.tableHeader {
	display: flex;
	padding: 15px 12px;
	border-bottom: 1px solid var(--editor-table-cell-selected-border);
}

.column {
	flex: 4;
}

.extraColumn {
	flex: 1;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.noCustomFieldsContainer {
	padding: 15px 12px;
}

.tableRow {
	display: flex;
	padding: 15px 12px;
	border-top: 1px solid var(--editor-table-cell-selected-border);
	&:hover .deleteIcon {
		display: flex;
	}
}

.deleteIcon {
	display: none;
}

.newField {
	display: flex;
	border-top: 1px solid var(--editor-table-cell-selected-border);
}

.inputContainer {
	flex: 4;
}

.optionsRow {
	width: 88px;
}
