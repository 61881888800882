.reviseButton {
	padding-left: 12px !important;
	padding-right: 12px !important;

	&.selected {
		background-color: var(--blue) !important;
		color: var(--text-on-color) !important;
		border-color: var(--blue) !important;
	}
}

.reviseToolbar {
	display: flex;
	gap: 24px;
	z-index: 5 !important;
	width: 566px;
	padding: 16px;
	border-radius: 8px;

	box-shadow: var(--shadow-container);

	.header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		.headerLeft {
			display: flex;
			align-items: center;
			gap: 16px;
		}
	}
	.buttons {
		display: flex;
		gap: 8px;
	}
	.text {
		padding: 16px;
		border-radius: 12px;

		&.isFetching {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.regenerateButton {
			display: flex;
			gap: 8px;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
