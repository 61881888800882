.layer-background {
	--current-layer: var(--background);
	--current-layer-hover: var(--background-hover);

	--previous-layer: var(--background);
	--previous-layer-hover: var(--background-hover);

	--next-layer: var(--layer-01);
	--next-layer-rgb: var(--layer-01-rgb);
	--next-layer-hover: var(--layer-hover-01);

	--current-layer-input-background: var(--field-02);
}
.layer-1 {
	--current-layer: var(--layer-01);
	--current-layer-rgb: var(--layer-01-rgb);
	--current-layer-hover: var(--layer-hover-01);

	--previous-layer: var(--background);
	--previous-layer-rgb: var(--background-rgb);
	--previous-layer-hover: var(--background-hover);

	--next-layer: var(--layer-02);
	--next-layer-rgb: var(--layer-02-rgb);
	--next-layer-hover: var(--layer-hover-02);

	--current-layer-input-background: var(--field-01);
}
.layer-2 {
	--current-layer: var(--layer-02);
	--current-layer-rgb: var(--layer-02-rgb);
	--current-layer-hover: var(--layer-hover-02);

	--previous-layer: var(--layer-01);
	--previous-layer-rgb: var(--layer-01-rgb);
	--previous-layer-hover: var(--layer-hover-01);

	--next-layer: var(--layer-03);
	--next-layer-rgb: var(--layer-03-rgb);
	--next-layer-hover: var(--layer-hover-03);

	--current-layer-input-background: var(--field-02);
}
.layer-3 {
	--current-layer: var(--layer-03);
	--current-layer-rgb: var(--layer-03-rgb);
	--current-layer-hover: var(--layer-hover-03);

	--previous-layer: var(--layer-02);
	--previous-layer-rgb: var(--layer-02-rgb);
	--previous-layer-hover: var(--layer-hover-02);

	--next-layer: var(--layer-03);
	--next-layer-rgb: var(--layer-03-rgb);
	--next-layer-hover: var(--layer-hover-03);

	--current-layer-input-background: var(--field-01);
}

.background {
	background-color: var(--current-layer);

	&.backgroundHover:hover {
		background-color: var(--current-layer-hover);
	}
}
