.gm-status-indicator {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
	user-select: none;

	.indicator {
		position: relative;
		height: 32px;
		width: 32px;

		.label {
			color: #b2b1b1;
			position: absolute;
			white-space: nowrap;
			bottom: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			font-size: 16px;
		}

		.number {
			border: 2px solid #b2b1b1;
			border-radius: 50%;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #b2b1b1;
		}
	}

	@keyframes animate-label {
		0% {
			color: #b2b1b1;
		}
		50% {
			color: #b2b1b1;
		}
		100% {
			color: #64c1c0;
		}
	}

	@keyframes animate-number {
		0% {
			border-color: #b2b1b1;
			color: #b2b1b1;
		}
		50% {
			border-color: #b2b1b1;
			color: #b2b1b1;
		}
		100% {
			border-color: #64c1c0;
			color: #64c1c0;
		}
	}

	@keyframes animate-line {
		0% {
			background-color: linear-gradient(
				to right,
				#b2b1b1 50%,
				#64c1c0 50%
			);
			background-size: 200% 100%;
			background-position: right bottom;
		}
		100% {
			background-color: linear-gradient(
				to right,
				#b2b1b1 50%,
				#64c1c0 50%
			);
			background-position: left bottom;
		}
	}

	@keyframes complete-number {
		0% {
			border-width: 2px;
		}
		100% {
			border-width: 8px;
			font-size: 0px;
		}
	}

	.complete {
		.label {
			color: #64c1c0;
		}

		.number {
			animation: complete-number 0.5s ease-out;
			border-color: #64c1c0;
			border-width: 8px;
			color: #64c1c0;
			font-size: 0px;
		}
	}

	.current-item {
		.label {
			color: #64c1c0;
		}

		.number {
			border-color: #64c1c0;
			color: #64c1c0;
		}
	}

	.current-item:not(.first-child) {
		.label {
			animation: animate-label 1.4s;
		}

		.number {
			animation: animate-number 1.4s;
		}
	}

	.line {
		display: flex;
		align-items: center;
		width: 100px;
		height: 4px;
		border: none;
		background-color: #b2b1b1;
		margin: 0 3px;
	}

	.line.complete {
		animation: animate-line 0.7s;
		background: linear-gradient(to right, #64c1c0 50%, #b2b1b1 50%);
		background-size: 200% 100%;
		background-position: left bottom;
	}
}
