.itemPlacementContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	overflow: hidden;
	.itemScrollableArea {
		height: 100%;
		overflow-y: scroll;
	}
}
.itemPlacement {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: calc(100% - 64px);
	padding: 32px 24px 40px 32px;
}
.header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.arrowIcon {
	transform: rotate(180deg);
	margin-right: 12px;
}

.headerName {
	max-width: 100px;

	&:not(:last-child) {
		cursor: pointer;
	}
}
.headerBlock {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}

.itemListWrapper {
	background-color: var(--background);
	height: 100%;
	width: 262px;
	min-width: 262px;
	margin-left: 4px;
	padding: 32px 16px 16px 24px;
	overflow: hidden;
	overflow-y: scroll;
	position: absolute;
	right: 0;
	border-bottom-right-radius: 8px;
}

.itemListContainer {
	display: flex;
	flex-direction: column;
}

.itemListIndex {
	margin-right: 8px;
	width: 2rem;
}

.itemContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
	overflow-x: hidden;
}

.cellTableContainer {
	margin-top: 32px;
	width: max-content;
	height: max-content;
	border-radius: 8px;
	background-color: var(--layer-02);
	border: 1px solid var(--border-subtle-v2);
	border-bottom: none;
}

.cellTable {
	display: grid;
	justify-items: center;
	align-items: center;
}

.cell {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--layer-02);
	border-left: 1px solid var(--border-subtle-v2);
	border-bottom: 1px solid var(--border-subtle-v2);
	width: 100%;
	height: 100%;
	cursor: pointer;

	&:last-child {
		border-bottom-right-radius: 6px;
	}

	&:hover {
		border: 1px solid var(--border-active);
	}

	&_viewOnly {
		cursor: default !important;
		&:hover {
			border: 1px solid var(--border-subtle-v2) !important;
		}
	}

	&_occupied {
		background-color: var(--action-disabled);
		cursor: not-allowed;
		align-items: unset;
		justify-content: flex-start;
		&:hover {
			border: 1px solid var(--border-active);
		}

		.occupiedCellName {
			font-size: 9px;
			line-height: 13px;
			display: -webkit-box !important;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			text-align: left;
			white-space: pre-wrap;
			word-break: break-word;
			margin: 4px;
		}
	}
	&_placed {
		background-color: var(--layer-03);
		&:hover {
			background-color: var(--layer-03);
			.cellPlaceText {
				display: none;
			}
			.cellUndoButton {
				display: inline;
			}
		}
	}
	&_placedAll {
		background-color: var(--layer-01);
		opacity: var(--disabled-opacity);
		pointer-events: none;
		.cellAddButton {
			opacity: var(--disabled-opacity);
		}
		&.cell_placed {
			background-color: var(--layer-01);
			opacity: 1;
			pointer-events: visible;
		}
	}
}

.cellPlaceText {
	display: inline;
}
.cellUndoButton {
	display: none;
}

.viewOnly {
	.itemPlacement {
		padding: 0px;
	}
	.cellTableContainer {
		margin-top: 0;
	}

	.itemScrollableArea {
		width: 100%;
	}
}

.topCell {
	width: 100%;
	&:not(:first-child) {
		border-left: 1px solid var(--border-subtle-v2);
	}
	border-bottom: 1px solid var(--border-subtle-v2);
	display: grid;
	place-items: center;
	height: 100%;
}

.leftCell {
	// border-left: 2px solid var(--border-subtle-v2);
	border-bottom: 1px solid var(--border-subtle-v2);
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
}
