.uploadFile {
	display: flex;
	flex-direction: column;

	.importdropzone {
		display: flex;
		align-items: center;
		height: 184px;
		border: 2px dashed var(--border-subtle);
		font: var(--font-body);
		color: var(--text-secondary);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px;

		.textLabel {
			display: flex;
			justify-content: center;
		}

		.error {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.errorFile {
				display: flex;
				align-items: center;
				color: var(--text-primary);
			}
			.errorMessage {
				color: var(--red);
				margin-top: 8px;
			}
		}

		.progressbar {
			display: flex;
			width: 272px;
			justify-content: center;
			align-items: center;
			margin-left: 80px;
			margin-right: 80px;
		}
	}
}
