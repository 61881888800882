.featureContainer {
	display: flex;
	flex-direction: row;
	padding: 8px 0;
	color: var(--text-primary);
}

.restrictionGradient {
	position: relative;
	z-index: 1;
	background: linear-gradient(
		to bottom,
		rgba(34, 43, 46, 0),
		var(--current-layer) 80%
	);
}

.upgradeButton {
	margin-top: 24px;
	margin-bottom: 48px;
	width: 180px;
}
