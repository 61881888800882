.unarchiveContainer {
	position: absolute;
	width: inherit;
	z-index: 100;
	background: var(--layer-01-v2);
	height: 100%;
	overflow: scroll;
}

.unarchiveStepContainer {
	background: var(--layer-02-v2);
	height: calc(100% - 80px);
	padding: 16px 32px;
}

:global {
	.ant-table .ant-table-tbody > tr.ant-table-row > td:first-child {
		border-right: none;
		background-color: transparent;
	}
}

.unarchiveTable {
	max-height: 75vh;
	overflow: scroll;
}
