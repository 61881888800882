.boximport {
	height: 100%;
	width: 100%;
	flex-direction: column;
	background-color: var(--background);
	grid-column: 1 /3;
	grid-row: 1 / 3;

	.boximportheader {
		position: relative;
		width: 100%;
		justify-content: space-between;
		display: flex;
		align-items: center;
		padding: 32px;
		background-color: var(--layer-02);
		border-bottom: 1px solid var(--border-subtle);

		.headerTitle {
			position: absolute;
			left: 40.6%;
		}
	}

	.boximportexit {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.boximportcontent {
		flex: 1;
		padding: 47px 32px;
		width: 100%;
		overflow-y: auto;
		overflow-x: auto;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		background-color: var(--background);

		&.organize {
			padding: 0px;
		}

		&.matchidentifiers {
			margin-bottom: 47px;
		}
	}
}

.importstep {
	&1 {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&2 {
		width: 70%;
		height: 0;

		.identifiers {
			width: 100%;
			height: 100%;
		}
	}

	&3 {
		width: 100%;
		height: 100%;
		padding: 0px;
	}
}

.importdropzone {
	height: 184px;
	width: 469px;
	background-color: var(--field-02);
	margin-top: 8px;
	border: 2px dashed var(--border-subtle);
	color: var(--text-secondary);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	border-radius: 4px;
}

.flex {
	display: flex;
	align-items: center;
}

.flexcol {
	flex-direction: column;
}

.statusText {
	margin-bottom: 16px;
	margin-top: 0px;
	width: 100%;
	justify-content: space-between;
}

.importfilename {
	width: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;

	> div {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

.columnidentifier {
	flex: 0 0 80px;
	width: 100%;
	background-color: var(--layer-01);
	margin-bottom: 16px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	color: var(--text-primary);
	border-radius: 4px;

	&:before {
		content: "";
		height: 100%;
		width: 8px;
		position: absolute;
		left: 0;
		background-color: var(--layer-03);
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
		background-color: var(--green);
	}

	&__noUpload:before {
		background-color: var(--button-disabled);
	}

	&__error {
		border: 1px solid var(--red);
	}

	&__error:before {
		background-color: var(--red);
	}

	&:last-child {
		margin-bottom: 0px;
	}

	.selectIdentifier {
		border-radius: 4px;
	}
}

.rearrangeContent {
	width: 100%;
	flex: 1;
	gap: 40px;
	align-items: start;

	.fullWidth {
		width: 100%;
	}
}

.table {
	height: 100%;
	flex-wrap: wrap;
	position: relative;
	color: var(--text-primary);

	.colHeader {
		height: 24px;
		flex: 1 1 auto;
		justify-content: center;
		border: 1px solid var(--border-subtle-v2);
	}

	.rowHeader {
		width: 24px;
		flex: 0 0 24px;
		justify-content: center;
		border: 1px solid var(--border-subtle-v2);
	}

	.cell {
		flex: 1 1 auto;
		user-select: none;
		padding: 4px;
		background-color: var(--layer-02-v2);
		border: 1px solid var(--border-subtle-v2);

		&Body {
			width: 100%;
			height: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
		}
	}

	.cell__disabled {
		background-color: var(--layer-03);
	}

	.cell__placeholder {
		background-color: var(--layer-02);
	}

	.cell__disabled .cellBody {
		color: var(--text-tertiary);
	}

	.cell__placeholder .cellBody {
		color: var(--text-primary);
		cursor: pointer;
	}

	.cell__placeholder:hover:not(.cell__placeholder__left_adjacent):not(
			.cell__placeholder__right_adjacent
		):not(.cell__placeholder__top_adjacent):not(
			.cell__placeholder__bottom_adjacent
		) {
		border: 2px solid var(--button-primary);
		padding: 3px;
	}

	.cell__placeholder__selected {
		background-color: var(--layer-02);
		border: 2px solid var(--button-primary);
		padding: 3px;
	}

	// cell_placeholder__left_adjacent, right_adjacent, top_adjacent, bottom_adjacent - for when the placeholder is adjacent to a selected cell remove t
	.cell__placeholder__left_adjacent {
		border-left: 1px solid var(--border-subtle);
	}

	.cell__placeholder__right_adjacent {
		border-right: 1px solid var(--border-subtle);
	}

	.cell__placeholder__top_adjacent {
		border-top: 1px solid var(--border-subtle);
	}

	.cell__placeholder__bottom_adjacent {
		border-bottom: 1px solid var(--border-subtle);
	}

	.cell__droppable {
		background-color: var(--layer-hover-02) !important;
	}

	.cell__created {
		background-color: var(--layer-02);
	}

	.cell__failed {
		background-color: var(--red);
	}

	.corner {
		height: 24px;
		width: 24px;
		border: 1px solid var(--border-subtle-v2);
	}
}

.legends {
	width: 100%;
	display: flex;
	gap: 16px;
}

.colorBox {
	height: 68px;
	width: 64px;
	padding: 4px;
	border: 1px solid var(--border-subtle);
}

.uploadScreen {
	position: absolute;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: var(--layer-03);
}

.fullWidthChildren {
	> * {
		width: 100%;
	}
}

.failedItem {
	width: 70%;
	background-color: var(--layer-01);
	margin-bottom: 8px;
	margin-right: auto;
	border-radius: 4px;
	padding: 0 8px;
	border: var(--border-subtle-solid);
	box-shadow: var(--shadow-container);

	&Name {
		height: 40px;
		padding: 0 8px;
		border-bottom: var(--border-subtle-solid);
	}

	&Info {
		width: 100%;
		flex-wrap: wrap;
		padding: 0 8px;
		margin-bottom: 8px;

		> div {
			height: 32px;
			width: 50%;
			display: flex;
			align-items: center;

			> div {
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
