.genemod-sidebar {
	width: var(--sidebar-width);
	min-height: fit-content;
	transition: 0.2s;
	position: relative;
	background-color: var(--platform-menu);
	box-shadow: var(--platform-menu-inner-shadow);
	display: flex;
	flex-direction: column;
	padding-bottom: 32px;

	min-height: 635px;
	height: 100vh;
	position: sticky;
	top: 0px;

	.team-avatar-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.sidebar-btn {
		width: 100%;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px;
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		opacity: 0.5;
		margin-top: 8px;
		.logo {
			height: 42px;
			width: 42px;
		}
		.btn-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 32px;

			.icon {
				pointer-events: none;
			}
		}

		.icon-container {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 32px;
		}

		.label {
			width: calc(100% - 70px);
			white-space: nowrap;
			opacity: 1;
		}

		&:hover,
		&__selected {
			opacity: 1;
		}
	}

	// rearrange effect during rearrange mode
	.sidebar-btn.rearrangeable {
		@keyframes shake {
			0% {
				transform: rotate(0deg);
			}
			25% {
				transform: rotate(3deg);
			}
			50% {
				transform: rotate(0deg);
			}
			75% {
				transform: rotate(-3deg);
			}
			100% {
				transform: rotate(0deg);
			}
		}

		animation: shake 0.2s infinite both;
		* {
			cursor: move;
		}
	}

	.end-btns {
		margin-top: auto;
		width: 100%;
		.sidebar-btn {
			opacity: 1;
			margin-top: 12px;
		}
	}
}

.sidebar__popover {
	.ant-popover-content > .ant-popover-arrow {
		border-bottom-color: var(--layer-03);
		border-left-color: var(--layer-03);
	}
	.sidebar__useravatar-menu,
	.sidebar__customer-support-menu {
		display: flex;
		flex-direction: column;

		> a {
			border: none;
			height: 36px;
			padding-right: 4px;
			outline: none;
			width: 100%;
			color: inherit;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 8px;

			&:hover {
				background-color: var(--layer-hover-03);
			}
		}
	}
}

.genemod-sidebar-glowing-btn-message {
	left: 254px !important;
	transition: left 0.2s;

	.genemod-menu__contact-us-msg {
		width: 250px;
		font-size: 16px;
	}

	&__collapsed-position {
		left: 74px !important;
	}
}
