.aiButton {
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 16px;
	height: 26px;
	width: 43px;

	// Text
	text-align: center;
	font-family: Lato;
	font-size: 9px;
	font-style: normal;
	font-weight: 800;
	line-height: 14px;
}

.isVertical {
	flex-direction: column;
	width: 26px;
	height: 43px;
}

.active {
	background-color: var(--background-hover);
}
