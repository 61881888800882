// Modal styling
div.ant-modal-wrap.ant-modal-centered > .item-group-modal {
	.ant-modal-body {
		.containerType {
			display: flex;
			flex-direction: column;
			margin-right: 16px;
			width: 62%;
			margin-bottom: 32px;
		}
	}
}

.inputName {
	margin-bottom: 24px;
}
