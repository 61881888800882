.button {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: var(--font-family-lato);
}

.icon {
	justify-content: center;
}

.default {
	background-color: var(--layer-02-v2);
	border: 1px solid var(--border-invisible-v2);
	color: var(--text-secondary-v2);

	&:hover {
		background-color: var(--layer-02-v2) !important;
		border: 1px solid var(--action-hover) !important;
		color: var(--action-hover) !important;
	}

	&:focus {
		border: 1px solid var(--action-click) !important;
		color: var(--action-click) !important;
		background-color: var(--layer-02-v2) !important;
	}
}

.primary {
	background-color: var(--action-default);
	color: var(--text-on-color);
	border: none;

	&:hover {
		background-color: var(--action-hover) !important;
		color: var(--text-on-color) !important;
		border: none;
	}

	&:focus {
		background-color: var(--action-click);
		color: var(--text-on-color) !important;
		border: none !important;
	}

	&:active {
		background: var(--action-click);
	}
}

.dashed {
	background-color: var(--layer-01-v2);
	border: 1px dashed var(--border-subtle);
	color: var(--text-secondary);

	&:hover {
		background-color: var(--layer-01-v2);
		border: 1px dashed var(--action-hover) !important;
		color: var(--action-hover) !important;
	}

	&:focus {
		border: 1px dashed var(--action-click) !important;
		color: var(--action-click) !important;
	}
}

.link {
	color: var(--action-link);
	padding: 0 !important;

	&:hover {
		color: var(--link-hover);
	}

	&:focus {
		color: var(--link-click);
	}
}

.text {
	color: var(--color-secondary);
	border: none;

	&:hover {
		background-color: var(--layer-hover-01);
	}

	&:focus {
		background-color: var(--layer-focus-01);
	}
}

.middle {
	padding: 5px 16px;
}

.large {
	padding: 8px 16px;
}

.small {
	padding: 0 8px;
	height: 24px;
	font-size: 12px;
	font-weight: 500;

	:global(.ant-btn-icon) {
		margin-inline-end: 6px !important;

		& div {
			height: 14px;
			width: 14px;
		}
	}
}

.middle {
	font-size: 14px;
	font-weight: 500;
}

.large {
	font-size: 16px;
	font-weight: 500;
}

.disabled {
	background-color: var(--action-disabled) !important;
	color: var(--text-disabled) !important;
	border: 1px solid var(--border-subtle) !important;

	&:hover {
		background-color: var(--action-disabled) !important;
		color: var(--text-disabled) !important;
		border-color: var(--border-subtle) !important;
	}

	&:focus {
		background-color: var(--action-disabled) !important;
		color: var(--text-disabled);
	}
}

.icon {
	margin-inline-end: 8px;
}

:global(.ant-btn-primary.ant-btn-dangerous) {
	background-color: var(--dust-red);

	&:hover {
		background-color: var(--dust-red-05) !important;
	}
}

:global(
		:where(
				.css-dev-only-do-not-override-b8ec2j
			).ant-btn-link.ant-btn-dangerous
	) {
	color: var(--dust-red);
}

.layer-01 {
	background: var(--layer-01-v2);
}

.layer-02 {
	background: var(--layer-02-v2);
}

.layer-03 {
	background: var(--layer-03-v2);
}

.link.disabled {
	background-color: transparent !important;
	border: none !important;
}
