.shareButton {
	width: 72px;
	height: 24px;
	border-radius: 2px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 4px 8px 4px 4px;
	border: var(--border-subtle-solid);
	gap: 8px;
	&:hover {
		transition: 0.2s;
	}
}
