.sidebarBtnLabel {
	color: var(--text-on-color);
	text-align: center;
	font-size: 9px;
	font-family: var(--font-family-lato);
	letter-spacing: 0.02px;
	font-weight: var(--font-weight-medium);
}

.sidebarBtnLink {
	width: 100%;
	height: 100%;
}

.divider {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&:before {
		content: "";
		height: 1px;
		width: 32px;
		background-color: var(--text-on-color);
		opacity: 0.5;
	}
}

.addToolRow {
	min-height: 128px;
	width: 100%;
	padding: 16px;
	padding-left: 0px;
	display: flex;
	color: var(--text-primary);
	position: relative;

	> div {
		height: 100%;
		width: 100%;

		&:first-child {
			flex: 0 0 40px;
			margin-right: 16px;
		}

		&:nth-child(2) {
			flex: 1 0 calc(100% - 40px);
			word-wrap: break-word;
		}
	}
}

.addToolIcon {
	height: 40px;
	width: 40px;
	background-color: var(--layer-03);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg path {
		fill: var(--text-secondary);
	}
}

.pin {
	position: absolute;
	height: 32px;
	width: 32px;
	top: 8;
	right: 0;
	cursor: pointer;
	border-radius: 50%;
	transition: 0.2s;

	&:hover {
		background-color: var(--layer-hover-01);
	}
}

.rearrangeDone {
	background-color: var(--button-primary);
}

.themeTooltipOverlay {
	max-width: unset;
	.themeTooltip {
		display: flex;
		flex-direction: row;
	}
}

.logoNotificationIcon {
	position: absolute;
	right: 5px;
	top: 5px;
	border: 1px solid var(--text-on-color);
	border-radius: 50%;
}

.genemodIconMenu {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 10px;

	.menu-item {
		height: 42px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
}
