.upgradeTag {
	width: 57px;
	height: 22px;
	border: solid 1px var(--button-primary);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	.tagLabel {
		margin-left: 2px;
		color: var(--system_aqua02);
	}
}

.upgradeButtonDemo {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
