.radioButton {
	width: 270px !important;
}

.boxEditGrid {
	width: min-content;
	// box-shadow: var(--shadow-container);
	border-radius: 8px;
	overflow: hidden;
	border: var(--border-subtle-solid-v2);

	.tableContent {
		position: relative;
		display: flex;
		flex-direction: column;

		.colTableRowHeaderAndGrid {
			display: flex;
		}

		.tableColHeader {
			display: flex;
			padding-left: 38px;
			height: 24px;

			.colHeader {
				display: flex;
				align-items: center;
				justify-content: center;
				font: var(--font-body);
				font-size: 12px;
				font-weight: var(--font-weight-medium);
				color: var(--text-secondary-v2);
				border: var(--border-subtle-solid-v2);
				background-color: var(--layer-01-v2);
				border-top: 0;
				border-bottom: 0;
			}
		}

		.tableRowHeader {
			width: 38px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.rowHeader {
				flex: 1;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font: var(--font-body);
				font-size: 12px;
				font-weight: var(--font-weight-medium);
				color: var(--text-secondary-v2);
				border-top: var(--border-subtle-solid-v2);
				background-color: var(--layer-01-v2);
			}
		}

		.middle {
			.cell {
				background-color: var(--action-disabled);
			}
			.cellEmpty {
				background-color: var(--layer-02-v2);
			}

			.cell,
			.cellEmpty {
				border: var(--border-subtle-solid);
			}
		}
	}
}

.tableLoading {
	background-color: rgba(0, 0, 0, 0.1);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	display: grid;
	place-items: center;
}
