.rackView {
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--border-subtle);
	border-radius: 16px;
	max-height: 100%;
	max-width: 1035px;
}

.boxAndColumns {
	max-height: 100%;
	overflow-y: scroll;
	display: flex;

	&::-webkit-scrollbar {
		display: none;
	}
}

.contents {
	display: grid;
	gap: 16px;
	place-items: center;
	background-color: var(--layer-02);
	flex: 1;
	padding: 24px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.info {
	display: flex;
	gap: 15px;
	align-items: center;
	padding: 0 16px;
	min-height: 56px;
	border-bottom: 1px solid var(--border-subtle);
	background-color: var(--layer-03);
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.rackMenu {
	border-radius: 4px;
	transition: background-color 0.2s ease;
	&:hover {
		background-color: var(--background-hover);
	}
}

.wrapper {
	width: 100%;
	display: grid;
	place-items: center;
}

.right {
	grid-column: span 7;
	overflow: hidden;

	@media (min-width: 1920px) {
		grid-column: 6 / span 7;
	}
}

.clickToEdit {
	min-width: 120px;
}
