.uploadFile {
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: var(--layer-03-v2);

	.buttonContainer {
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
		:global(.genemod-icon) {
			height: 48px;
			width: 48px;
			min-width: 48px;
			svg {
				height: 48px;
				width: 48px;
				min-width: 48px;
				path {
					fill: var(--informative);
				}
			}
			margin-bottom: 16px;
		}
		.uploadAction {
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 4px;
		}
	}

	.importdropzone {
		cursor: pointer;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		height: 184px;
		border: 2px solid var(--border-subtle);
		border-radius: 4px;
		font: var(--font-body);
		color: var(--text-secondary);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px;

		.contentContainer {
			display: flex;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			gap: 16px;
			border-radius: 8px;
		}

		.error {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.errorFile {
				display: flex;
				align-items: center;
				color: var(--text-primary);
			}
			.errorMessage {
				color: var(--red);
				margin-top: 8px;
			}
		}

		.progressbar {
			display: flex;
			width: 272px;
			justify-content: center;
			align-items: center;
			margin-left: 80px;
			margin-right: 80px;
		}
	}
}

.divider {
	width: 100%;
	// margin: 16px 0 16px 0;
	height: 0;
	border-top: 1px solid var(--border-invisible-v2);
}

.googleDriveButton {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	> svg {
		width: 14px;
		height: 14px;
	}
}
