.shelf-rack-info-container {
	border-bottom: var(--border-subtle-solid);
	margin-bottom: 32px;
}

.ant-divider-horizontal {
	margin-bottom: 32px;
	margin-top: 32px;
	background: var(--border-subtle);
	height: 1px;
}

.edit-form {
	position: relative;
}
