.rowName {
	max-width: 100%;
}

.row {
	td {
		padding: 12px 8px !important;
	}
}

.linkButton {
	span {
		color: var(--action-link) !important;
	}
}
