@use "../../Table.module.scss" as table;

// left side of box page
.headerAndBoxGrid {
	height: 100%;
	aspect-ratio: 1/1;
	max-width: calc(63% - 48px);
	display: flex;
	flex-direction: column;

	.sectionHeader {
		width: 100%;
		flex: 0 0 18px;
		height: 18px;
		display: flex;
		align-items: center;
		margin: 24px 0;

		.backToResults {
			display: flex;
			gap: 4px;
			align-items: center;
			cursor: pointer;
			margin-right: 24px;
		}

		.headerCellActions {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 16px;
		}
	}
}

.boxGrid {
	display: grid;
	background-color: var(--border-subtle);
	overflow: hidden;
	aspect-ratio: 1/1;

	.tableContent {
		display: contents;
		background-color: var(--ui_level02);
	}
}

// BoxCellMenuItem component
.menuItemContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.shortcut {
		color: var(--text-ghost);
	}
}

.dummy {
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 1;
	grid-column-end: 2;
	border: var(--border-subtle-solid-v2);
	display: flex;
	background-color: var(--layer-01-v2);
}

.colHeader,
.rowHeader {
	user-select: none;
	position: relative;
	font: var(--font-body);
	font-size: 12px;
	font-weight: var(--font-weight-medium);
	color: var(--text-primary-v2);
	border: var(--border-subtle-solid-v2);
	background-color: var(--layer-01-v2);
}
.colHeader {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	border-left: 0px;
}

.rowHeader {
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 0px;
}

.button {
	color: var(--text-secondary-v2) !important;
}
