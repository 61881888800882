.gridViewToggleContainer {
	width: 64px;
	height: 32px;
	border-radius: 4px;
	background-color: var(--layer-03);
	border: 1px solid var(--border-invisible-v2);
	display: flex;
	align-items: center;

	.gridView,
	.listView {
		display: grid;
		place-items: center;
		width: 32px;
		height: 32px;
		border-radius: 2px;
		&__selected {
			background-color: var(--gray-01);
			transition: all 0.25s ease-in-out;
			path,
			rect {
				fill: var(--informative);
			}
			border: 1px solid var(--informative);
		}
		&__selected_end {
			border-radius: 0px 4px 4px 0px;
		}
		&__selected_start {
			border-radius: 4px 0px 0px 4px;
		}
	}
}
