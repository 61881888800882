.freezerTab {
	flex-direction: column;
	height: 100%;
	padding-bottom: 64px;
	position: relative;
	@media (min-width: 1920px) {
		width: calc(100vw - 514px);
	}
	@media (min-width: 1281px) {
		width: calc(81vw - 152px);
	}
	:global {
		.ant-table .ant-table-placeholder {
			opacity: 1;
			background-color: transparent;
			padding: 32px;
			border: none !important;
		}
	}
	:global {
		.ant-table-content {
			.ant-table-scroll,
			.ant-table-fixed-left {
				div {
					table {
						background-color: var(--current-layer);
					}
				}
			}
		}
	}
}

.freezerTabTools {
	top: 0px;
	background: var(--current-layer);

	:global(.ant-select-single) {
		width: 304px;
	}

	:global(.ant-input-affix-wrapper) {
		background-color: var(--layer-03) !important;
		border-radius: 4px;
	}
}

.pagination {
	margin: 24px 0;
	padding-bottom: 32px;
}

.searchBarContainer {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.searchInputContainer {
	display: flex;
	margin-left: auto;
	margin-right: 8px;
	width: 32px;
	height: 32px;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--border-invisible-v2);
	background-color: var(--layer-02-v2) !important;
}

.wrapperEnd {
	display: flex;
	gap: 8px;
	align-items: center;
}

.tableContainer {
	flex: 1;
	position: relative;
}

.noScrollTableContainer {
	overflow: hidden;
}

.noLocation {
	display: block;
}

.assignLocation {
	display: none;
	cursor: pointer;
}
.locationName {
	display: flex;
	align-items: center;
	gap: 4px;
}

.locationContainer:hover > .noLocation {
	display: none;
}

.locationContainer:hover > .assignLocation {
	display: block;
}

.toobarContainer {
	padding: 12px 0;
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: space-between;
	height: 48px;
}

.toolbarContent {
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
}

.items {
	padding: 6px 8px 0 8px;
}

.searchBar {
	height: 36px !important;

	&:global(.ant-select) {
		width: 100%;
	}
	:global(.genemod-search-input) {
		padding-left: 0 !important;
	}

	:global(.ant-select-selection__placeholder) {
		margin-left: 0 !important;
	}
}

.searchIcon {
	margin-right: 4px !important;
	margin-left: 4px !important;
}

.reset {
	border-top: 1px solid var(--border-subtle);
	padding: 12px 8px;
	display: grid;
	place-items: center;
}

.menu {
	padding: 0 !important;
	max-height: 400px;
	overflow-x: scroll;
	min-width: 200px !important;
}

.timestamp {
	min-width: 280px !important;
}

.dropdown {
	:global(.ant-menu) {
		background-color: var(--layer-02);
	}
}

.locationName {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	path {
		fill: var(--text-tertiary);
	}
}

.item {
	height: 42px;
}

.datepicker {
	background-color: var(--layer-02) !important;
}

.createdOn {
	width: 280px;
	max-width: 280px !important;
}

.dateRange {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 14px;
}

.inputRange {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 10px;
}

.inputFilter {
	margin-bottom: 0;
}

.line {
	background-color: var(--border-subtle);
	height: 1px;
	width: 8px;
	margin-top: 26px;
}

.selectedRow {
	background: var(--layer-hover-01-v2) !important;
}

.reactResizableHandle {
	position: absolute;
	right: -3px;
	bottom: 0;
	z-index: 1;
	width: 6px;
	height: 100%;
	cursor: col-resize;
	&:hover {
		background: var(--button-text);
	}
}

.filterCheckbox {
	margin-left: 5px;
}

.layers {
	display: flex;
	gap: 8px;
	align-items: center;

	:global(.ant-select-dropdown) {
		:global(.rc-virtual-list-holder) {
			max-height: 400px !important;
		}
	}
}

.layerLabel {
	cursor: pointer;
}

.columnHeader {
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.archiveBtn {
	div[class*="imageFile"] {
		width: 14px !important;
		height: 14px !important;
		margin-right: 8px !important;
		div[name="archive"] {
			width: 14px !important;
			height: 14px !important;
		}
	}
	path {
		fill: var(--text-secondary-v2);
		stroke: none;
	}
}

.layerSystemContainer {
	border-radius: 8px;
}

.table {
	:global(.ant-table-fixed) {
		border: 1px solid var(--border-subtle-v2) !important;
	}
	:global(.ant-table-row-cell-break-word) {
		padding: 0px 16px !important;
	}
}

.spacing {
	display: flex;
	align-items: center;
	gap: 8px;
}
