.plusIcon {
	height: 18px;
	width: 18px;
	display: grid;
	place-items: center;
}

.sharedDisplay {
	> div {
		> span {
			font-weight: bold;
		}
	}
}
