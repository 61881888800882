.upload {
	height: 200px;
	width: 100%;
	border: 1px dashed var(--border-subtle);
	display: grid;
	place-items: center;
}

.uploadContent {
	display: flex;
	gap: 8px;
}
