.itemPill {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 184px;
	height: 32px;
	padding: 7px 16px;
	border-radius: 16px;
	background-color: var(--layer-03);
	border: var(--border-subtle-solid);
	cursor: pointer;
	&:not(.itemPill_placed):hover {
		background-color: var(--layer-hover-03);
	}
	&_placed {
		&:hover {
			.itemPlaceText {
				display: none;
			}
			.itemUndoButton {
				display: inline;
			}
		}
	}
	&_focused {
		border: solid 2px var(--button-primary);
		padding: 5px 14px;
		background-color: var(--layer-focus-01);
	}
}

.itemName {
	width: 118px;
}

.itemNormalStatus {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--button-primary);
}

.itemPlaceText {
	display: inline;
}

.itemUndoButton {
	display: none;
}
