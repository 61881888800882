.parentDiv {
	width: 100% !important;
	height: 100% !important;
	overflow: scroll;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-primary);
	background-color: var(--layer-01);

	.narrowWrapper {
		width: fit-content;
		padding: 32px 48px 48px;
		background-color: var(--layer-02);
		border-radius: 8px;
		box-shadow: var(--shadow-container);

		@media (min-width: 1920px) {
			padding: 32px 56px 56px;
		}

		.imgWrapper {
			display: flex;
			justify-content: center;
			width: 100%;

			img {
				width: 210px;
				height: 44px;
			}
		}

		.childrenWrapper {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}
}
