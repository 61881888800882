.container {
	background-color: var(--layer-02) !important;
	padding: 16px;
	min-height: 364px;
	max-height: 364px;
	width: 490px;
	border: 1px solid var(--border-subtle-solid);
	border-radius: 4px;
	overflow: auto;
}

.searchBarContainer {
	:global {
		.genemod-search-container {
			height: 36px;
			.ant-select-auto-complete .ant-input {
				padding: 0;
			}
			.ant-select-auto-complete .ant-select-selection__placeholder {
				margin-left: 0;
			}
		}
	}
}

.resultsLabel {
	padding: 16px 8px;
}

.breadcrumbContainer {
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 8px 2px 4px 2px;
	border-bottom: 1px solid var(--border-subtle-v2);
	flex-wrap: wrap;
}

.breadcrumbItemContainer {
	display: flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;
	z-index: 10;
	max-width: 127px;
}
