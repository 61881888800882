.messageContainer {
	display: flex;
	flex-direction: row;
	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	width: 100%;
	padding: 16px 16px 16px 12px;
	margin-bottom: 12px;
	.messageIcon {
		margin-top: 3px;
		width: 24px;
		height: 24px;
	}
	.contents {
		display: flex;
		flex-direction: column;
		margin-left: 12px;
		.buttons {
			display: flex;
			margin-top: 12px;
			flex-direction: row;
			align-items: center;
			gap: 12px;
		}
	}
}
