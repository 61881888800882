.shelfSkeleton {
	height: 655px;
	width: 472px;
	background-color: var(--layer-02);
	border-radius: 16px;
	border: 1px solid var(--border-subtle);
	box-shadow: var(--shadow-container);
	padding: 24px 8px;
}

.shelfCardSkeleton {
	width: 84px;
	height: 84px;
	background-color: var(--layer-03);
	border: 1px solid var(--boder-subtle);
	border-radius: 4px;
	padding: 12px 4px 8px 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.rackSkeleton {
	height: 312px;
	width: 520px;
	border-radius: 16px;
	padding: 12px;
	background-color: var(--layer-rack);
	box-shadow: var(--shadow-container);
}

.freezerContent {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 16px;
	padding: 32px;
	width: 100%;
}
