.autocomplete {
	input {
		font-family: "lato";
	}
}

.popup {
	:global(.rc-virtual-list) {
		background-color: var(--layer-popover);
		padding: 2px;
	}

	:global(.ant-select-item-option-active) {
		background-color: var(--layer-hover-popover) !important;
	}
}

.withSearchButton {
	:global(.ant-select-selection-search-input) {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.searchButtonIconContainer {
	height: 42px;
	background-color: var(--layer-02-v2);
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	display: grid;
	place-items: center;
	margin-top: 2px;
	padding-right: 10px;
	transition: border-color 0.2s ease;
	padding-left: 10px;
	background-color: var(--border-hover);
	cursor: pointer;
}

.wrapper {
	display: flex;
	align-items: center;

	&:hover {
		& .searchButtonIconContainer {
			border-color: var(--border-hover);
		}

		& :global(.ant-select-selection-search-input) {
			border-color: var(--border-hover);
		}
	}
	:global {
		.ant-select {
			.ant-select-selector {
				background: var(--layer-02-v2);
				border: 1px solid var(--border-subtle-v2);
				color: var(--text-secondary-v2);
				.ant-select-selection-search-input {
					height: 100% !important;
				}
			}
			.ant-select-selection-placeholder {
				color: var(--text-placeholder);
			}
			.ant-select-clear {
				line-height: unset;
				opacity: 100%;
				top: 50% !important;
			}
			&:hover {
				.ant-select-selector {
					border-color: var(--border-hover);
				}
				.ant-select-clear {
					background-color: transparent;
				}
			}
			&.ant-select-focused .ant-select-selector {
				border-color: var(--border-subtle-v2) !important;
			}
		}
	}
}
