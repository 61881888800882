.verifiedEmailModal {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;

	.header {
		text-align: center;
		margin-bottom: 48px;
	}
}
