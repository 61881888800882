@use "../Input/Input.module.scss" as GenemodInputStyle;

.datePickerContainer {
	width: 100%;
	:global {
		//for applying styles of date-picker Select
		@import "~antdv4/lib/date-picker/style/index";

		.ant-picker {
			width: 100%;
			height: GenemodInputStyle.$height;
			border-radius: 4px;
			padding: 0;
			border: var(--border-subtle-solid);
			background-color: var(--layer-03);

			/** Defines the stylings and the position of the bottom line */
			&::before {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 2px;
				bottom: 0;
				content: "";
				opacity: 0;
				transition: GenemodInputStyle.$transition;
			}

			&.ant-picker-focused {
				border: var(--border-subtle-solid);
				box-shadow: none;
			}

			&:focus-within {
				border: var(--border-subtle-solid);
				border-bottom: none;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				outline: none;

				> input {
					outline: none;
				}

				// Display the bottom line when the input is in focus
				&::before {
					opacity: 1;
					background-color: var(--border-active);
				}
			}

			.ant-picker-input {
				//style of input field
				> input {
					height: 100%;
					transition: GenemodInputStyle.$transition;
					border: none;
					padding: GenemodInputStyle.$padding;
					outline: none;
					position: relative;
					color: var(--text-primary);
				}
				> input::placeholder {
					color: var(--text-ghost);
				}
				//style of clear icon
				.ant-picker-clear {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
					width: 18px;
					height: 18px;
					margin-right: 8px;
					color: var(--text-secondary);
				}
			}
		}
	}

	:global {
		.ant-picker {
			background-color: var(--current-layer-input-background);
		}
	}

	/** Hide the border when the input field is filled with values */
	&__filled {
		.ant-picker {
			border: 1px solid transparent !important;
		}
	}
	&__error {
		.ant-picker {
			border: 1px solid var(--red) !important;
			border-radius: 4px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;

			&:before {
				opacity: 1;
				height: 2px;
				background-color: var(--red);
			}

			&:focus-within {
				&::before {
					background-color: var(--red) !important;
				}
			}
		}
	}
}

// style of datePicker panel
.dropdownClass {
	background-color: var(--layer-03);
	box-shadow: var(--shadow-container);
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;

	&__exceptDatePicker {
		:global {
			.ant-picker-header {
				.ant-picker-header-super-prev-btn,
				.ant-picker-header-super-next-btn {
					visibility: visible !important;
					margin-left: 18px;
					margin-right: 18px;
				}
			}
			.ant-picker-cell-inner {
				border-radius: 0 !important;
			}
		}
	}

	:global {
		.ant-picker-panel {
			border-bottom: none;
		}
		.ant-picker-header {
			margin-top: 8px;
			height: 32px;
			border-bottom: none;
			button {
				line-height: 32px;
			}

			.ant-picker-header-super-prev-btn,
			.ant-picker-header-super-next-btn {
				display: none;
			}

			.ant-picker-header-prev-btn {
				display: flex;
				align-items: center;
				padding-left: 10px;
			}
			.ant-picker-header-next-btn {
				display: flex;
				align-items: center;
				padding-right: 14px;
			}
			button,
			button:hover {
				color: var(--text-primary);
			}
			.ant-picker-header-view {
				line-height: 32px;
				color: var(--text-primary);
				font: var(--font-subheadline);
			}
		}

		.ant-picker-body {
			padding: 0px 12px !important;
		}

		.ant-picker-footer {
			border-top: none !important;
			padding: 0px 16px 16px;
			.ant-picker-today-btn {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 18px;
				color: var(--link-primary);
				font: var(--font-label);
				font-weight: var(--font-weight-semibold);
			}
		}

		.ant-picker-panel-container {
			background-color: transparent;
			box-shadow: none;
			th {
				color: var(--text-secondary);
				font: var(--font-caption);
			}

			.ant-picker-content {
				.ant-picker-cell {
					color: var(--text-primary);
					font: var(--font-label);
					background-color: transparent;
					padding: 3.5px 0px;

					&::before {
						background-color: transparent;
					}

					// hide if dates are out of current view month
					&:not(.ant-picker-cell-in-view) {
						visibility: hidden;
					}

					// make circle for border-radius if date is selected or "today"
					&.ant-picker-cell-selected,
					&.ant-picker-cell-in-view.ant-picker-cell-today {
						.ant-picker-cell-inner {
							border-radius: 50%;
							border: 1px solid var(--link-primary);
						}
					}
					.ant-picker-cell-inner {
						transition: none;
						line-height: 21px;

						&::before {
							border: none;
							border-radius: 50%;
						}
					}

					&.ant-picker-cell-disabled .ant-picker-cell-inner {
						color: var(--text-tertiary);
					}
				}
				// hover effect on dates
				.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
					.ant-picker-cell-inner {
					background-color: var(--link-primary);
					border-radius: 50%;
					border: 1px solid var(--link-primary);
					color: var(--background);
				}
				// background color on selected date
				.ant-picker-cell-in-view.ant-picker-cell-selected
					.ant-picker-cell-inner {
					background-color: var(--link-primary);
					color: var(--background);
				}
			}
		}
	}
}
