.loginModal {
	.smallHeader {
		margin-top: 24px;
		margin-bottom: 32px;
	}

	.loginButton {
		margin-top: 36px;
	}
}

@media (min-width: 1920px) {
	.loginModal {
		.smallHeader {
			margin-top: 32px;
			margin-bottom: 48px;
		}

		.loginButton {
			margin-top: 48px;
		}
	}
}

.enterpriseAdminLogin {
}

.enterpriseAdminLoginPageWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.enterpriseAdminLoginModal {
		border-radius: 8px;
		width: 766px;
		display: flex;

		box-shadow: 0 4px 15px 0 rgba(43, 48, 69, 0.2);
		border: solid 1px var(--border-subtle);

		.leftSection {
			width: 456px;
			padding: 48px;
			border-right: solid 1px var(--border-subtle);

			.header {
				width: 100%;
				margin-bottom: 32px;
			}

			.loginButton {
				margin-top: 36px;
			}
		}
		.rightSection {
			padding: 32px 24px 48px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			.loginImg {
				width: 262px;
				height: 260px;
				margin-top: 24px;
			}
		}
	}
}
