#freezer .freezer-contents .category-right {
	padding-bottom: 32px;
	margin-right: 8px;
	background-color: var(--layer-01);

	.genemod-category-view {
		padding-top: 72px;
		padding-left: 12px;
		padding-right: 12px;
		width: 100%;
		color: var(--text-primary);

		@media (min-width: 1920px) {
			padding-top: 76px;
		}

		.category-layout {
			width: 100%;
			height: 100%;
			box-shadow: var(--shadow-container);
			border-radius: 16px;
			padding: 0;

			::-webkit-scrollbar-thumb {
				background-color: var(--freezer_scrollbar_bg);
				border: solid 1px var(--freezer_scrollbar_border);
			}

			.boxes,
			.item-groups {
				width: 100%;
				padding-left: 16px;
				padding-right: 16px;
			}

			.boxes {
				background-color: var(--layer-rack);
			}

			.item-groups {
				background-color: var(--layer-02);
				height: 100%;
			}

			.item-groups .category-contents .table {
				background-color: var(--layer-02);
			}

			.parent-container {
				width: 100%;
				height: 100%;
				margin: 0px;
				padding: 0px;

				min-height: 140px;
				overflow: hidden;
				overflow-y: scroll;
				display: flex;
				flex-direction: column;
				justify-content: flex-start !important;
				align-items: flex-start !important;
				border-radius: 16px;

				.category-header {
					margin: 24px 0px 24px 0px;
					width: 100%;
					height: 24px;
					display: flex;
					justify-content: space-between;

					> div {
						display: flex;
						align-items: center;
					}

					.category-filters {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-end;
						gap: 16px;

						.ant-select-selection {
							width: 100%;

							.ant-select-selection-selected-value {
								overflow: visible;
							}
						}
					}
				}

				.category-contents {
					width: 100%;
					padding-bottom: 24px;
					border-bottom: 1px solid var(--border-subtle);

					&:last-child {
						border-bottom: none;
					}
				}

				.table {
					height: 100%;
					width: 100%;

					.table-cell {
						width: calc((100% - 24px) / 4);
						height: 80px;
						margin: 0 8px 16px 0;

						@media (min-width: 1920px) {
							width: calc((100% - 72px) / 4);
							margin: 0 24px 16px 0;
							height: 124px;
						}

						&:nth-of-type(4n) {
							margin-right: 0px;
						}

						.cell-content {
							height: calc(100% - 28px);

							@media (min-width: 1920px) {
								height: calc(100% - 32px);
							}

							.cell-name {
								height: calc(100% - 16px);
								width: calc(100% - 16px);

								@media (min-width: 1920px) {
									height: calc(100% - 48px);
									width: calc(100% - 32px);
								}
							}
						}
					}
				}
			}
		}
	}
}

.category-right {
	background-color: var(--layer-01);
}

.category-view {
	&__icon:hover {
		background-color: var(--layer-hover-01) !important;
	}
}
