.genemodLayout {
	height: 100%;
	width: 100%;
	display: flex;
	position: relative;

	.genemodLayoutRight {
		height: 100%;
		flex: 1;
		min-width: calc(100% - 250px);
		display: flex;
		overflow-y: auto;
	}

	.genemodContent {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
}
