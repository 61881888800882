.experiment-image-container {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 16px 0 4px 0;

	&:hover,
	&_focused {
		.experiment-image {
			border-color: var(--button-primary) !important;
		}

		.draggable-icon-container {
			visibility: visible !important;
		}

		.addCaptionText {
			visibility: visible !important;
		}
	}

	.experiment-image-cover {
		position: absolute;
		border-radius: 4px;
		background-color: rgba(var(--layer-hover-02-rgb), 0.2);
	}

	.experiment-image {
		border-radius: 4px;
		border: solid 2px transparent;
	}

	.draggable-icon-container {
		visibility: hidden;

		.draggable-icon {
			cursor: col-resize;
		}
	}
}

.experiment-indicator-bar {
	margin-top: 10px;
	width: 100%;
	height: 4px;
	opacity: 0.7;
	background-color: var(--button-primary);
}

.outterContainer {
	.captionContainer {
		margin-left: 26px;
		margin-right: 26px;
	}

	.addCaptionText {
		visibility: hidden;
		cursor: pointer;
		align-self: center;
		font-size: 12px;
		color: var(--text-tertiary);
		user-select: none;
	}

	.captionText {
		font-size: 12px;
		color: var(--text-secondary);
	}

	&:hover,
	&_focused {
		.addCaptionText {
			visibility: visible !important;
		}
	}
}
