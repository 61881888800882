$toggle-switch-icon-color: var(--text-on-color);
.genemodLargeToggle {
	//div
	position: absolute;
	height: fit-content;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 16px;
	user-select: none;

	.genemodToggleBackground {
		height: 100%;
		width: fit-content;
		display: block;
		border: 1px solid var(--accent-subtle);
		border-radius: 16px;
	}

	.genemodToggleBackground input {
		opacity: 0;
		position: absolute;
	}

	.toggleSlider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		bottom: 0;
		transition: 0.4s;
		background-color: var(--accent-subtle);
		border-radius: 16px;
	}

	.toggleLabels {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 4px 16px;
		gap: 32px;
		cursor: pointer;

		.activeLabel,
		.inactiveLabel {
			z-index: 1;
			transition: 0.4s;
		}
		.activeLabel {
			color: $toggle-switch-icon-color;
		}

		.inactiveLabel {
			color: var(--text-secondary);
		}
	}
}

//small ant design switch
.genemodSmallToggle {
	display: flex;
	align-items: center;
	:global {
		.ant-switch-small.ant-switch::after {
			background-color: $toggle-switch-icon-color;
			box-shadow: var(--shadow-container);
		}
		.ant-switch-small.ant-switch.ant-switch-checked::after {
			background-color: $toggle-switch-icon-color;
			box-shadow: var(--shadow-container);
		}
	}
}

:global {
	.ant-switch {
		margin: 0;
		padding: 0;
		color: var(--text-primary);
		line-height: 1.5;
		background-color: var(--text-ghost);
		border: 1px solid transparent;
		border-radius: 100px;
		cursor: pointer;
		transition: 0.4s;
	}

	.ant-switch-checked {
		background-color: var(--accent-subtle);
	}
}

.smallToggleText {
	color: var(--text-secondary);
	margin-left: 8px;
	opacity: 1;
}
