@import "../Typography/index.module.scss";

.avatarGroup {
	:global(.ant-avatar-group) {
		display: inline-flex;
		direction: rtl; /* This is to get the stack with left on top */
	}

	&__reversed {
		:global(.ant-avatar-group) {
			direction: ltr;
		}

		:global(.ant-avatar:last-child) {
			margin-left: -4px;
		}
	}

	:global(.ant-avatar) {
		position: relative;
	}

	:global(.ant-avatar:not(:last-child)) {
		margin-left: -4px;
	}
}

:global(.ant-avatar-string) {
	transform: translateX(-50%) translateY(-50%) !important;
	top: 50%;
	font-family: var(--font-family-lato);
}

.UserAvatarSizes_XL {
	min-width: 64px;
	min-height: 64px;
	:global(.ant-avatar-string) {
		font-size: 20px;
		font-weight: 700;
		line-height: 28px;
	}
}
.UserAvatarSizes_L {
	min-width: 40px;
	min-height: 40px;
	:global(.ant-avatar-string) {
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
	}
}
.UserAvatarSizes_M {
	min-width: 32px;
	min-height: 32px;
	:global(.ant-avatar-string) {
		font-size: 14px;
		font-weight: 700;
		line-height: 22px;
	}
}
.UserAvatarSizes_S {
	min-width: 24px;
	min-height: 24px;
	:global(.ant-avatar-string) {
		font-size: 12px;
		font-weight: 700;
		line-height: 18px;
	}
}
.UserAvatarSizes_XS {
	min-width: 18px;
	min-height: 18px;
	:global(.ant-avatar-string) {
		font-size: 10px;
		font-weight: 400;
		line-height: 12px;
	}
}

.deactivated {
	background-color: var(--text-ghost) !important;
}

.deactivatedWithImage {
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(#d8dbeb, 0.6);
		height: 100%;
		width: 100%;
	}
}

.avatarAndName {
	display: flex;
	flex-direction: row;
	gap: 8px;
	overflow: hidden;
	width: 100%;
	align-items: center;
}
