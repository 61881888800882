.genemod-moving-cart.genemod-moving-cart {
	.ant-modal {
		height: 698px;
	}
	.ant-modal-body {
		border-top: var(--border-subtle-solid);
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	.ant-modal-content {
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-bottom: 0px !important;
	}
	.ant-modal-close {
		top: 16px;
		right: 8px;
	}
	.ant-modal-title {
		padding-left: 32px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		// Extra 32px to accomodate for the X icon
		width: calc(100% - 64px);
	}

	.ant-modal-body,
	.ant-modal-header,
	.ant-modal-content,
	.ant-modal-close,
	.ant-modal-title {
		background-color: var(--background);
	}
}

.genemod-moving-cart-table.genemod-moving-cart-table {
	width: 388px;
	.ant-table-tbody > tr:hover {
		background-color: var(--layer-hover-01-v2);
	}
	.ant-table-tbody > tr > td {
		height: 56px;
	}
}
