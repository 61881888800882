.option {
	display: flex;
	align-items: center;
	gap: 12px;

	.selectButton {
		margin-left: auto;
		visibility: hidden;
	}

	&:hover {
		.selectButton {
			visibility: visible;
		}
	}
}

.underlined {
	border-bottom: 1px solid var(--border-subtle);
	padding: 12px 0;
}
