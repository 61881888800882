@use "@common/components/Typography/index.module.scss";
@use "@common/styles/mixins.scss";

.nameSearchEmptyMsg {
	@mixin noResultsMixin {
		margin-bottom: 42px;

		&::after {
			content: "No results";
			@extend .label;

			display: flex;
			align-items: center;
			height: 42px;
			color: var(--text-secondary);
		}
	}

	&__Freezer [title="Freezer results"] {
		@include noResultsMixin();
	}

	&__Consumable [title="Consumables results"] {
		@include noResultsMixin();
	}
}

.materialsTable {
	:global {
		.ant-table-tbody > tr {
			cursor: inherit !important;
			height: 48px;
			transition: visibility 0s;
			> td {
				transition: visibility 0s;
			}

			&:hover {
				:local(.addLinkButton) {
					visibility: visible;
				}
			}
		}

		.ant-table-tbody > tr > td {
			@include mixins.linkStyle();
		}

		.ant-table-header.ant-table-hide-scrollbar {
			// By default the opacity was set to 0.9999 and this was causing pop over to be appear as it was behind the table rows
			opacity: 1 !important;

			// hide header scrollbar
			overflow: hidden !important;
			margin-bottom: -20px !important;
			padding-bottom: 20px !important;
			.ant-table-fixed {
				box-shadow: none;
			}
		}
		.ant-table-fixed-right table {
			background: var(--layer-01) !important;
		}
		.ant-table-fixed-right {
			z-index: 0;
			transform: translateX(8px);
			box-shadow: none;
			.ant-table-row {
				.ant-table-row-cell-break-word {
					padding: 0;
				}
				&:not(.ant-table-row-hover) {
					:local(.actionCell) {
						visibility: hidden;
					}
				}
			}

			& table {
				background-color: transparent !important;
			}
		}
		.ant-table-scroll-position-right {
			:local(.actionCell) {
				box-shadow: none;
			}
		}
	}

	td:empty:before {
		content: "-";
	}

	a:hover {
		text-decoration: underline;
	}
}

.tableSelect {
	&:global(.ant-select) {
		border-radius: 4px;
		border: 1px solid var(--border-subtle);
		:global {
			.ant-select-selection {
				height: 34px;
			}
		}
	}
}

.flex {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.editLinkItemContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	.linkItem {
		width: calc(100% - 36px);
	}
}
.actionCell {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	margin-left: 4px;
	box-shadow: -2px 0 6px 0 var(--shadow-overlay);
	background-color: var(--layer-01) !important;
	gap: 16px;

	&__edit {
		visibility: visible !important;
	}
	&__disabled {
		visibility: hidden !important;
	}
}
.addLinkButton {
	visibility: hidden;
	transition: visibility 0s;
}
.linkItem {
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	width: 100%;
	& a {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.materialNameAutoComplete {
	&:global(.ant-select-auto-complete.ant-select) {
		height: 32px;
		background-color: var(--layer-02-v2);
		border: 1px solid var(--border-subtle-v2);
		border-radius: 4px;
		display: flex;
		align-items: center;
		&:global(.ant-select-focused) {
			border-color: var(--border-active);
			box-shadow: 0 0 0 2px var(--border-subtle-v2);
		}
		:global {
			.ant-select-selection {
				background-color: transparent !important;
			}
			.ant-input {
				border: none;
				padding: 0 16px;
				color: var(--text-primary);
				font-family: var(--font-family-lato);
				font: var(--font-body);
				letter-spacing: 0.01px;
				&:focus {
					box-shadow: none;
					border: none;
				}
			}
			.ant-select-clear {
				position: absolute;
				right: 8px;
				background: transparent;
				color: var(--text-tertiary);
				opacity: 1;
			}
		}
	}
}
.searchOption {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr max-content;
	gap: 16px;
}

.materialNameAutoCompleteDropdown {
	&:global(.ant-select-dropdown) {
		padding: 4px 0 !important;
		border-radius: 4px;
		border: var(--border-subtle-solid);
		box-shadow: var(--shadow-container);
		background-color: var(--layer-03);
		max-height: 319px; // height of 7 and half result
		@media (min-width: 1920px) {
			max-width: 496px;
		}
		:global {
			.ant-select-dropdown-menu-item {
				height: 42px;
				padding: 12px 16px;
			}
			.ant-select-dropdown-menu-item-selected {
				background-color: transparent;
			}
			.ant-select-dropdown-menu-item-active {
				background-color: var(--layer-hover-03);
			}
			.rc-virtual-list-scrollbar-thumb {
				background-color: var(--scrollbar-color);
			}
			.ant-select-dropdown-menu-item-group-title {
				color: var(--text-tertiary);
				font: var(--font-caption);
				height: 24px;
				padding: 6px 16px;
			}
		}
	}
}

.currencyInput {
	padding-left: 4px !important;
}

.currencySelector {
	&:global(.genemod-select__isInput) {
		border: none !important;
		border-right: 1px solid var(--border-subtle) !important;
		border-radius: 0 !important;
		overflow: hidden;
		&::before {
			display: none !important;
		}
		&:global(.ant-select-focused) {
			border: none !important;
			border-right: 1px solid var(--border-subtle) !important;
			border-radius: 0 !important;
		}
		:global {
			.ant-select-selection {
				background-color: transparent;
				padding: 8px;
				padding-right: 4px;
			}
			.ant-select-selector {
				overflow: hidden;
				.ant-select-selection-wrap {
					height: 100%;
				}
			}
		}
	}
	&__disabled {
		&:global(.genemod-select__isInput) {
			cursor: not-allowed !important;
			:global {
				.ant-select-selection,
				.ant-select-arrow-icon {
					cursor: not-allowed !important;
					pointer-events: none !important;
				}
			}
		}

		:global {
			.ant-select-dropdown-menu-item {
				height: 42px;
				padding: 12px 16px;
			}

			.ant-select-dropdown-menu-item-active {
				background-color: var(--layer-hover-03);
			}

			.rc-virtual-list-scrollbar-thumb {
				background-color: var(--scrollbar-color);
			}
		}
	}
}

.currencyInput {
	padding-left: 4px !important;
}

.iconDisabled {
	& path {
		stroke: var(--button-disabled) !important;
	}
}

.editAndDeleteButton {
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: var(--accent-subtle) 1px solid;
	border-radius: 50px;
}

.flex {
	display: flex;
}
