.FreezerCategoryList {
	height: 100%;
	width: 100%;

	.ant-table {
		font: var(--font-body);
		text-align: left;

		.ant-table-tbody > tr > td {
			padding: 12px 16px 12px 16px;
			border-top: none;
			border-bottom: solid 1px var(--border-subtle);
		}

		.ant-table-tbody > tr.ant-table-row > td {
			background-color: unset !important;
		}

		.ant-table-tbody > tr.ant-table-row {
			cursor: pointer;
		}
	}
}
