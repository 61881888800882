.user-selector {
	height: 100%;
	width: 100%;
	display: flex;

	.name-column {
		height: 50px;
		width: 80px;
		margin-right: 10px;
		font-weight: 1000;
		display: flex;
		align-items: center;
		padding-bottom: 10px;
	}

	.full-width {
		width: 100%;
	}

	.remaining-width {
		width: calc(100% - 90px);
	}

	.select-users {
		height: 100%;
		display: flex;
		position: relative;
		flex-direction: column;

		.selected-container {
			height: 50px;
			width: 100%;
			display: flex;
			margin-bottom: 10px;
			overflow-x: scroll;
			overflow-y: hidden;

			.selected {
				flex: 1;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0px 5px;
				box-sizing: border-box;
				border: 1px solid #94969a;
				overflow: visible;
			}

			.selected-user {
				margin-right: 5px;
				position: relative;
				cursor: pointer;
			}

			.selected-user:before {
				position: absolute;
				border-radius: 50%;
				background-color: #af2d22;
				content: "x";
				bottom: 0;
				right: 0;
				height: 16px;
				width: 16px;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
		}

		.unselected {
			height: calc(100% - 50px - 10px);
			display: flex;
			flex-direction: column;
			border: 1px solid #94969a;
			position: relative;

			.user-search {
				height: 35px;
				background-color: #363c45;
				color: white;
				outline: none;
				border: 1px solid #94969a;
				border-radius: 3px;

				&:focus {
					border: 1px solid #94969a;
				}

				&::placeholder {
					user-select: none;
					color: white;
				}
			}

			.user-results {
				height: calc(100% - 35px);
				overflow-y: auto;
			}

			.add-user-row {
				display: flex;
				align-items: center;
				padding: 10px;
				cursor: pointer;
				user-select: none;

				.avatar {
					margin: 0 10px;
					flex: 0 0 32px;
				}

				&:hover {
					background-color: #ffffff33;
				}

				.fullname {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.shield {
				position: absolute;
				background-color: #21262cb3;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 3;
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;
			}

			.hidden {
				display: none;
			}
		}

		.unselected,
		.selected {
			border-radius: 3px;
		}
	}
}
