$lrPadding: 25px;

.container {
	height: 100%;
	width: 100%;
	overflow: auto;
}

.flex {
	display: flex;
	align-items: center;
	height: 36px;
	border-bottom: var(--border-invisible-solid);
	padding-left: $lrPadding;
	padding-right: $lrPadding;
}

.export {
	&:global(.ant-btn.genemod-button) {
		background-color: var(--layer-02);
		border: var(--border-subtle-solid);
		color: var(--text-secondary);
		padding: 0 8px !important;

		&:global(.ant-btn.ant-btn-loading) {
			padding-left: 20px !important;
		}

		&:hover {
			background-color: var(--layer-hover-01);
			color: var(--text-secondary);
		}
	}
}

.tableContainer {
	position: relative;
	padding-left: $lrPadding;
	padding-right: $lrPadding;
}

.actions {
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 24px;
	padding-left: $lrPadding;
	padding-right: $lrPadding;
}
