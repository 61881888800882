.container {
	:global(.ant-popover-inner) {
		width: 400px;
		height: 560px;

		@media (min-width: 1919px) {
			width: 560px;
			height: 640px;
		}

		background-color: var(--layer-03);
		border: solid 1px var(--border-subtle);
	}
	:global(.ant-popover-arrow) {
		background-color: var(--layer-03);
		border-top-color: var(--layer-03) !important;
		border-left-color: var(--layer-03) !important ;
		border-top: solid 1px var(--border-subtle) !important;
		border-left: solid 1px var(--border-subtle) !important;
		transform: translateY(1px) rotate(45deg);
	}

	:global(.ant-popover-inner-content) {
		padding: 0;
		height: 500px;
		overflow-y: auto;
	}

	:global(.ant-popover-title) {
		padding: 0px;
		border-bottom: var(--border-subtle-solid);
	}
}
.antPopoverTitle {
	padding: 0;
	padding-left: 24px;
	padding-right: 24px;
	height: 58px;
	display: flex;
	gap: 8px;
	align-items: center;
}

.notificationIconWrapper {
	position: relative;
	.indicator {
		position: absolute;
		top: 0;
		right: 0;
		border: 1px solid var(--text-on-color);
		border-radius: 50%;
	}

	.icon {
		&:hover,
		&__active {
			background-color: var(--layer-hover-02);
			border-radius: 2px;
		}
	}
}

.appliedFilter {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	padding: 4px 8px;
	border-radius: 12px;
	background-color: var(--layer-02);
}

.lastWords {
	width: 270px;
	margin: auto;
	padding: 24px 0;
	text-align: center;
	align-self: center;
}

.emptyResult {
	width: 100%;
	text-align: center;
	margin-top: 32px;
}

.popup {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.cardsContainer {
	position: relative;
	flex: 1;
	overflow: scroll;
}

.cards {
	position: absolute;
}

.notificationSettings {
	width: 100%;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	padding: 13px 0;
	border-top: 1px solid var(--border-subtle);
}

.empty {
	width: 100%;
}
