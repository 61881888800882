.boxName {
	&__isItemGroup {
		color: var(--text-secondary);
		opacity: var(--disabled-opacity);
	}
}

.boxPreview {
	position: absolute;
	top: 82px;
	left: 688px;
	padding: 24px;
	border-radius: 8px;
	border: solid 1px var(--border-subtle);
	width: fit-content;
	background-color: var(--layer-popover);
	box-shadow: 0px 6px 20px 4px rgba(21, 25, 45, 0.15);
}

.previewStyling {
	* {
		font-size: 5px !important;
		line-height: 6px !important;
	}
}

.linkButton {
	span {
		color: var(--action-link) !important;
	}
}

.caption {
	color: var(--text-tertiary-v2) !important;
}
