textarea.ant-input {
	min-height: 56px;
	background-color: var(--layer-02-v2) !important;
	&:hover {
		border: 1px solid var(--border-hover) !important;
	}

	&:focus {
		box-shadow: 0 0 0 2px var(--border-subtle-v2);
		border: 1px solid var(--border-hover) !important;
	}
}
form textarea.ant-input {
	background-color: var(--layer-02-v2);
	margin-bottom: 0;
}
