.menuWrapper {
	display: flex;
	height: calc(100vh - 48px);
}
.menuContents {
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 100%;
	width: 19vw;
	min-width: 240px;
	max-width: 364px;
	border-right: 1px solid var(--border-subtle-v2);
	color: var(--text-primary);

	@media (min-width: 1281px) {
		min-width: 19vw;
	}

	@media (min-width: 1920px) {
		min-width: 364px;
	}
}

.menuTitle {
	margin-top: 48px;
	margin-bottom: 32px;
	padding-left: 24px;
}

.menuSubtitle {
	padding-left: 24px;
	height: 48px;
	display: flex;
	align-items: center;
	color: var(--text-primary);
	margin-top: 24px;
}

.menuItem {
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 24px;
	padding-right: 16px;
	justify-content: space-between;
	cursor: pointer;
	color: var(--text-secondary);
	&:hover {
		background-color: var(--next-layer);
	}
}
.menuItem__selected {
	border-right: var(--border-subtle-emph-width) solid var(--brand-08);
	background-color: var(--current-layer-hover);
	cursor: auto;
	&:hover {
		background-color: var(--current-layer-hover);
	}
}
.menuItemContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}
