.hideSubtableCollapseArrow {
	:first-child {
		/** hide only child content ( icon ) */
		display: none;
	}
}
.SubtableCollapseArrow {
	padding: 0 !important;
	> div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
