.barcodeModal {
	:global .ant-modal-footer {
		margin-top: 32px;
	}
}
.checkboxWrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 24px;
}
.checkbox {
	flex: 0 0 33%;
}

.preview {
	&Wrapper {
		width: 100%;
		border: 1px solid var(--border-subtle);
		border-radius: 8px;
	}

	&Header {
		display: flex;
		justify-content: center;
		gap: 24px;
		align-items: center;
		padding: 12px;
		border-bottom: 1px solid var(--border-subtle);
	}

	&Content {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 24px 66px;
	}

	&Component {
		background-color: white;
		border-radius: 8px;
	}
}
