.container {
	background-color: var(--layer-02) !important;
	padding: 16px 8px;
	max-height: 302px;
	width: 490px;
	border: 1px solid var(--border-subtle-solid);
	border-radius: 4px;
	overflow: auto;
}

.searchBarContainer {
	padding: 0 8px;
	:global {
		.genemod-search-container {
			height: 36px;
			.ant-select-auto-complete .ant-input {
				padding: 0;
			}
			.ant-select-auto-complete .ant-select-selection__placeholder {
				margin-left: 0;
			}
		}
	}
}

.resultsLabel {
	margin-top: 16px;
	margin-left: 8px;
}

.listContainer {
	margin-top: 8px;
}

.boxContainer {
	padding: 8px 6px;
	cursor: pointer;
}

.boxContainer:hover {
	background-color: var(--layer-hover-02);
	border-radius: 4px;
}

.boxContainerDisabled {
	cursor: not-allowed;
}

.boxNameContainer {
	display: flex;
	margin-bottom: 4px;
	justify-content: space-between;
}
