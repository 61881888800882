.newItemModal {
	:global {
		.information-form .form-body {
			padding: 0 20px 0 0;
			margin-bottom: 24px;
		}
		.genemod-modal {
			width: 554px !important;
		}
		.create-new-item-container {
			background-color: transparent;
		}
		.ant-modal-footer {
			margin: 0;
		}
		.ant-modal-body {
			//min-height: 560px !important;
			max-height: 560px !important;
		}
		.formSectionTitleWrapper {
			height: 34px;
		}
	}
	:global {
		.genemod-modal .ant-modal-content {
			padding-right: 2px;
		}
	}
}
