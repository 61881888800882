.animatedPanel {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}

.visible {
	opacity: 1;
	animation: slideIn 0.1s ease-out forwards;
}

.hidden {
	opacity: 0;
	animation: slideOut 0.1s ease-out forwards;
}

.unmounted {
	opacity: 0;
	display: none;
}

@keyframes slideIn {
	0% {
		opacity: 0;
		transform: translateY(1%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes slideOut {
	0% {
		opacity: 1;
		transform: translateY(0%);
	}
	99% {
		opacity: 0;
		transform: translateY(1%);
	}
	100% {
		opacity: 0;
		transform: scale(0) translateY(9999px);
	}
}
