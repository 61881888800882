.main {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 12px 24px;
	&:hover {
		text-decoration: none;
		background-color: var(--layer-hover-03);
		.objectName__link {
			text-decoration: underline;
			color: var(--link-primary-hover);
		}
	}
}

.objectName {
	font-weight: bold;
	color: var(--text_secondary);
	display: inline;
	&__link {
		color: var(--link-primary);
	}
}

.read {
	opacity: 0;
	&:hover {
		opacity: 0.5;
	}
}

.avatarWrapper {
	position: relative;
	align-self: flex-start;
}

.notificationIcon {
	border-radius: 50%;
	border: 1px solid var(--text-on-color);
	height: 20px;
	aspect-ratio: 1;
	position: absolute;
	bottom: -4px;
	right: -5px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		transform: scale(1.2);
	}
}

.dot {
	margin-left: auto;
	align-self: flex-start;
	min-height: 18px;
	min-width: 18px;
	display: grid;
	place-items: center;
	cursor: pointer;
	position: relative;

	&::before {
		content: "";
		width: 18px;
		height: 18px;
		background-color: var(--layer-hover-03);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(0);
		border-radius: 50%;
		transition: transform 0.2s ease;
	}

	&:hover {
		&::before {
			transform: translate(-50%, -50%) scale(1);
		}
	}

	// Put the contents in front of the pseudo-element.
	> * {
		z-index: 1;
	}
}

.typeIcon {
	height: 32px;
	aspect-ratio: 1;
	display: grid;
	place-items: center;
	border-radius: 50%;
}
