.formContainer {
	background-color: var(--current-layer);

	.select {
		margin-bottom: 32px;
	}

	.topicError {
		padding-left: 16px;
		padding-top: 8px;
	}

	.boardLabel {
		margin-bottom: 8px;
	}
}
