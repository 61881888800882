.container {
	display: flex;
	align-items: center;
	height: 24px;
	color: var(--text-primary);
	font: var(--font-label);
	letter-spacing: 0.01px;
}

.numbers {
	margin: 0;
	display: flex;
	align-items: center;
}

.currentPage {
	margin: 0 16px 0 24px;
	font: var(--font-label);
	color: var(--text-secondary);
}

.number {
	width: 24px;
	text-align: center;
	margin-right: 4px;
	cursor: pointer;
	user-select: none;
	color: var(--text-secondary);
	line-height: 18px;
	opacity: 0.6;

	&:last-child {
		margin-right: 0px;
	}

	&__selected {
		color: var(--text-primary);
		text-decoration: underline;
		opacity: 1;
	}
}

.pageNumContainer {
	margin: 0 12px;
	display: flex;
}

.prev-next-btn {
	color: var(--text-secondary);
	cursor: pointer;
	user-select: none;
	margin-top: auto;
	margin-bottom: auto;
	width: 18;
	height: 18;
	display: flex;
	align-items: center;

	&__disabled {
		color: var(--text-tertiary);
		cursor: not-allowed;
		opacity: 0.6;
	}
}
