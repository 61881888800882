.freezerItemList {
	height: 100%;
	width: 100%;
	border-radius: 16px;
	position: relative;
	overflow: hidden;
}

.bookmarkWrapper {
	position: relative;
	.bookmarkIcon {
		position: absolute !important;
		top: -12px;
		left: -12px;
		color: var(--accent-strong) !important;
	}
}

.addNewItemView {
	position: absolute;
	left: 0;
	top: 56px;
	margin-top: -8px;
	margin-left: 40px;
}
.itemListTableWrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	.tableHeader {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	.tableContainer {
		position: relative;
		height: 100%;
		overflow: scroll;
	}
}

.itemListTable {
	height: 100%;
	> div {
		height: 100%;
		> div {
			height: 100%;
		}
	}
	:global {
		.ant-table-thead > tr > th {
			height: 48px;
			&:first-of-type {
				::before {
					width: 0 !important;
				}
			}
		}
		.ant-table-body {
			overflow-y: auto;
		}
		.ant-table-tbody > tr > td {
			padding: 0 16px;
		}
		.ant-table-placeholder {
			border: none;
		}
		.ant-table {
			opacity: 1;
			min-height: 100%; /* TODO: fix it */
			background-color: var(--layer-02-v2);
			color: var(--text-primary-v2);
			border: 1px solid var(--border-subtle-v2);
			border-radius: 12px;
			overflow: auto;
		}
	}
}

.toolbarContainer {
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.toolbarContent {
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
}

.checkboxTable {
	:global {
		.genemod-checkbox-container {
			width: 32px;
			height: 32px;
			justify-content: center;
			gap: 0;
		}
		.checkbox-label {
			width: 0;
		}
	}
}

.selectedRow {
	background-color: var(--layer-focus-02);
}

.selectedMultiRow {
	background-color: var(--layer-hover-02-v2);
}
