.breadcrumb {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	gap: 4px;
	align-items: center;
	color: var(--text-secondary-v2);
	&:hover {
		color: var(--button-text);
	}
	&__active {
		pointer-events: none;
		cursor: default;
		.crumbLabel {
			color: var(--text-primary-v2);
		}
	}
	&__disable {
		color: var(--text-ghost);
		pointer-events: none;
		cursor: default;
	}
	&__last {
		.chevronRight {
			display: none;
		}
	}

	.chevronRight {
		color: var(--text-primary-v2);
	}

	.crumbLabel {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 4px;
	}
}
