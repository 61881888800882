.editorText {
	margin-bottom: 4px;
	margin-top: 4px;
	position: relative;
	font-size: 12px;
	line-height: 18px;
	tab-size: 12;
	@media (min-width: 1920px) {
		font-size: 14px;
		line-height: 20px;
	}
}
