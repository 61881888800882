.dotTyping {
	--offset: 9999px;
	--middle: calc(var(--offset) + calc(var(--size) * 2));
	--right: calc(var(--offset) + calc(var(--size) * 4));

	position: relative;
	left: calc(var(--offset) * -1);
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: var(--dotTypingColor);
	color: var(--dotTypingColor);
	box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
		var(--middle) 0 0 0 var(--dotTypingColor),
		var(--right) 0 0 0 var(--dotTypingColor);
	animation: dotTyping 1.5s infinite linear;

	@keyframes dotTyping {
		0% {
			box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
				var(--middle) 0 0 0 var(--dotTypingColor),
				var(--right) 0 0 0 var(--dotTypingColor);
		}
		16.667% {
			box-shadow: var(--offset) calc(var(--size) * -1) 0 0
					var(--dotTypingColor),
				var(--middle) 0 0 0 var(--dotTypingColor),
				var(--right) 0 0 0 var(--dotTypingColor);
		}
		33.333% {
			box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
				var(--middle) 0 0 0 var(--dotTypingColor),
				var(--right) 0 0 0 var(--dotTypingColor);
		}
		50% {
			box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
				var(--middle) calc(var(--size) * -1) 0 0 var(--dotTypingColor),
				var(--right) 0 0 0 var(--dotTypingColor);
		}
		66.667% {
			box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
				var(--middle) 0 0 0 var(--dotTypingColor),
				var(--right) 0 0 0 var(--dotTypingColor);
		}
		83.333% {
			box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
				var(--middle) 0 0 0 var(--dotTypingColor),
				var(--right) calc(var(--size) * -1) 0 0 var(--dotTypingColor);
		}
		100% {
			box-shadow: var(--offset) 0 0 0 var(--dotTypingColor),
				var(--middle) 0 0 0 var(--dotTypingColor),
				var(--right) 0 0 0 var(--dotTypingColor);
		}
	}
}
