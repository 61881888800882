@use "sass:map";

$colors: (
	// ~~~~~~~~~~~ Styleguide colors ~~~~~~~~~~~
	// Note: try not to use rgba colors, since they do not get automatically translated into hex codes
	background:			(light: #ffffff, dark: #202534),
	background-hover:	(light: #dfe4f4, dark: #303544),
	background-accent: (light: #eceef9, dark: #171D2E),

	layer-01:			(light: #f6f7fc, dark: #272E3C),
	layer-01-v2:			(light: #FAFAFC, dark: #202436),
	layer-hover-01: 	(light: #e1e7f5, dark: #373F4E),
	layer-hover-01-v2: 	(light: #F0F1F6, dark: #202436),
	layer-focus-01: 	(light: #E4E6EC, dark: #272B3D),
	layer-focus-02: 	(light: #F0F1F6, dark: #40455C),
	layer-focus-03: 	(light: #E4E6EC, dark: #5A617C),
	layer-focus-popover: (light: #F0F1F6, dark: #5A617C),
	layer-hover-popover: (light: #FAFAFC, dark: #5A617C),
	layer-focus-inverse-popover: (light: #5A617C, dark: #F0F1F6),
	layer-02:			(light: #ffffff, dark: #313745),
	layer-02-v2:			(light: #ffffff, dark: #272B3D),
	layer-hover-02:		(light: #eaf0fd, dark: #42495A),
	layer-hover-02-v2:		(light: #F5F6F9, dark: #272B3D),
	layer-03:			(light: #f6f7fc, dark: #38404D),
	layer-03-v2:			(light: #FAFAFC, dark: #40455C),
	layer-hover-03:		(light: rgba(88, 128, 239, 0.2), dark: #4C545F),
	layer-hover-03-v2:		(light: #F0F1F6, dark: #4C545F),
	layer-inverse:		(light: #272E3C, dark: #f6f7fc),
	layer-inverse-hover: (light: #373F4E, dark:#e1e7f5),
  layer-accent-01: (light: #e0e3f0, dark: #1F2535),
  layer-accent-01-v2: (light: #051D851A, dark: #57A9F41A),
  layer-accent-02: (light: #ECEEF9, dark: #1F2535),
  layer-accent-02-v2: (light: #F0F1F6, dark: #1F2535),
  layer-accent-03: (light: #051D8533, dark: #051D8533),
	layer-popover:			(light: #ffffff, dark: #40455C),

	text-placeholder: (light: #D2D4DF, dark: #5A617C),
	text-primary:		(light: #000c41, dark: #FAFAFF),
	text-primary-v2:		(light: #15192D, dark: #FAFAFF),
	text-secondary:		(light: #101e5b, dark: #E6E6F0),
	text-secondary-v2:		(light: #40455C, dark: #e4e6ec),
	text-tertiary:		(light: rgba(16, 30, 91, 0.6), dark: rgb(230, 230, 240, 0.6)),
	text-tertiary-v2:		(light: #7B8096, dark: #B0B4C8),
	text-ghost:			(light: rgba(16, 30, 91, 0.45), dark: rgb(230, 230, 240, 0.45)),
	text-on-color:		(light: #ffffff, dark: #ffffff),
	text-inverse: 		(light: #F5F5F8, dark: #000C41),
	text-disabled: 		(light: #B0B4C8, dark: #B0B4C8),

	border-hover:		(light: #374A9D, dark: #79BAF6),
	border-active:		(light: #051d85, dark: #57A9F4),
	border-subtle:		(light: #d8dbeb, dark: #404858),
	border-subtle-v2:		(light: #D2D4DF, dark: #5A617C),
	border-invisible:	(light: #EDEFF8, dark: #353941),
	border-invisible-v2:	(light: #f0f1f6, dark: #5A617C),
	border:				(light: #edeff8, dark:#353941),

	field-01:			(light: #fffffc, dark: rgba(209, 229, 255, 0.12)),
	field-02:			(light: #F6F7FB, dark: rgba(209, 229, 255, 0.12)),
	field-disabled:		(light: rgba(70, 83, 136, 0.15), dark: rgba(32, 33, 37, 0.15)),

	link-primary:		(light: #418CD8, dark: #24ABF4),
	link-primary-hover: (light: rgba(65, 140, 216, 0.6), dark: #31B3F8),
	link-inverse:		(light: #24ABF4, dark: #418CD8),

	accent-subtle:		(light: #051D85, dark: #33CCF0),
	accent-strong:		(light: #D94B7C, dark: #33CCF0),

	button-primary:			(light: #051D85, dark: #33CCF0),
	button-hover-primary:	(light: #5064a7, dark: #5ADCFA),
	button-primary-danger:	(light: #d32f2f, dark: #d32f2f),
	button-hover-danger:	(light: #e86868, dark: #e86868),
	button-text: 			(light: #418CD8, dark: #33CCF0),
	button-hover-text: 		(light: rgba(65, 140, 215, 0.6), dark: #5ADCFA),
	button-disabled: 		(light: rgba(70, 83, 136, 0.3), dark: rgba(186, 195, 229, 0.3)),
	button-plus-circle:		(light: #465388, dark: #33CCF0),

  action-default: (light: #051D85, dark: #57A9F4),
  action-hover: (light: #374A9D, dark: #79BAF6),
  action-click: (light: #04176A, dark: #4E98DC),
  action-disabled: (light: #F0F1F6, dark: rgb(90, 97, 124, 0.4)),
  action-link: (light: #1890ff, dark: #1890ff),
  link-hover: (light: #40A9FF, dark: #40A9FF),
  link-click: (light: #096DD9, dark: #096DD9),
  danger: (light: #f24c4e, dark: #f24c4e),

	red:				(light: #D32F2F, dark: #D32F2F),
	red-contrast:		(light: #B71C1C, dark: #EF535B),
	yellow:				(light: #F7B510, dark: #F7B510),
	yellow-contrast:	(light: #D38D1E, dark: #FBC02D),
	green:				(light: #4CAF50, dark: #4CAF50),
	green-contrast:		(light: #2E7D32, dark: #59B15D),
	blue:				(light: #3B66C9, dark: #3B66C9),
	blue-contrast:		(light: #3949AB, dark: #4D89E8),
	aqua:				(light: #43c6c3, dark: #00a8a5),
	aqua-contrast:		(light: #00a8a5, dark: #43c6c3),
	brown: 				(light: #c79564, dark: #c79564),
	pink: 				(light: #DD4587, dark: #EB5B99),
	neutral-gray: (light: #969dac, dark: #5c6374),
	dust-red: (light: #F24C4E, dark: #F24C4E),
	neutral-gray-contrast: (light: rgba(16, 30, 91, 0.6), dark: rgb(230, 230, 240, 0.6)),
	gray-01: (light: #FAFAFC, dark: #15192D),
	gray-01-v2: (light: #ffffff, dark: #272B3D),
	gray-02: (light: #F5F6F9, dark: #5A617C),
	gray-03: (light: #F0F1F6, dark: #272b3d),
	gray-04: (light: #E4E6EC, dark: #40455C),
	gray-05: (light: #D2D4DF, dark: #5A617C),
	gray-06: (light: #B0B4C8, dark: #B0B4C8),
	gray-08: (light: #5A617C, dark: #E4E6EC),
	transparent: 		(light: rgba(0, 0, 0, 0), dark: rgba(0, 0, 0, 0)),

	shadow-container-color:			(light: rgba(186, 193, 219, 0.4), dark: rgba(29, 36, 51, 0.4)), // shadow-primary and shadow-secondary in specs
	shadow-text-and-border-color:	(light: rgba(0, 12, 64, 0.2), dark: #1D2433), // shadow-text in specs
	shadow-overlay:					(light: rgba(43, 48, 69, 0.2), dark: rgba(8, 14, 28, 0.2)),

	platform-menu-shadow-color: 	(light: rgba(0, 0, 0, 0), dark: rgba(8, 16, 29, 0.2)),
	box-card-handle-shadow-color: 	(light: rgba(184, 188, 203, 0.2), dark: rgba(184, 188, 203, 0.2)),
  // ~~~~~~~~~~~ Exception colors ~~~~~~~~~~~
	layer-rack: 		(light: #d7deee, dark: #d7deee),

	platform-menu:		(light: #051D85, dark: #0F1323),
	rack-card: 			(light: #f6f7fb, dark: rgba(209, 229, 254, 0.16)),
	box-grid-cell:		(light: #051d85, dark: #051d85),
	header-cell-fill: 	(light: #FBFCFE, dark: #2E323F),
	darker-cell-fill:	(light: #EDF0F6, dark: #383D4D),
	ghost-cell-fill:	(light: #fbfcfe, dark: #2e323f),
	ghost-cell-stroke:	(light: #e8ebf9, dark: #383e4a),

	furniture-bench:			(light: #E9498E, dark: #E9498E),
	furniture-bench-inside:		(light: rgba(233, 73, 142, 0.2), dark: rgba(233, 73, 142, 0.2)),
	furniture-shelf:			(light: #4D89E8, dark: #4D89E8),
	furniture-shelf-inside:		(light: rgba(77, 137, 232, 0.2), dark: rgba(77, 137, 232, 0.2)),
	furniture-desk:				(light: #36CFC9, dark: #36CFC9),
	furniture-desk-inside:		(light: rgba(54, 207, 201, 0.2), dark: rgba(54, 207, 201, 0.2)),
	furniture-default:			(light: #D8DBEB, dark: #555C69),
	furniture-default-inside:	(light: rgba(216, 219, 235, 0.2), dark: rgba(85, 92, 105, 0.2)),
	onboarding-background: 		(light: #f6f7fc, dark: #f6f7fc), // Used for auth pages. Same color token as layer-01
	editor-table-cell-selected-border: (light: #d8dbeb, dark: #545b69),
	box-category-info: (light: #051D850D, dark: #33CCF00D),


	// ~~~~~~~~~~~ Editor colors ~~~~~~~~~~~
	color-1A:		(light: #000c41, dark: #f5f5f8),
	color-1B:		(light: #101e5b, dark: #dee3ea),
	color-1C:		(light: rgba(16, 30, 91, 0.6), dark: rgba(222, 227, 234, 0.6)),
	color-2A:		(light: #da1f28, dark: #fc8c8c),
	color-2B:		(light: #2e76c9, dark: #83acf8),
	color-2C:		(light: #7f54c4, dark: #7f54c4),
	color-3A:		(light: #d36d07, dark: #eda054),
	color-3B:		(light: #1c934a, dark: #1c934a),
	color-3C:		(light: #00969d, dark: #5ecad0),
	hl-1B:			(light: #ffc014, dark: #ffde71),
	hl-1C:			(light: #67ff53, dark: #97fa8a),
	hl-2A:			(light: #fa8dff, dark: #ee6df4),
	hl-2B:			(light: #f25f5f, dark: #f25f5f),
	hl-2C:			(light: #81d5f5, dark: #43cbfd),
	highlighter:	(light: #1e86ff4d, dark: #3f97ef4d),

	// ~~~~~~~~~~~ Colors ~~~~~~~~~~~
	// ~~~~~~ Pink ~~~~~~
	pink-01:		(light: #FCE4EE, dark: #5A1130),
	pink-02:		(light: #F8C8DD, dark: #791640),
	pink-03:		(light: #F4A4C7, dark: #9E2057),
	pink-04:		(light: #F080B0, dark: #BA3A72),
	pink-05:		(light: #EB5B99, dark: #DD4587),
	pink-06:		(light: #DD4587, dark: #EB5B99),
	pink-07:		(light: #BA3A72, dark: #F080B0),
	pink-08:		(light: #9E2057, dark: #F4A4C7),
	pink-09:		(light: #791640, dark: #F8C8DD),
	pink-10:		(light: #5A1130, dark: #FCE4EE),
	// ~~~~~~ Dust Red ~~~~~~
	dust-red-01:		(light: #FFE9E9, dark: #5C0011),
	dust-red-02:		(light: #FFCCCD, dark: #7D191B),
	dust-red-03:		(light: #FFAFB0, dark: #A12527),
	dust-red-04:		(light: #FF9293, dark: #CE4142),
	dust-red-05:		(light: #FF6D6F, dark: #F24C4E),
	dust-red-06:		(light: #F24C4E, dark: #FF6D6F),
	dust-red-07:		(light: #CE4142, dark: #FF9293),
	dust-red-08:		(light: #A12527, dark: #FFAFB0),
	dust-red-09:		(light: #7D191B, dark: #FFCCCD),
	dust-red-10:		(light: #5C0011, dark: #FFE9E9),
	// ~~~~~~ Desert ~~~~~~
	desert-01:		(light: #FEF3EE, dark: #49291A),
	desert-02:		(light: #FBDBCD, dark: #613623),
	desert-03:		(light: #F8BEA3, dark: #864B30),
	desert-04:		(light: #F5A079, dark: #AA5F3D),
	desert-05:		(light: #F38857, dark: #DB7A4E),
	desert-06:		(light: #DB7A4E, dark: #F38857),
	desert-07:		(light: #AA5F3D, dark: #F5A079),
	desert-08:		(light: #864B30, dark: #F8BEA3),
	desert-09:		(light: #613623, dark: #FBDBCD),
	desert-10:		(light: #49291A, dark: #FEF3EE),
	// ~~~~~~ Gold ~~~~~~
	gold-01:        (light: #FFFBE6, dark: #613400),
	gold-02:        (light: #FFF1B8, dark: #874D00),
	gold-03:        (light: #FFE58F, dark: #AD6800),
	gold-04:        (light: #FFD666, dark: #D48806),
	gold-05:        (light: #FFC53D, dark: #FAAD14),
	gold-06:        (light: #FAAD14, dark: #FFC53D),
	gold-07:        (light: #D48806, dark: #FFD666),
	gold-08:        (light: #AD6800, dark: #FFE58F),
	gold-09:        (light: #874D00, dark: #FFF1B8),
	gold-10:        (light: #613400, dark: #FFFBE6),
	// ~~~~~~ Yellow ~~~~~~
	yellow-01:        (light: #FEFFE6, dark: #614700),
	yellow-02:        (light: #FFFFB8, dark: #876800),
	yellow-03:        (light: #FFFB8F, dark: #AD8B00),
	yellow-04:        (light: #FFF566, dark: #D4B106),
	yellow-05:        (light: #FFEC3D, dark: #FADB14),
	yellow-06:        (light: #FADB14, dark: #FFEC3D),
	yellow-07:        (light: #D4B106, dark: #FFF566),
	yellow-08:        (light: #AD8B00, dark: #FFFB8F),
	yellow-09:        (light: #876800, dark: #FFFFB8),
	yellow-10:        (light: #614700, dark: #FEFFE6),
	// ~~~~~~ Lime ~~~~~~
	lime-01:        (light: #FCFFE6, dark: #254000),
	lime-02:        (light: #F4FFB8, dark: #3F6600),
	lime-03:        (light: #EAFF8F, dark: #5B8C00),
	lime-04:        (light: #D3F261, dark: #7CB305),
	lime-05:        (light: #BAE637, dark: #A0D911),
	lime-06:        (light: #A0D911, dark: #BAE637),
	lime-07:        (light: #7CB305, dark: #D3F261),
	lime-08:        (light: #5B8C00, dark: #EAFF8F),
	lime-09:        (light: #3F6600, dark: #F4FFB8),
	lime-10:        (light: #254000, dark: #FCFFE6),
	// ~~~~~~ Green ~~~~~~
	green-01:        (light: #F6FFED, dark: #092B00),
	green-02:        (light: #D9F7BE, dark: #135200),
	green-03:        (light: #B7EB8F, dark: #237804),
	green-04:        (light: #95DE64, dark: #389E0D),
	green-05:        (light: #73D13D, dark: #52C41A),
	green-06:        (light: #52C41A, dark: #73D13D),
	green-07:        (light: #389E0D, dark: #95DE64),
	green-08:        (light: #237804, dark: #B7EB8F),
	green-09:        (light: #135200, dark: #D9F7BE),
	green-10:        (light: #092B00, dark: #F6FFED),
	// ~~~~~~ Cyan ~~~~~~
	cyan-01:        (light: #E6FFFB, dark: #002329),
	cyan-02:        (light: #B5F5EC, dark: #00474F),
	cyan-03:        (light: #87E8DE, dark: #006D75),
	cyan-04:        (light: #5CDBD3, dark: #08979C),
	cyan-05:        (light: #36CFC9, dark: #13C2C2),
	cyan-06:        (light: #13C2C2, dark: #36CFC9),
	cyan-07:        (light: #08979C, dark: #5CDBD3),
	cyan-08:        (light: #006D75, dark: #87E8DE),
	cyan-09:        (light: #00474F, dark: #B5F5EC),
	cyan-10:        (light: #002329, dark: #E6FFFB),
	// ~~~~~~ Sky Blue ~~~~~~
	sky-blue-01:        (light: #E6F7FF, dark: #002766),
	sky-blue-02:        (light: #BAE7FF, dark: #003A8C),
	sky-blue-03:        (light: #91D5FF, dark: #0050B3),
	sky-blue-04:        (light: #69C0FF, dark: #096DD9),
	sky-blue-05:        (light: #40A9FF, dark: #1890FF),
	sky-blue-06:        (light: #1890FF, dark: #40A9FF),
	sky-blue-07:        (light: #096DD9, dark: #69C0FF),
	sky-blue-08:        (light: #0050B3, dark: #91D5FF),
	sky-blue-09:        (light: #003A8C, dark: #BAE7FF),
	sky-blue-10:        (light: #002766, dark: #E6F7FF),
	// ~~~~~~ Royal Blue ~~~~~~
	royal-blue-01:        (light: #F0F5FF, dark: #030852),
	royal-blue-02:        (light: #D6E4FF, dark: #061178),
	royal-blue-03:        (light: #ADC6FF, dark: #10239E),
	royal-blue-04:        (light: #85A5FF, dark: #1D39C4),
	royal-blue-05:        (light: #597EF7, dark: #2F54EB),
	royal-blue-06:        (light: #2F54EB, dark: #597EF7),
	royal-blue-07:        (light: #1D39C4, dark: #85A5FF),
	royal-blue-08:        (light: #10239E, dark: #ADC6FF),
	royal-blue-09:        (light: #061178, dark: #D6E4FF),
	royal-blue-10:        (light: #030852, dark: #F0F5FF),
	// ~~~~~~ Violet ~~~~~~
	violet-01:        (light: #F9F0FF, dark: #120338),
	violet-02:        (light: #EFDBFF, dark: #22075E),
	violet-03:        (light: #D3ADF7, dark: #391085),
	violet-04:        (light: #B37FEB, dark: #531DAB),
	violet-05:        (light: #9254DE, dark: #722ED1),
	violet-06:        (light: #722ED1, dark: #9254DE),
	violet-07:        (light: #531DAB, dark: #B37FEB),
	violet-08:        (light: #391085, dark: #D3ADF7),
	violet-09:        (light: #22075E, dark: #EFDBFF),
	violet-10:        (light: #120338, dark: #F9F0FF),

	// ~~~~~~~~~~~ Live editing cursor colors ~~~~~~~~~~~
	cursor-blue:	(light: #2d85fc, dark: #0a84ff),
	cursor-brown:	(light: #a2845e, dark: #aa824e),
	cursor-green:	(light: #28cd41, dark: #32d74b),
	cursor-indigo:	(light: #5856d6, dark: #5e5ce6),
	cursor-mint:	(light: #00c7be, dark: #66d4cf),
	cursor-orange:	(light: #ff9500, dark: #ff9f0a),
	cursor-pink:	(light: #ff2d55, dark: #fd3e87),
	cursor-purple:	(light: #af52de, dark: #c244f2),
	cursor-red:		(light: #ff3b30, dark: #ff453a),
	cursor-yellow:	(light: #ffcc0a, dark: #ffd60a),

	// ~~~~~~~~~~~ Pastel palette ~~~~~~~~~~~~~~~~~~~~~~~
	pastel-pink: 			(light: #E9498E, dark: #D1417F),
	pastel-pink-contrast: 	(light: #DD367E, dark: #D84785),
	pastel-red: 			(light: #DD8C94, dark: #C77F86),
	pastel-red-contrast: 	(light: #D36C76, dark: #CC8B91),
	pastel-brown: 			(light: #C18952, dark: #AA7F54),
	pastel-brown-contrast: 	(light: #B28356, dark: #BE9269),
	pastel-ginger: 			(light: #d38f70, dark: #BA7656),
	pastel-ginger-contrast: (light: #C67550, dark: #BC7C5D),
	pastel-yellow:			(light: #E8CF62, dark: #D0BA58),
	pastel-yellow-contrast:	(light: #A29535, dark: #DBC76F),
	pastel-mint:			(light: #38B78C, dark: #32A47E),
	pastel-mint-contrast:	(light: #23956F, dark: #5CBE9D),
	pastel-green:			(light: #78CE78, dark: #6CB96C),
	pastel-green-contrast:	(light: #36A336, dark: #86C686),
	pastel-violet:			(light: #9b78ce, dark: #8A6DB4),
	pastel-violet-contrast:	(light: #9271C3, dark: #A68AD0),
	pastel-navy:			(light: #4D89E8, dark: #457BD0),
	pastel-navy-contrast:	(light: #447AD0, dark: #588CDE),
	pastel-blue:			(light: #7ac4eb, dark: #6EB0D3),
	pastel-blue-contrast:	(light: #3095CB, dark: #51B3E6),

	// ~~~~~~~~~~~ Colors V2 ~~~~~~~~~~~~~~~~~~~~~~~
	informative: (light: #1890FF, dark: #1890FF),
	success: (light: #52C41A, dark: #52C41A),
	helper: (light: #B0B4C8, dark: #B0B4C8),
	warning: (light: #FAAD14, dark: #FAAD14),
	error: (light: #F24C4E, dark: #F24C4E),
	brand-07: (light: #374A9D, dark: #57A9F4),
	brand-08: (light: #051D85, dark: #57A9F4),

);

// Converts a color to a comma separated rgb string (ie: "1,2,3") so we can
// later apply custom opacities to the colors. Eg: background: rgb(var(--background-rgb), 0.5)
@function colorToRgbString($hexColor) {
	@return "#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)}";
  }

// Converts the $color map to color variables. Also makes an "rgb" version of each color.
@mixin makeColorVariables($darkOrLight) {
	@each $color, $map in $colors {
		--#{"" + $color}: #{map.get($map, $darkOrLight)};
		--#{"" + $color}-rgb: #{colorToRgbString(map.get($map, $darkOrLight))};

		// Make a class for each color to be used to color typography text
		.#{"" + $color}-typography {
			color:  var(--#{"" + $color});
		}
	}
}

.genemod-lightmode {
	@include makeColorVariables(light);
}

.genemod-darkmode {
	@include makeColorVariables(dark);
}

// Normalize a css color, which will either be a hex or an "rgba" color (ie: #1234.. or rgba(1,2,3,.5)) to a hex value
// Used to export hex values to javascript for consistency
@function colorToExportHexValue($color) {
	$first-char: str-slice("" + $color, 0, 1);
	@if $first-char == "#" {
		@return $color;
	};
	// alpha channel is reversed after this function
	$ie-hex: ie_hex_str($color);
	// flip alpha channel to the end and convert to hex
	$hex-color: unquote('#' + str-slice($ie-hex, 4) + str-slice($ie-hex, 2, 3));
	// Note: sass treats this as a string
	@return $hex-color;
}

// Export the color variables for use in js files
:export {
	@each $color, $map in $colors {
		dark-#{"" + $color}: #{colorToExportHexValue(map.get($map, dark))};
		dark-#{"" + $color}-rgb: #{colorToRgbString(map.get($map, dark))};
	}
	@each $color, $map in $colors {
		light-#{"" + $color}: #{colorToExportHexValue(map.get($map, light))};
		light-#{"" + $color}-rgb: #{colorToRgbString(map.get($map, light))};
	}
};
