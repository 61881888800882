.planFAQ {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;

	.faqList {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: 39px;
		column-gap: 48px;
	}
}

.faqField {
	display: flex;
	flex-direction: column;
	width: 344px;
	gap: 16px;
}
