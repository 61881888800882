.panelHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
	gap: 32px;
}
.panelTitle {
	> div {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	width: 89%;
	font-weight: bold !important;
}

.panelCards {
	border-radius: 8px;
}

.viewPanel {
	position: relative;
	overflow: scroll;
}

.loadingWrapper {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
}

.loading {
	z-index: 1;
	background-color: rgba(var(--layer-01-rgb), 0.5);
	pointer-events: all;
}

.loadingSpinner {
	margin-top: 200px;
}
