@use "../../Table.module.scss" as table;

$selectionDragMask_index: 2;
$dragHandle_index: 2;
$shouldBlockMask_index: 4;

.selectionDragMask {
	display: block;
	z-index: $selectionDragMask_index;
}

.selections {
	background-color: table.$freezer_cell_selected;
	border: var(--border-active-solid);
	display: flex;
	pointer-events: none;
	z-index: 99;
	margin-left: -1px;
	margin-top: -1px;

	&.isUnselect {
		background-color: rgba(var(--layer-02-rgb), 0.3);
	}
}

.dragHandle {
	content: "";
	height: 8px;
	width: 8px;
	transform: translate(-5px, -5px);
	z-index: $dragHandle_index;
	background-color: var(--border-active);
	cursor: crosshair;
	border-radius: 50%;
	position: absolute;
}
.shouldBlockMask {
	display: block;
	z-index: $shouldBlockMask_index;
}
