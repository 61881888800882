#freezer .freezer-contents {
	height: 100%;
	width: 100%;
	display: flex;
	color: var(--text-primary);
	overflow: hidden;
	justify-content: center;
	background-color: var(--layer-01);
	padding-top: 48px;

	.left {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 466px;
	}

	.right {
		display: flex;
		flex-direction: column;
		width: 60%;
		align-items: center;
	}

	.right-empty {
		display: flex;
		width: 60%;
	}
}
