.table {
	:global(.ant-table-scroll) {
		:global(.ant-table-hide-scrollbar) {
			overflow: auto !important;
		}
	}
	:global {
		.ant-table .ant-table-placeholder {
			opacity: 1;
			background-color: transparent;
			padding: 32px;
			border: none !important;
		}
	}

	:global(.ant-table-row-cell-last) {
		border-right-color: var(--border-subtle) !important;
	}

	:global(.ant-table-body) {
		padding-right: 1px;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	:global(.ant-table-body) {
		overflow: auto !important;
	}
	:global(.ant-table .ant-table-tbody > tr > td) {
		padding: 7px 16px;
	}
	:global {
		.ant-table-bordered .ant-table-thead > tr > th,
		.ant-table-bordered .ant-table-tbody > tr > td {
			border-right: var(--border-subtle-solid-v2);
		}
		.ant-table-bordered .ant-table-header > table,
		.ant-table-bordered .ant-table-fixed-left table {
			border: var(--border-subtle-solid-v2);
		}
		.ant-table
			.ant-table-fixed-left
			.ant-table-thead
			> tr:first-child
			> th:first-child {
			border-right: var(--border-subtle-solid-v2);
		}
		.ant-table-column-title {
			@media (max-width: 1919px) {
				font-size: 14px !important;
			}
		}
	}
	:global(.ant-table-header-column) {
		display: flex;
		> div {
			flex-grow: 1;
		}
	}

	.columnHeader {
		display: flex;
		flex-grow: 1;
		align-items: center;
	}

	:global(.ant-table-column-sorters) {
		width: 100%;
	}

	:global(.ant-table-column-sorter-inner) {
		width: min-content;
		margin-left: auto !important;
	}
	:global(.react-resizable) {
		position: relative;
	}
	:global(.ant-table-header-column) {
		overflow: hidden;
	}
}

.selectedRow {
	background: var(--layer-hover-01-v2) !important;
}
