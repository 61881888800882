.home-freezer-settings {
	display: flex;
	gap: 16px;
	flex-direction: column;

	.radio-buttons-cell-display {
		padding-top: 0px;
	}
}
.edit-fridge-form {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.edit-description-label {
		margin-bottom: 8px;
		color: var(--text-primary-v2);
	}

	.fridge-type-edit-container {
		color: var(--text-primary);
		width: 100%;

		.genemod-select {
			width: 100%;
		}
	}

	.is-shared-edit-container {
		margin-top: 32px;
		margin-bottom: 24px;
	}
}

.setting-container {
	margin-top: 0px;
	overflow-y: auto;
	overflow-x: hidden;
}

.home-freezer-settings .radio-buttons-cell-display {
	padding-top: 0px !important;
	margin-left: 0px !important;
}

.freezer-page-activity {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.fridge-form {
	display: flex;
	flex-direction: column;
	gap: 26px;
	.sidePanel-infoView-leftSide {
		width: 144px !important;
	}
}

.select-fields {
	display: flex;
	flex-direction: row;
	gap: 16px;
	width: 100%;
}

.sidePanel-infoView-layout {
	display: flex !important;
	align-items: center !important;
	height: auto !important;
}
