.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 5px;
	flex: 1;
	.title {
		margin-bottom: 16px;
	}
}
