.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.progressBarContainer {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.StepProgressContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	color: var(--text-tertiary);
	gap: 8px;
	pointer-events: none;
	margin-bottom: 56px;
	&:hover {
		color: var(--link-primary) !important;
		.StepProgressBar {
			background-color: var(--link-primary) !important;
		}
	}

	&__previous {
		pointer-events: visible;
		cursor: pointer;
	}

	.StepProgressBar {
		width: 100%;
		height: 3px;
	}
}
