.toolbar {
	height: 42px;
	width: fit-content;
	box-shadow: 0px 4px 15px var(--shadow-overlay);
	border-radius: 4px;
	flex-direction: row !important;
	align-items: center;
	z-index: 10 !important;
	background-color: var(--layer-popover);
	padding: 0 8px;
}

.btnGroup {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 8px;
	gap: 8px;

	&:not(:last-child) {
		border-right: 1px solid var(--border-invisible-v2);
	}
}

.textHighlightButtonContainer {
	:global(.genemod-select) {
		border: none !important;
	}
	:global(.gm__pm__colorpicker .ant-select-selection__rendered) {
		margin-top: 4px;
	}
}

.aiButtonContainer {
	background-color: var(--layer-accent-01-v2);
}

.reviseTextDropdown {
	display: flex;
	width: 332px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;

	border-radius: 4px;

	/* Light Mode/shadow-overlay */
	box-shadow: 0px 4px 15px 0px rgba(40, 43, 51, 0.2);

	padding: 16px;

	.header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	}

	.buttons {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 8px;

		width: 100%;
		background-color: unset;
	}
}
