// Modal styling
div.ant-modal-wrap.ant-modal-centered > .category-modal {
	.ant-modal-content {
		padding: 24px;

		.ant-modal-body {
			.modal-header {
				margin-bottom: 24px;
				color: var(--text-primary);
				.step-header {
					color: var(--text-secondary);
					font: var(--font-caption);
				}
			}

			.modal-form {
				max-height: 342px;
				overflow-y: scroll;
				.form-group {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;
					font: var(--font-body);

					.form-item {
						width: 48%;

						.quantity-input {
							width: 200px;
							user-select: none;
							border-radius: 2px;
							margin-bottom: 32px;

							.genemod-quantity-input {
								border-bottom-right-radius: 0;
								border-top-right-radius: 0;
							}
						}
					}
				}
			}
		}
	}
}

.ant-dropdown-menu-item-divider {
	background-color: var(--border-subtle);
}
