.gm__pm__colorpicker {
	:global(.ant-select-selection .ant-select-selection--single) {
		padding-left: 0 !important;
	}
	.ant-select-selection {
		padding: 0 2px !important;
		background-color: transparent;
		border-radius: 2px;

		&__rendered {
			margin-right: 14px;
			width: 100%;
		}
		&:hover {
			background-color: var(--layer-hover-01);
		}
	}
	.ant-select-focused .ant-select-selector,
	.ant-select-selector:focus,
	.ant-select-selector:active,
	.ant-select-open .ant-select-selector {
		border-color: none !important;
		box-shadow: none !important;
	}
	.ant-select-selection-selected-value {
		margin-right: 0 !important;
	}
	&.ant-select-focused .ant-select-selection {
		background-color: transparent;
	}

	.ant-select-arrow {
		right: 0px;
	}

	&.ant-select-focused .ant-select-selection {
		background-color: var(--current-layer-hover) !important;
		border-radius: 2px;
	}

	&__dropdown {
		padding: 4px !important;
		min-width: 104px !important;

		> div {
			width: 100% !important;
		}

		ul {
			display: flex;
			height: 100% !important;
			flex-wrap: wrap;
			padding-left: 0px !important;
		}
		li {
			height: 32px !important;
			width: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 7px !important;
			margin: 0px !important;
		}
	}
}
