.drawer {
	:global {
		.ant-drawer,
		.ant-drawer-content-wrapper,
		.ant-drawer-body {
			height: 100%;
		}
		.ant-drawer-content-wrapper {
			width: 100%;
			box-shadow: none !important;
		}
		.ant-drawer-body {
			display: flex;
			flex-direction: column;
			background-color: var(--previous-layer);
		}
		.ant-drawer-wrapper-body {
			overflow: hidden;
		}
	}
}
