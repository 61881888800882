.eventData {
	display: flex;
	cursor: pointer;
	padding: 8px;
	border-radius: 4px;

	.link {
		display: inline;
	}
	&:hover .link {
		text-decoration: underline;
	}

	&:hover {
		background-color: var(--layer-hover-01);
	}
}
.eventData__nolink {
	cursor: default;
	&:hover .link {
		text-decoration: none;
	}
}

.eventLog {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.noActivity {
		line-height: 48px;
		color: var(--text-tertiary);
	}
}
