.requestUpgradeModal {
	display: grid;
	grid-template-columns: 329px auto;
	gap: 56px;
	position: relative;
}

.features {
	margin-top: 16px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 8px;
}

.img {
	position: absolute;
	bottom: -58px;
	right: -32px;
}

.actions {
	display: flex;
	justify-content: flex-end;
}

.select {
	:global {
		.ant-select-selection {
			height: 36px !important;
			border: 1px solid var(--border-subtle) !important;
		}
	}
}

.submitted {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 631px;
}
