.details {
	padding-top: 4px;
	overflow: scroll;
}

.group {
	display: grid;
	grid-template-columns: 102px minmax(0, 1fr);
	align-items: center;
	column-gap: 56px;
	row-gap: 12px;
}
