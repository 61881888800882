$height: 36px;
$padding: 9px 16px 10px 8px;
$transition: 0.2s;

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 24px;

	&__error,
	&__noError {
		margin-bottom: 16px;
	}

	&__noBottomMargin {
		margin-bottom: 0px;
	}
}

.label {
	white-space: nowrap;
	margin-bottom: 4px;
}

.labelLeft {
	flex-direction: row;

	.label {
		line-height: $height;
		margin-right: 32px;
	}
}

.inputContainer {
	width: 100%;
	height: $height;
	border: var(--border-subtle-solid);
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	display: flex;

	input {
		flex: 1 1 auto;
		width: 100%;
	}

	input::placeholder {
		color: var(--text-placeholder);
	}

	textarea::placeholder {
		color: var(--text-placeholder);
		line-height: normal;
	}

	&__noBorder {
		border: none;
	}

	&Error {
		$paddingTop: 8px;
		padding-left: 16px;
		padding-top: $paddingTop;
		height: calc(16px + $paddingTop);
		color: var(--red);
		position: relative;
		left: -16px;
		min-width: max-content;
	}

	&__autocomplete {
		input:-webkit-autofill {
			-webkit-text-fill-color: var(--text-primary);
			caret-color: var(--text-primary);
			-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
			transition: background-color 5000s ease-in-out 0s !important;

			&:hover,
			&:focus {
				-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
			}
		}

		&.inputContainer__filled {
			input:-webkit-autofill {
				background-color: var(--layer-03) !important;
			}

			> div {
				background-color: var(--layer-03) !important;
			}
		}

		input:-webkit-autofill {
			&:hover,
			&:focus-within {
				background-color: var(--layer-03) !important;
			}
		}
	}

	&__disabled {
		pointer-events: none;
		input,
		textarea,
		> div {
			background-color: var(--action-disabled) !important;
			border-color: var(--border-subtle-v2) !important;
		}
	}

	/** Defines the stylings and the position of the bottom line */
	&::before {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 2px;
		bottom: 0;
		content: "";
		opacity: 0;
		transition: $transition;
	}
	&__noFocusUnderline::before {
		content: none;
	}

	&:focus-within {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	/** Defines the stylings of prefix and suffix */
	.prefix,
	.suffix {
		flex: 0 0 auto;
		transition: 0.2s;
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--text-primary);
	}

	.vertical {
		flex-direction: column;
	}

	.prefix {
		padding-left: 12px;
	}

	.suffix {
		padding-right: 12px;
	}

	input,
	textarea,
	> div {
		background-color: var(--current-layer-input-background);
	}
}

.content {
	flex: 1 1 100%;
	width: 100%;
}

.inputContainer__error {
	border: 1px solid var(--red);
	border-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	position: relative;

	&:focus-within {
		border: var(--border-subtle-solid);
		&:before {
			opacity: 1;
			height: 2px;
			background-color: var(--red) !important;
		}
	}

	&:before {
		opacity: 1;
		height: 2px;
		background-color: var(--red) !important;
	}
}

.input {
	border: none;
	outline: none;
	box-shadow: none !important;
	height: $height;
	transition: $transition;
	padding: $padding;
	font: var(--font-label);
	letter-spacing: 0.01px;
	position: relative;
	color: var(--text-primary);
	resize: none;

	@media (min-width: 1920px) {
		font-size: 13px/18px;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/*For FireFox*/
input[type="number"] {
	-moz-appearance: textfield;
}
