.rackCard {
	aspect-ratio: 1;
	border: 1px solid var(--border-subtle-v2);
	border-radius: 8px;
	position: relative;
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--layer-03);
	cursor: pointer;
	transition: border 0.2s ease;
	padding-top: 2rem;
	&:hover {
		border: 2px solid var(--border-hover);

		& .catOptions {
			visibility: visible;
			opacity: 1;
		}
	}
}

.rowHeader {
	height: 6px;
	width: 49px;
	background-color: var(--brand-08);
	opacity: 0.3;
	border-radius: 50px;
	position: absolute;
	top: 16px;
	left: 50%;
	transform: translateX(-50%);
}

.categoryIcon {
	position: absolute;
	top: 8px;
	left: 8px;
	opacity: 0.6;
}

.catOptions {
	position: absolute !important;
	right: 8px;
	top: 8px;
	border: 1px solid var(--border-invisible-v2);
	border-radius: 4px;
	z-index: 2;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s ease, opacity 0.2s ease;
	background-color: var(--layer-02-v2) !important;
}

.optionsIcon {
	background-color: var(--layer-02-v2) !important;
}

.content {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	text-align: center;
	margin-top: auto;
	width: 100%;
	height: 44px;
	div {
		span {
			overflow-wrap: break-word;
			white-space: break-spaces;
			-webkit-line-clamp: 2;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			line-height: 22px;
		}
	}
}

.selected {
	border: 2px solid var(--border-hover);
	position: relative;
	z-index: 1;
	box-shadow: 0 0 0 2px var(--border-subtle-v2);
	transition: box-shadow 0.2s ease;

	&:hover {
		box-shadow: 0 0 0 4px var(--border-subtle-v2);
	}
}
