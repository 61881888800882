.search-filter-container {
	margin-bottom: 24px;
	border-radius: 8px;
	border: var(--border-subtle-solid);
	box-shadow: var(--shadow-container);

	.filter-header-collapsible {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 42px;
		width: 100%;
		background-color: var(--layer-03);
		border-radius: 7px;

		.filter-label {
			padding-left: 12px;
		}

		&.opened {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.search-filter-content {
		border-top: 1px solid var(--border-subtle);
		height: 100%;
		padding: 8px;
		background-color: var(--layer-02);
		border-radius: 0px 0px 8px 8px;
	}

	.Input_noError__3JbFO {
		margin: 2px 0px;
	}

	.no-result-found {
		padding: 8px 0 12px;
		color: var(--text-secondary);
	}

	// dropdown
	.ant-select-selection.ant-select-selection--single {
		background-color: var(--layer-03);
	}

	.ant-radio-wrapper {
		margin-bottom: 5px;
		span > div {
			font: var(--font-label);
			letter-spacing: 0.01px;
		}
	}

	.search-filter-checkbox {
		display: flex;
		align-items: center;
		.checkbox-label {
			// 100% - checkbox icon width - nItem width
			width: calc(100% - 18px - 14px);
		}
	}

	.date-picker-container {
		width: 100%;
	}

	.added-date-filter {
		.date-input {
			display: flex;
			align-items: center;
			border-radius: 2px;
			height: 36px;
			padding-bottom: 4px;

			&__label {
				background-color: transparent;
				padding-right: 8px;
				height: 100%;
				display: flex;
				align-items: center;

				> div {
					width: 32px;
				}
			}

			.date-picker-container {
				flex: 1 1 auto;
			}
			.ant-calendar-picker-input {
				font: var(--font-body);
				letter-spacing: 0.01px;
				background-color: transparent;
			}
		}
	}
}

.search-bar {
	margin-bottom: 12px;

	input,
	textarea,
	> div {
		background-color: var(--layer-02) !important;
	}

	.genemod-search-container .ant-select-auto-complete .ant-input {
		padding-left: 0px !important;
	}

	.ant-select-selection__placeholder {
		margin-left: 4px !important;
	}
}
