.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.cards {
	height: calc(48px * 10);
	overflow-y: scroll;
}

.linkRowContainer {
	border-bottom: var(--border-subtle-solid);
}

.linkRow {
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.nameAndLink {
		width: 100%;
		padding: 8px;

		.nameOrLink {
			width: 363px;
		}
	}

	.selectAndCollapsible {
		display: flex;
		align-items: center;

		.selectButton {
			display: none;
		}
	}

	&:hover {
		background-color: var(--layer-hover-01);

		.selectButton {
			display: unset;
		}
	}

	.icon {
		height: 48px;
		width: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: rotate(90deg);
	}

	&__expanded {
		background-color: var(--layer-hover-01);

		.selectButton {
			display: none !important;
		}
	}
}

.formFields {
	padding: 16px;
	background-color: var(--background);

	.input {
		margin-bottom: 16px;
	}

	.row {
		display: grid;
		column-gap: 24px;
		grid-template-columns: 2fr 1fr;
	}

	.footer {
		display: flex;
		gap: 16px;
		justify-content: flex-end;
		width: 100%;
	}
}
