.themes {
	display: flex;
	align-items: center;
}

.themeToggleWrapper {
	width: 168px;
	height: 142px;
	border-radius: 8px;
	position: relative;
	background: url("./dark-theme.png");
	background-size: calc(100% + 3px);
	background-repeat: no-repeat;
	background-position: -1px -2px;
	border: var(--border-subtle-emph-solid);

	:global(.ant-radio-inner) {
		border-color: var(--fill-color) !important;
	}
	.radio {
		--fill-color: var(--border-subtle);
	}

	z-index: 0;
	cursor: pointer;
	overflow: hidden;
	.selectionArea {
		background-color: var(--layer-02);
		border-top: var(--border-subtle-solid);
		height: 42px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 9px 12px;
		position: absolute;
		bottom: 0px;
		left: 0px;
		z-index: 2;
	}
	&__lightTheme {
		background: url("./light-theme.png");
		background-size: calc(100% + 3px);
		background-position: -1px -2px;
		background-repeat: no-repeat;
	}
	&__selected {
		border: var(--border-active-emph-solid);
		transition: 0s;

		.radio {
			--fill-color: var(--border-active);
		}
	}
}
