.icon {
	height: 32px;
	aspect-ratio: 1;
	border-radius: 500px;
	background-color: var(--layer-accent-01-v2);
	display: grid;
	place-items: center;
}

.title {
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
}

.accessCard {
	display: flex;
	gap: 8px;
	margin-top: 8px;
}

.membersCount {
	background-color: var(--sky-blue-01);
	min-height: 16px;
	min-width: 16px;
	display: grid;
	place-items: center;
	border-radius: 50px;
	margin-left: -4px;
}

.titleWithCount {
	gap: 6px;
}
