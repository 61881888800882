#item-type-selector {
	.ant-select-selection__rendered {
		.ant-select-selection-selected-value {
			display: flex !important;
			align-items: center;
			gap: 8px;
		}
	}
}

.useBodyColor .ant-select-selection-selected-value {
	color: var(--text-tertiary-v2) !important;
}
