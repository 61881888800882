.freezerList {
	width: 220px;
	height: 100%;
	padding: 16px 0;
	border-right: var(--border-subtle-solid);
	overflow-y: auto;

	&__loading {
		padding: 32px;
	}
}

.freezerCard {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 48px;
	align-items: center;
	cursor: pointer;
	&__active {
		background-color: var(--layer-accent-01);
		border-right: 2px solid var(--border-active);
	}
}

.FreezerName {
	padding-left: 8px;
	margin-right: 32px;
}

.freezerIcon {
	margin-left: 30px; // to align with the modal title
}
