// General component
.listGridPanel {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 32px 24px 32px 32px;
	overflow-y: scroll;
	box-shadow: 0 2px 3px 0 var(--shadow-container-color);
	.header {
		height: 48px;
		margin-bottom: 24px;
		@media (min-width: 1920px) {
			margin-bottom: 48px;
		}
		.subheader {
			display: flex;
			align-items: center;
			width: 100%;

			.nameAndIcon {
				display: flex;
				width: calc(100% - 258px);
				align-items: center;
				margin-right: 16px;

				.storageType {
					width: 100%;
					position: relative;
					border-radius: 4px;
					height: 14px;
					overflow: hidden;
					flex-shrink: 0;
				}

				.nameCol {
					margin-right: 8px;
					margin-left: 8px;
				}
			}

			.nameAndIconA {
				display: flex;
				align-items: center;
				margin-right: 16px;

				.storageType {
					width: 100%;
					position: relative;
					border-radius: 4px;
					height: 14px;
					overflow: hidden;
					flex-shrink: 0;
				}

				.nameCol {
					margin-right: 8px;
					margin-left: 8px;
				}
			}

			.sortAndLayoutViewCol {
				margin-left: auto;
			}
		}
	}
	.content {
		width: 100%;
	}

	.flexrow {
		display: flex;
		align-items: center;
	}
}

.consumableSort {
	:global(.ant-select-selection-search) {
		background-color: var(--layer-03);
	}
}
