.toolbar {
	flex: 0 0 56px;
	width: 100%;
	display: flex;
	align-items: center;
	z-index: 11;
}

.toolbarBtn {
	background-color: transparent;
	outline: none;
	border-radius: 2px;
	height: 22px;
	width: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;

	&:hover {
		background-color: var(--current-layer-hover);
	}

	&__active:not(.toolbarBtn__disabled) {
		path {
			fill: var(--accent-subtle) !important;
		}
	}

	&__active {
		background-color: var(--current-layer-hover);
	}

	&__disabled {
		opacity: 0.6;
		pointer-events: none;
	}
}

.toolbarDividerContainer {
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.toolbarDivider {
	width: 1px;
	height: 100%;
	margin: 0px;
	background-color: var(--border-invisible);
	border: none;
}
