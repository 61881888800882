@import url("https://use.typekit.net/maa1ivk.css");
@import "./common/styles/Colors.scss";

:root {
	/* ----------------------------------------------------------------- */
	// Scrollbar color
	--scrollbar-color: rgba(143, 157, 157, 0.24);

	// Font weights
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
	--font-weight-heavy: 800;

	// Font families
	--font-family-lato: "lato", sans-serif;

	// Shadows
	--box-shadow-default: 0px 3px 6px #00000029;
	--box-shadow-left: -3 3px 6px rgba(0, 0, 0, 0.16);
	--drop-shadow-l2: 0px 2px 6px 0px rgba(21, 25, 45, 0.12);

	// Font sizes
	--font-size-h1: 40px/40px;
	--font-size-h2: 30px/38px;
	--font-size-h3: 26px/32px;
	--font-size-h4: 20px/28px;
	--font-size-h5: 14px/24px;
	--font-size-list-body: 14px;
	--font-size-list-header1: 16px/20px;
	--font-size-list-header2: 14px/16px;
	--font-size-small-print: 12px/12px;
	--font-size-paragraph1: 16px/22px;
	--font-size-paragraph2: 14px/20px;

	--letter-spacing-header1: 0.04px;
	--letter-spacing-header3: 0.03px;
	--letter-spacing-small-print: 0.02px;
	@media (max-width: 1919px) {
		--font-size-h1: 32px/32px;
		--font-size-h2: 24px/38px;
		--font-size-h3: 20px/32px;
		--font-size-h4: 16px/28px;
		--font-size-list-header1: 14px/20px;
		--font-size-list-header2: 12px/16px;
		--font-size-list-body: 12px;
		--font-size-small-print: 10px/16px;
		--font-size-paragraph1: 14px/22px;
		--font-size-paragraph2: 12px/20px;

		--font-list-header2: var(--font-weight-semibold)
			var(--font-size-list-header2) var(--font-family-lato);
		--font-h5: var(--font-weight-medium) var(--font-size-h5)
			var(--font-family-lato);

		--letter-spacing-header1: 0.03px;
		--letter-spacing-header2: 0.02px;
	}

	/** New fonts*/
	--font-largeTitle: var(--font-weight-semibold) var(--font-size-largeTitle)
		var(--font-family-lato);
	--font-title: var(--font-weight-regular) var(--font-size-title)
		var(--font-family-lato);
	--font-headline: var(--font-weight-regular) var(--font-size-headline)
		var(--font-family-lato);
	--font-subheadline: var(--font-weight-medium) var(--font-size-subheadline)
		var(--font-family-lato);
	--font-body: var(--font-weight-regular) var(--font-size-body)
		var(--font-family-lato);
	--font-label: var(--font-weight-regular) var(--font-size-label)
		var(--font-family-lato);
	--font-caption: var(--font-weight-medium) var(--font-size-caption)
		var(--font-family-lato);
	--font-size-largeTitle: 22px/38px;
	--font-size-title: 20px/32px;
	--font-size-headline: 16px/26px;
	--font-size-subheadline: 14px/22px;
	--font-size-body: 14px/24px;
	--font-size-label: 13px/18px;
	--font-size-caption: 11px/16px;
	@media (min-width: 1920px) {
		--font-size-largeTitle: 26px/42px;
		--font-size-title: 22px/34px;
		--font-size-headline: 19px/28px;
		--font-size-subheadline: 16px/28px;
		--font-size-body: 16px/26px;
		--font-size-label: 14px/18px;
		--font-size-caption: 12px/16px;
	}

	--regular-v2: 400 14px/22px var(--font-family-lato);
	--headline1-v2: 500 38px/46px var(--font-family-lato);
	--headline2-v2: 500 30px/40px var(--font-family-lato);
	--headline3-v2: 500 24px/32px var(--font-family-lato);
	--headline4-v2: 500 20px/28px var(--font-family-lato);
	--headline5-v2: 500 16px/24px var(--font-family-lato);
	--small-v2: 400 12px/18px var(--font-family-lato);
	--footnote-v2: 400 12px/14px var(--font-family-lato);
	--caption-v2: 400 10px/12px var(--font-family-lato);

	--disabled-opacity: 0.68;
	--navbar-height: 48px;
	--sidebar-width: 56px;
	--sidebar-shadow-width: 54px;
	/** Freezer only colors */
	--ui_level01: #d4dade;
	--ui_level01_hover: #ced2d6;
	--ui_level02: #efeff0;
	--ui_level02_hover: #e1e1e3;
	--ui_level02_hover02: #dbdbdc;
	--freezer_selected_cell: #d8ebf0; //color for selected box
	--freezer_focused_cell: #cbe7f1; //color for focused box
	--freezer_accent: #91a6b4;
	--freezer_scrollbar_bg: #87878d4d;
	--freezer_scrollbar_border: #d9d9d9;
}

// Declaring global variables that use things from Colors.scss do not get the right color values if we
// declare them in :root, putting them below fixes this.
.genemod-globals-with-color {
	/** Borders */
	--border-subtle-width: 1px;
	--border-subtle-emph-width: 2px;
	$border_subtle: 1px var(--border_subtle);
	$border_subtle_emph: 2px var(--border_subtle);
	--border-subtle-solid: var(--border-subtle-width) solid var(--border-subtle);
	--border-subtle-solid-v2: var(--border-subtle-width) solid
		var(--border-subtle-v2);
	--border-invisible-solid: var(--border-subtle-width) solid
		var(--border-invisible);
	--border-invisible-solid-v2: var(--border-subtle-width) solid
		var(--border-invisible-v2);
	--border-subtle-emph-solid: var(--border-subtle-emph-width) solid
		var(--border-subtle);

	--border-active-solid: var(--border-subtle-width) solid var(--border-active);
	--border-active-emph-solid: var(--border-subtle-emph-width) solid
		var(--border-active);

	/** Box shadow */
	--shadow-container: 0px 4px 10px var(--shadow-container-color); // shadow-primary in specs
	--shadow-container-secondary: 0px 2px 3px var(--shadow-container-color); // shadow-secondary in specs
	--shadow-text-and-border: 0px 3px 6px var(--shadow-text-and-border-color);
	--platform-menu-inner-shadow: inset -3px 0px 12px 0px var(--platform-menu-shadow-color);
	--box-card-handle-shadow: 0px 2px 3px 0px
		var(--box-card-handle-shadow-color);

	/** Default layer input background if we are not inside of any layer system components */
	--current-layer-input-background: var(--field-02);
	--current-layer-input-background-v2: var(--field-01);
}

.genemod-darkmode {
	/** Search filter */
	--search_filter: #327ac3;
	--search_filter_hover: #4173a5;

	@extend .genemod-globals-with-color;
}

.genemod-lightmode {
	/** Search filter */
	--search_filter: #3a9cfd;
	--search_filter_hover: #61a3e6;

	@extend .genemod-globals-with-color;
}

html,
body,
#root {
	height: 100%;
	overflow: auto;
	width: 100%;
	min-width: 1280px;
	margin: 0;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: var(--text-primary);
	}

	h1 {
		font: var(--font-largeTitle);
	}

	h2 {
		font: var(--font-title);
		letter-spacing: 0.06px;
	}

	h3 {
		font: var(--font-headline);
		letter-spacing: 0.02px;
	}

	h4 {
		font: var(--font-subheadline);
		letter-spacing: 0.02px;
	}

	h5 {
		font: var(--font-body);
		letter-spacing: 0.01px;
	}

	h6 {
		font: var(--font-body);
		letter-spacing: 0.01px;
	}

	p {
		font: var(--font-body);
		letter-spacing: 0.01px;
		color: var(--text-primary);
	}
}

// width
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-corner {
	color: transparent;
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: var(--layer-hover-01);
	border: var(--border-subtle-solid);
	border-radius: 6px;
}

.hover-opacity {
	opacity: 0.9;
	transition: 0.2s;
	&:hover {
		opacity: 1;
	}
}

@keyframes fadeOutBorderAndShadow {
	0% {
		border: 1px solid
			var(--borders-lines-border-active, var(--border-active));
		box-shadow: 0px 0px 8px 4px rgba(5, 29, 133, 0.2);
	}
	100% {
		border: 1px solid transparent;
		box-shadow: var(--shadow-container);
	}
}

.isNewCardLikeComponent {
	border: 1px solid var(--borders-lines-border-active, var(--border-active));
	box-shadow: 0px 0px 8px 4px rgba(5, 29, 133, 0.2);
	animation: fadeOutBorderAndShadow 0.4s forwards;
	animation-delay: 3s;
}

@keyframes fadeOutBackground {
	0% {
		background-color: var(--layer-hover-01);
	}
	100% {
		background-color: transparent;
	}
}

.isNewListLikeComponent {
	background-color: var(--layer-hover-01);
	animation: fadeOutBackground 0.4s forwards;
	animation-delay: 3s;
}
