@use "common/styles/mixins";

.textarea,
.input {
	border-radius: 4px;
	max-width: 100%;
	padding: 4px 8px;
	outline: none;
	border: 0px;
	min-width: 60px;
	background-color: transparent;

	// Keep the font size consistent across input and div
	font-weight: inherit;
	letter-spacing: inherit;
	color: var(--text-secondary-v2);
}

.inputContainer {
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: var(--layer-hover-01);
	position: relative;
	// Add invisible border to prevent shifting once we are focused on the input
	border: 1px solid transparent;

	&.error.error {
		border: 1px solid var(--red);
	}

	&.lightTheme {
		background-color: white;
		border-color: var(--border-subtle);
	}

	&:not(.error):not(.hideBorder):focus-within {
		border: 1px solid var(--button-primary);
	}

	&:not(.error).hideBorder:focus-within {
		border: 0px;
		// make up for the border width
		padding: 1px;
	}

	.arrowContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-left: 0;
		border-radius: 4px;
		position: absolute;
		right: 4px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.textarea {
	resize: none;
}

.label {
	border-radius: 4px;
	padding: 4px 8px;
	transition: background-color 0.2s ease;
	white-space: pre-wrap;
	overflow-wrap: break-word;

	// Add invisible border to prevent shifting once we are focused on the input
	border: 1px solid transparent;
}

.label.withLinks {
	@include mixins.linkStyle();
}

.hover {
	cursor: pointer;

	&:hover {
		background-color: var(--layer-hover-01);
	}
}

.inputContainerError {
	position: relative;
	min-width: max-content;
	padding-left: 16px;
	left: -12px;
}

.priceClickToEditContainer {
	display: flex;
	margin-left: -8px;
}

.selectClickToEditContainer {
	display: flex;
	align-items: center;
	gap: 12px;
	:global(.genemod-select-container) {
		left: -8px;
		:global(.genemod-select) {
			:global(.ant-select-selection) {
				background-color: var(--layer-03);
				padding: 6px 0 6px 8px;
			}
		}
	}
}

.confirmAndCancelContainer {
	display: flex;
	align-items: center;
	gap: 3px;

	position: absolute;
	right: 0;
	z-index: 3;
	bottom: -35px;

	.confirm,
	.cancel {
		width: 32px;
		height: 32px;
		border: var(--border-subtle-solid);
		border-radius: 4px;
		display: flex;
		align-items: center;
	}

	.confirm {
		background-color: var(--button-primary);
	}

	.cancel {
		background-color: var(--layer-02);
	}
}

.quantityClickToEdit {
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
}

.confirmAndCancelDisabled {
	&:disabled {
		background-color: var(--field-disabled);
	}
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lightBackground {
	background-color: var(--layer-02-v2);
}
