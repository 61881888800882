.checkboxContainer {
	display: inline;
	> label > span > div {
		display: inline;
	}
	:global(.ant-checkbox-inner) {
		margin: 8px;
		border-radius: 2px !important;
		background-color: var(--layer-01);
		border: 1px solid var(--border-subtle-v2);
	}

	:global(.ant-checkbox-checked .ant-checkbox-inner) {
		background-color: var(--action-default);
		border-color: var(--action-default);
	}
	:global(.ant-checkbox-indeterminate .ant-checkbox-inner::after),
	:global(
			.ant-checkbox-checked:not(.ant-checkbox-disabled)
				.ant-checkbox-inner
		) {
		background-color: var(--action-default);
		border-color: var(--action-default);
	}
	:global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
		border-color: var(--action-hover) !important;
	}
	:global(.ant-checkbox-wrapper:hover .ant-checkbox:after) {
		border-color: var(--action-hover) !important;
	}
	:global(.ant-checkbox:hover.ant-checkbox:after) {
		border-color: var(--action-hover) !important;
	}
	:global(.ant-checkbox:hover .ant-checkbox-inner) {
		border-color: var(--action-hover) !important;
	}
	:global(.ant-checkbox-checked:hover .ant-checkbox-inner) {
		border-color: var(--action-default) !important;
		background-color: var(--action-default) !important;
	}
	:global(.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner) {
		border-color: var(--action-default) !important;
		background-color: var(--action-default) !important;
	}
	:global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner) {
		border-color: var(--border-subtle-v2) !important;
		background-color: var(--action-disabled) !important;
	}
	:global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner) {
		border-color: var(--border-subtle-v2) !important;
		background-color: var(--action-disabled) !important;
	}
	:global(.ant-checkbox-wrapper-disabled:hover .ant-checkbox-inner) {
		border-color: var(--border-subtle-v2) !important;
	}
	:global(
			.ant-checkbox-indeterminate.ant-checkbox-disabled
				.ant-checkbox-inner:after
		) {
		border-color: var(--border-subtle-v2) !important;
		background-color: var(--text-disabled) !important;
	}
	:global(.ant-checkbox-input:focus-visible) {
		outline: 4px dashed darkorange;
	}
}
