.ant-notification-bottom {
	transform: translateX(0px) !important;
}
.ant-notification-notice {
	padding: 13px 16px !important;
	margin: 0 !important;
	width: auto !important;
}
.ant-notification-notice-close {
	position: absolute !important;
	top: 15px !important;
	right: 8px !important;
}
.notification-success,
.notification-error,
.notification-experiment {
	width: fit-content;
	height: 48px;
	background-color: var(--layer-popover);
	box-shadow: var(--shadow-container);
	// Aligns icon/message with close icon
	display: flex;
	align-items: center;
	padding: 0 16px;
	margin-left: 64px; // 56px (width of left nav) + 8px
	margin-bottom: 8px;
	border-radius: 2px;

	.ant-notification-notice-icon {
		margin-left: 0px;
		position: relative;

		& svg {
			height: 16px;
			width: 16px;
		}
	}
	.ant-notification-notice-with-icon {
		.ant-notification-notice-message {
			margin: 0px;
		}
	}
	.ant-notification-notice-content {
		> div {
			display: flex;
			align-items: center;
			gap: 12px;
		}
		.ant-notification-notice-message {
			font-family: var(--font-family-lato) !important;
			font-size: 14px !important;
			font-weight: 400 !important;
			line-height: 22px !important;
			letter-spacing: 0em !important;
			text-align: left !important;
			letter-spacing: 0.01px;
			line-height: normal;
			white-space: nowrap;
			margin-inline-start: 30px !important;
			margin-bottom: 0px !important;
			padding: 0px !important;
			color: var(--text-secondary);
		}
	}

	.ant-notification-notice-close {
		margin-left: 0px;
		right: 12px;
		.ant-notification-close-x {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 24px;
			width: 24px;
			g > line {
				stroke: var(--background) !important;
			}
		}
	}
	.ant-spin-container {
		position: absolute;
		right: 21px;
		bottom: -4px;
	}
}

.notification-error,
.notification-experiment {
	.ant-notification-notice-message {
		padding-left: 32px;
	}
	.ant-notification-notice-icon {
		display: flex;
		height: 24px;
		width: 24px;
		align-items: center;
		justify-content: center;
		margin-left: 0px;
	}

	.ant-notification-notice-description:empty {
		margin-left: 16px;
	}
}

.ant-notification {
	pointer-events: none;
	.ant-notification-notice {
		pointer-events: visible;
	}
}

.ant-notification-bottom {
	.ant-notification-fade-appear.ant-notification-fade-appear-active {
		animation-name: myFadeInFromBottom;
		animation-play-state: running;
	}

	@keyframes myFadeInFromBottom {
		0% {
			margin-bottom: -100%;
			opacity: 0;
		}

		100% {
			margin-bottom: 0;
			opacity: 1;
		}
	}
}
