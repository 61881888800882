@use "../Input/Input.module.scss" as GenemodInputStyle;
$primary-text-color: var(--text-primary);
$primary-link-color: var(--link-primary);
$transparent-background-color: transparent;

.datePickerContainer {
	width: 100%;
	:global {
		//for applying styles of date-picker Select
		@import "~antdv4/lib/date-picker/style/index";

		.ant-picker {
			width: 100%;
			height: GenemodInputStyle.$height;
			border-radius: 4px;
			padding: 0;
			border: var(--border-subtle-solid);
			background-color: var(--layer-03);

			&.ant-picker-focused {
				border: var(--border-subtle-solid);
				box-shadow: none;
			}

			&:focus-within {
				border: var(--border-subtle-solid);
				outline: none;

				> input {
					outline: none;
				}
			}

			&::before {
				opacity: 1;
				background-color: var(--border-active);
			}

			.ant-picker-input {
				//style of input field
				> input {
					height: 100%;
					transition: GenemodInputStyle.$transition;
					border: none;
					padding: GenemodInputStyle.$padding;
					outline: none;
					position: relative;
					color: var(--text-primary);
				}
				> input::placeholder {
					color: var(--text-ghost);
				}
				//style of clear icon
				.ant-picker-clear {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
					width: 18px;
					height: 18px;
					margin-right: 8px;
					color: var(--text-secondary);
				}
			}
		}
	}

	:global {
		.ant-picker {
			background-color: var(--layer-02);
		}
	}

	/** Hide the border when the input field is filled with values */
	&__filled {
		.ant-picker {
			border: 1px solid transparent !important;
		}
	}
	&__error {
		.ant-picker {
			border: 1px solid var(--red) !important;
			border-radius: 4px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;

			&:before {
				opacity: 1;
				height: 2px;
				background-color: var(--red);
			}

			&:focus-within {
				&::before {
					background-color: var(--red) !important;
				}
			}
		}
	}
}

// style of datePicker panel
.dropdownClass {
	:global {
		.ant-picker-header {
			margin-top: 8px;
			height: 32px;
			border-bottom: none;
			button {
				line-height: 32px;
				color: $primary-text-color;
				&:hover {
					color: $primary-text-color;
				}
			}

			.ant-picker-header-super-prev-btn,
			.ant-picker-header-super-next-btn {
				display: none;
			}

			.ant-picker-header-prev-btn,
			.ant-picker-header-next-btn {
				display: flex;
				align-items: center;
			}

			.ant-picker-header-prev-btn {
				padding-left: 10px;
			}

			.ant-picker-header-next-btn {
				padding-right: 14px;
			}

			.ant-picker-header-view {
				line-height: 32px;
				color: $primary-text-color;
				font: var(--font-subheadline);
			}
		}

		.ant-picker-body {
			padding: 0px 12px;
		}

		.ant-picker-footer {
			border-top: none;
			.ant-picker-today-btn {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 18px;
				color: $primary-link-color;
				font: var(--font-label);
				font-weight: var(--font-weight-semibold);
			}
		}

		.ant-picker-panel-container {
			background-color: var(--layer-02);
			box-shadow: none;
			th {
				color: var(--text-secondary);
				font: var(--font-caption);
			}

			.ant-picker-content {
				.ant-picker-cell {
					color: $primary-text-color;
					font: var(--font-label);
					background-color: $transparent-background-color;
					padding: 3.5px 0px;

					&::before {
						background-color: $transparent-background-color;
					}

					&:not(.ant-picker-cell-in-view) {
						visibility: hidden;
					}

					&.ant-picker-cell-selected,
					&.ant-picker-cell-in-view.ant-picker-cell-today {
						.ant-picker-cell-inner {
							border-radius: 50%;
							border: 1px solid $primary-link-color;
						}
					}

					.ant-picker-cell-inner {
						transition: none;
						line-height: 21px;

						&::before {
							border: none;
							border-radius: 50%;
						}
					}

					&.ant-picker-cell-disabled .ant-picker-cell-inner {
						color: var(--text-tertiary);
					}
				}

				.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
						.ant-picker-cell-range-start
					):not(.ant-picker-cell-range-end):not(
						.ant-picker-cell-range-hover-start
					):not(.ant-picker-cell-range-hover-end)
					.ant-picker-cell-inner {
					background-color: $primary-link-color;
					border-radius: 50%;
					border: 1px solid $primary-link-color;
					color: var(--background);
				}

				.ant-picker-cell-in-view.ant-picker-cell-selected
					.ant-picker-cell-inner {
					background-color: $primary-link-color;
					color: var(--background);
				}
			}
		}
	}

	&__exceptDatePicker {
		:global {
			.ant-picker-header {
				.ant-picker-header-super-prev-btn,
				.ant-picker-header-super-next-btn {
					visibility: visible;
					margin-left: 18px;
					margin-right: 18px;
				}
			}

			.ant-picker-cell-inner {
				border-radius: 0;
			}
		}
	}
}

.hideDateFooter {
	display: flex;
	justify-content: space-between;
	padding: 10px 12px;
}

.hideFooter {
	:global(.ant-picker-ranges) {
		display: none;
	}
}

.buttons {
	color: var(--action-default) !important;
}

.okPanel {
	border-top: 1px solid var(--border-invisible);
	display: flex;
	justify-content: space-between;
	padding: 9px 12px;
}
