.genemod-select__editor-toolbar {
	.ant-select-selection {
		background-color: transparent;
		padding: 4px;

		&__rendered {
			margin-right: 12px;
		}
	}

	.ant-select-arrow {
		right: 4px;
	}
	&.ant-select-focused {
		border: none !important;
	}
	&-menu {
		padding: 4px;
		ul {
			display: flex;
			padding-left: 0px !important;
		}
		li {
			width: 24px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 4px !important;

			&:not(:first-child) {
				margin-left: 2px;
			}
		}
	}
}
.genemod-workspace-table-wrapper {
	max-width: 744px;
	width: 744px;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-right: 32px;
	position: relative;

	.toolbar {
		width: 100% !important;
		margin-right: 2px;
		height: 48px;
		position: absolute;
		top: -52px;
		border: solid 0.6px var(--border-subtle);
		box-shadow: var(--shadow-container);
		border-radius: 2px;
		background-color: var(--layer-03);
		&__hidden {
			display: none;
		}
	}
}
.genemod-workspace-table-container {
	max-width: 744px;
	width: 744px;
	overflow-x: auto;
	&::-webkit-scrollbar-thumb {
		border: solid 1px var(--scrollbar-color);
	}
	&::-webkit-scrollbar {
		height: 6px;
		width: 18px;
	}

	.workspace-table {
		min-width: 744px;

		th,
		td {
			text-align: center;
			border: solid 1px var(--layer-03);
		}

		td {
			padding: 8px;
			min-width: 88px;
			min-height: 32px;
			background-color: var(--layer-01);
		}

		th {
			background-color: var(--layer-03);
			height: 32px;
			min-width: 32px;
		}

		tr {
			min-height: 32px;
		}

		thead {
			th {
				&:first-child {
					width: 32px;
				}

				&:not(:first-child) {
					min-width: 88px;
					padding: 12px 24px;
				}
				font: var(--font-label);
				letter-spacing: 0.01px;
				color: var(--text-secondary);
			}

			> tr > th:first-child {
				position: sticky;
				left: 0;
				background-color: var(--layer-03);
				z-index: 10;
			}
		}

		tbody {
			> tr > th {
				position: sticky;
				left: 0;
				background-color: var(--layer-03);
				z-index: 10;
				font: var(--font-label);
				letter-spacing: 0.01px;
				color: var(--text-secondary);
			}
		}
	}

	.workspace-table-title {
		display: flex;
		align-items: center;
		padding-left: 8px;
		padding-right: 16px;
		background-color: var(--layer-03);
		border: var(--border-subtle-solid);
		color: var(--text-secondary);
		height: 32px;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		span {
			white-space: pre;
		}
	}
}
