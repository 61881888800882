.collapsibleCreatePanel {
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 16px;
}
.panelHeader {
	display: flex;
	padding-bottom: 24px;
	justify-content: space-between;
	align-items: center;
	border-bottom: var(--border-subtle-solid);
	padding: 32px;
}

.container {
	padding: 32px 0;
	padding-top: 0;
}
