$large-border-radius: 4px;
$small-border-radius: 2px;

.genemod-select-container {
	display: flex;
	flex-direction: column;
}

.genemod-select {
	border: 1px solid transparent;
	border-radius: $large-border-radius;

	.ant-select-search__field {
		width: auto;
	}

	.ant-select-selection {
		background: transparent;
		border-radius: $large-border-radius;
		height: 48px;
		padding: 6px 8px 6px 16px;
		border: none;
		color: var(--text-primary);
		display: flex;
		align-items: center;
		box-shadow: none;
		background-color: var(--layer-03);
		pointer-events: all;
		cursor: pointer;
	}

	&__isStrokeIcon {
		.ant-select-selection {
			&[aria-expanded="true"] {
				path,
				circle {
					fill: none !important;
					stroke: var(--link-primary) !important;
				}
			}
		}
	}

	.ant-select-selection__rendered {
		width: calc(100% - 18px);
		margin-left: 0;
		line-height: normal;
	}

	&.ant-select-focused {
		border-radius: $large-border-radius;
		border: 1px solid transparent;
	}

	.ant-select-arrow {
		color: var(--text-primary);
		top: 0;
		margin-top: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.ant-select-selection-selected-value {
		font: var(--font-label);
		color: var(--text-secondary);
		letter-spacing: 0.01px;
		margin-right: 8px;
		line-height: 18px;
	}

	&__isSmall {
		border-radius: 2px;
		.ant-select-selection {
			height: 24px;
			border-radius: $small-border-radius;
		}
		&.ant-select-focused {
			border-radius: $small-border-radius;
		}
	}

	/** Select components displayed along with Input component */
	&__isInput,
	&__error {
		width: 100%;
		border: var(--border-subtle-solid);
		.ant-select-selection {
			width: 100%;
			height: 36px;
			background-color: var(--layer-03);
		}
		.ant-select-selection__placeholder {
			font: var(--font-body);
			line-height: 24px;
			color: var(--text-tertiary) !important;
			letter-spacing: 0.01px;
		}
		.ant-select-selection-selected-value {
			font: var(--font-body);
			letter-spacing: 0.01px;
		}
		&::before {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 2px;
			bottom: 0;
			content: "";
			opacity: 0;
		}
		&.ant-select-focused {
			border: 1px solid var(--border-subtle) !important;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			&::before {
				opacity: 1;
				background-color: var(--border-active);
			}
			.ant-select-selection-selected-value {
				color: var(--text-primary);
			}
			.ant-select-arrow {
				path {
					fill: var(--text-secondary);
				}
			}
		}
		&.ant-select-focused .ant-select-selection {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	&__error {
		border: 1px solid var(--red);
	}

	.ant-select-selection {
		width: 100%;
		background-color: var(--current-layer-input-background);
	}

	/** No background by default & blue background on hover */
	&__noBackground {
		border: none !important;
		.ant-select-selection {
			background-color: transparent;
			padding-left: 8px;
			padding-right: 0px;
			height: 24px;
			&:hover {
				background-color: var(--layer-hover-01);
				border-radius: 4px;
			}
		}
		&.ant-select-focused .ant-select-selection {
			background-color: var(--layer-hover-01);
			border-radius: 4px;
		}
	}
}

.genemod-select-dropdown {
	padding: 8px 0px;
	border-radius: $large-border-radius;
	background-color: var(--layer-03);
	.ant-select-dropdown-menu-item,
	.ant-select-dropdown-menu-item-group-title {
		background-color: var(--layer-03);
		color: var(--text-primary);
		height: 48px;
		padding: 14px 16px;
		font: var(--font-label);
		letter-spacing: 0.01px;

		&-active,
		&-active:not(.ant-select-dropdown-menu-item-disabled) {
			background-color: var(--layer-hover-03);
		}
	}

	.ant-select-dropdown-menu-item-group-title {
		padding-left: 8px;
	}

	.ant-select-dropdown-menu {
		padding: 0;
		box-shadow: var(--shadow-container);
		max-height: 256px;
	}

	.ant-select-dropdown-menu-item-selected {
		background-color: var(--layer-03) !important;

		&:hover {
			background-color: var(--layer-hover-03) !important;
		}
	}

	&__isSmall {
		padding: 4px 0px;
		border-radius: $small-border-radius;

		.ant-select-dropdown-menu-item,
		.ant-select-dropdown-menu-item-group-title {
			display: flex;
			align-items: center;
			height: 42px;
			line-height: 42px;
			padding: 11px 16px;
			font: var(--font-label);
			letter-spacing: 0.01px;
		}
	}

	&__isInput,
	&__error {
		padding: 4px 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border: var(--border-subtle-solid);
		.ant-select-dropdown-menu-item,
		.ant-select-dropdown-menu-item-group-title {
			height: 42px;
			padding: 12px 16px;
		}
	}

	/**Styling for SelectedOpt*/
	.selected-option-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: var(--layer-hover-03);
}
