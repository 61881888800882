.informationView {
	height: 100%;
	flex: 0 0 37%;
	border-radius: 16px;
	overflow: hidden;
	position: relative;
	background-color: var(--layer-accent-01-v2);
	padding: 24px;
}

.loadingWrapper {
	height: 100%;
	:global {
		.ant-spin.ant-spin-spinning {
			max-height: 100%;
		}
	}
}

.itemInfoPanel {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
}
