$indicator-dims: 48px; // size of the lock icon
.hoverContainer {
	border-radius: 10px;
	transition: box-shadow 0.2s ease;
	&:hover,
	&__focused {
		// reduce 1px for each paddings because border is 1px increased
		box-shadow: 0 0 0 4px var(--border-subtle);
	}
}
.container {
	height: 96px;
	/** Have 3 freezer cards in a row with gap of 24px */
	// width: calc((100% - 48px) / 3);
	background-color: var(--layer-02);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	position: relative;
	// box-shadow: var(--shadow-container);
	border: 1px solid transparent;
	box-shadow: 0px 2px 6px 0px rgba(21, 25, 45, 0.12);
	transition: border 0.2s ease;

	// &:nth-child(3n + 2) {
	// 	margin: 0 24px;
	// }
	&:hover,
	&__focused {
		// reduce 1px for each paddings because border is 1px increased
		// border-radius: 10px;
		// border: 4px solid rgba(5, 29, 133, 0.1);
		// box-shadow: 0px 2px 6px 0px rgba(21, 25, 45, 0.12);
		border-radius: 8px;
		border: var(--border-subtle-emph-width) solid var(--border-hover);
		box-shadow: 0px 2px 6px 0px rgba(21, 25, 45, 0.12);
		.kabob {
			opacity: 1;
			transition: opacity 0.15s;
			:global(.ant-dropdown-trigger) {
				transform: rotate(90deg);
			}
		}
	}
}

.noHoverEffect {
	&:hover {
		cursor: not-allowed;
		background-color: transparent;
	}
}

.inactive {
	background-color: transparent;
	border: var(--border-subtle-emph-width) solid var(--button-disabled);
	&:hover {
		border: var(--border-subtle-emph-width) solid var(--button-disabled);
	}
	@extend .noHoverEffect;
}

.content {
	display: flex;
	align-items: flex-start;
	width: 100%;
	cursor: pointer;
	padding: 24px 12px 24px 24px;
	&:hover,
	&__focused {
		// reduce 1px for each paddings because border is 1px increased
		// border-radius: 8px;
		// border: var(--border-subtle-emph-width) solid var(--border-hover);
		// box-shadow: 0px 2px 6px 0px rgba(21, 25, 45, 0.12);
		.kabob {
			opacity: 1;
			transition: opacity 0.15s;
			:global(.ant-dropdown-trigger) {
				transform: rotate(90deg);
			}
		}
	}
}

.shared-locked-indicator {
	width: $indicator-dims;
	min-width: $indicator-dims;
	height: $indicator-dims;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 24px;
	background-color: var(--layer-accent-01-v2);
	border-radius: 50%;
}

.freezer-details {
	position: absolute;
	/* Minus the icon size and gap */
	width: 100%;
	/* Make sure the icon and freezer name is always aligned */
	transform: translateY(-5px);
}

.freezer-name {
	/* Leave 16px margin b/w the name and the dropdown */
	max-width: 86%;
}

.kabob {
	opacity: 0;
	position: absolute;
	top: 16px;
	right: 12px;
}
div[class*="hoverEffect"]:hover {
	transition: none !important;
	border-radius: 4px !important;
	border: 1px solid var(--layer-hover-03-v2) !important;
	background-color: var(--layer-03-v2) !important;
}

.cardIcons {
	& div {
		transform: none;

		& svg {
			height: auto;
			width: auto;
		}
	}
}

.detailsContainer {
	position: relative;
	width: 100%;
}
