.genemodLabel {
	&:global(.ant-tag) {
		position: relative;
		background: var(--layer-accent-01);
		color: var(--text-primary);
		border: none;
		border-radius: 2px;
		height: 24px;
		padding: 3px 8px 3px 8px;
		display: inline-block;
		align-items: center;
		justify-content: center;
		font: var(--font-label);
		margin-right: 0px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		&:hover {
			background: var(--layer-hover-01);
			color: var(--text-primary);
			opacity: 1;
			svg > path {
				fill: var(--text-primary);
			}
		}
		:global {
			.ant-tag-close-icon {
				position: absolute;
				right: 4px;
			}
		}
	}
	&__closable {
		&:global(.ant-tag) {
			padding-right: 24px;
		}
	}
}

.genemodLabelNoElipsis {
	&:global(.ant-tag) {
		overflow: unset;
		text-overflow: unset;
	}
}

.genemodNewLabelContainer {
	&:global(.ant-select-auto-complete.ant-select) {
		height: 24px;
		border: 1px solid var(--layer-03);
		border-radius: 2px;
		display: flex;
		align-items: center;
		&:global(.ant-select-focused) {
			border: 1px solid var(--button-primary);
		}
		:global {
			.ant-input {
				height: 24px;
				max-width: 170px;
				border: none;
				padding: 3px 32px 3px 6px;
				color: var(--text-primary);
				font-family: var(--font-family-lato);
				&:focus {
					box-shadow: none;
					border: none;
				}
			}
			.ant-select-search,
			.ant-select-selection,
			.ant-select-search__field__wrap {
				height: 24px;
				line-height: 24px;
				background-color: transparent;
			}
			.ant-select-clear {
				position: absolute;
				right: 8px;
				background: transparent;
				color: var(--text-tertiary);
				opacity: 1;
			}
		}
	}

	:global(.ant-select-clear) {
		top: 50% !important;
		transform: translateY(-50%);
	}
}
.genemodLabelDropdown {
	&:global(.ant-select-dropdown) {
		padding: 4px 0 !important;
		border-radius: 4px;
		border: var(--border-subtle-solid);
		box-shadow: var(--shadow-container);
		background-color: var(--layer-03);
		min-width: 176px;
		max-height: 319px; // height of 7 and half result
		max-width: 400px;
		@media (min-width: 1920px) {
			max-width: 496px;
		}
		:global {
			.ant-select-item-option {
				height: 42px;
				padding: 12px 16px;
				.ant-select-item-option-content {
					color: var(--text-primary);
					font: var(--font-label);
					overflow: hidden !important;
					text-overflow: ellipsis !important;
					white-space: nowrap !important;
				}
			}
			.ant-select-item-option-active {
				background-color: var(--layer-hover-03);
			}
			.rc-virtual-list-scrollbar-thumb {
				background-color: var(--scrollbar-color);
			}
		}
	}
}
.newLabelWithLine {
	:global {
		.ant-select-item-option:first-child {
			border-bottom: var(--border-subtle-solid);
		}
	}
}

.newLabel {
	border: 1px solid var(--layer-03);
	border-radius: 2px;
	height: 24px;
	display: inline-flex;
	align-items: center;
	gap: 4px;
	padding: 3px 6px 3px 4px;
	background-color: transparent;
	color: var(--text-primary);
	cursor: pointer;
	&:hover {
		background-color: var(--layer-hover-01);
	}
}

.static {
	&:global(.ant-tag) {
		color: var(--text-tertiary);
	}
}

.active {
	&:global(.ant-tag) {
		background: var(--pastel-navy);
		color: var(--text-on-color);
	}
}
