.genemod-editfield {
	color: white;
	width: 100%;

	.edit-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 31px;

		> input {
			background-color: transparent;
			border: none;
			outline: none;
			height: 100%;
		}

		.required {
			font-size: 12px;
			display: flex;
			align-items: center;
			margin-right: 5px;
			color: #af2d21;
		}

		.buttons {
			display: flex;

			> button {
				margin-right: 5px;
				opacity: 0.8;
				transition: 0.3s;

				> i {
					display: flex;
					align-items: center;
				}

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.display-text {
		cursor: pointer;
	}
}
