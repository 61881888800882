.avatarGroup {
	position: relative;
	gap: 2px;
}

.avatar {
	border-radius: 50%;

	:global(.ant-avatar-string) {
		// Center the text which is shifted by borders :)
		margin-top: -1px;
	}
}

.flex {
	display: flex;
	align-items: center;
}

.manageUsersPopover {
	:global(.ant-popover-arrow) {
		display: none;
	}

	:global(.ant-popover-inner) {
		background: transparent;
	}

	:global(.ant-popover-inner-content) {
		border: 1px solid var(--border-subtle);
		background-color: var(--layer-02);
		box-shadow: var(--shadow-container);
		border-radius: 4px;
		padding: 0px;
	}
}
.addPeopleContainer {
	border-radius: 50%;
	border: 1px solid var(--text-secondary);
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}

.manageUsers {
	height: 312px;
	width: 280px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-top: 16px;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 4px;

	.userSearchContainer {
		padding-right: 12px;

		.userSearch {
			border-radius: 4px;
			margin-bottom: 12px;
		}
	}

	.userRows {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		overflow-y: auto;
		padding-right: 8px;
	}

	.userRow {
		height: 48px;
		padding: 12px 0;
		display: flex;
		gap: 12px;

		&Name {
			flex: 1 1 auto;
		}
	}
}
