.auth-link,
.auth-link:hover {
	color: var(--link-primary);
	font-weight: bold;
	text-decoration: underline;
	white-space: nowrap;

	&.auth-linkPlain,
	&.auth-linkPlain:hover {
		color: var(--link-primary);
		font-weight: normal;
	}
}

.auth-flexRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.auth-centerRow {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.auth-body {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 510px;
}
