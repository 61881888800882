.segmented {
	padding: 2px 4px;

	:global {
		.ant-segmented-item {
			.ant-segmented-item-label {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.ant-segmented-item-selected {
			background-color: var(--layer-02-v2);
			* {
				color: var(--text-primary-v2) !important;
			}
		}
		.ant-segmented-thumb {
			background-color: var(--layer-02-v2);
		}
	}
}
:global {
	.ant-segmented {
		background-color: var(--layer-accent-01-v2);
	}
}
