.commentsTab {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding-bottom: 32px;
	padding-top: 16px;
}

.commentTextArea {
	display: flex;
	align-items: start;
	flex-direction: row;
	gap: 8px;

	.textAreaAndButtons {
		position: relative;
		width: 368px;

		:global {
			.resize.body {
				font-size: 13px;
				line-height: 18px;
				tab-size: 12;
				@media (min-width: 1920px) {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		.textArea {
			&:focus-within {
				outline: 2px solid rgb(5 29 133 / 10%);
				border-radius: 4px;
			}

			div {
				margin: 0;
				font-size: 13px;
				line-height: 18px;
				tab-size: 12;
				&::before {
					display: none;
				}
				@media (min-width: 1920px) {
					font-size: 14px;
					line-height: 20px;
				}
			}
			.slateTextAreaEditable {
				&:focus-within {
					border: 1px solid var(--border-active);
				}
				border-radius: 4px;
				min-height: 36px !important;
				padding: 8px 32px 6px 16px;
			}
		}
		.saveCommentButton {
			position: absolute !important;
			bottom: 6px;
			right: 6px;
		}
	}
}

.commentCardWrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	.commentCard {
		display: flex;
		flex-direction: row;
		gap: 12px;
		.rightSide {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 360px;
			.userInfo {
				display: grid;
				grid-template-columns: 1fr max-content 1fr;
				gap: 12px;
				width: fit-content;
				align-items: center;
			}
			.commentCardButtons {
				display: flex;
				flex-direction: row;
				gap: 12px;
			}
		}
	}
	.confirmMessage {
		margin-bottom: 0;
	}
	&:has(.confirmMessage) {
		.commentCardButtons {
			display: none !important;
		}
	}
	.readOnly {
		span {
			color: var(--text-secondary-v2);
		}
	}
}

.buttons {
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-left: 36px;
	margin-top: 12px;
}
