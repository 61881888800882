.genemod-checkbox-container {
	height: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
	transition: all 0.2s ease-in-out;

	.checkbox-icon {
		height: 14px;
		width: 14px;
		min-width: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: var(--border-subtle-solid);
		border-color: var(--text-tertiary);
		border-radius: 2px;
		.minus {
			svg {
				transform: translateX(1px);
			}
		}
	}

	.checkbox-label {
		color: var(--text-secondary);
		user-select: none;
		text-align: left;
		cursor: pointer;
		font: var(--font-label);
		letter-spacing: 0.01px;
		width: calc(100% - 18px);
	}

	&__disabled {
		opacity: 0.45;
	}
}
