.container {
	:global {
		.ant-input,
		.ant-input-outlined .ant-input-affix-wrapper {
			background-color: var(--layer-02-v2);
			border: 1px solid var(--border-subtle-v2);
			color: var(--text-secondary-v2);
			&:hover {
				border-color: var(--border-hover);
			}
			&:focus {
				box-shadow: 0 0 0 2px var(--border-subtle-v2);
				border-color: var(--border-hover);
			}
			&::placeholder {
				color: var(--text-placeholder);
			}
		}
		.ant-input-group-addon {
			background-color: var(--layer-02-v2);
			border: 1px solid var(--border-subtle-v2);
		}

		.ant-input-prefix {
			z-index: initial;
			margin-inline-end: 8px;
		}

		.ant-input-affix-wrapper {
			background-color: var(--layer-03);
			border: 1px solid var(--border-subtle-v2);
			.ant-input {
				color: var(--text-secondary-v2);
				padding: 4px 11px;
				border-radius: 8px;
				border: none !important;
			}
			.ant-input-suffix {
				color: var(--text-secondary-v2);
			}
			&-focused {
				border-color: var(--border-active);
				box-shadow: 0 0 0 2px var(--border-subtle-v2);
			}
		}
	}
}

.disabled {
	:global(.ant-input-disabled) {
		color: var(--text-disabled) !important;
		background-color: var(--action-disabled) !important;
		border-color: var(--border-subtle-v2) !important;
		> input {
			background-color: var(--action-disabled) !important;
			border-color: var(--border-subtle-v2) !important;
		}
	}
	:global(.ant-input-group-addon) {
		background-color: var(--action-disabled) !important;
	}
}

.error {
	:global(.ant-input) {
		border-color: var(--error) !important;
	}
}

.enterButton {
	:global(.ant-input-group-addon) {
		background-color: var(--action-default);
		border: none;
		cursor: pointer;
	}
	:global(.ant-input) {
		border-right: none;
	}
}

.borderless {
	:global(.ant-input) {
		background-color: transparent;
		border: none;

		&:hover {
			background-color: var(--layer-hover-01-v2);
		}

		&:focus {
			box-shadow: 0 0 0 2px var(--border-subtle-v2);
			border: 1px solid var(--border-active);
		}
	}
}

.withPrefix {
	:global(.ant-input) {
		padding-left: 34px !important;
	}
}
