$button-disabled: 0.7;
.genemodButton.genemodButton {
	display: flex;
	align-items: center;
	width: auto;

	// border/shadow
	border: none;

	// default font
	font: var(--font-label);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.01px;

	z-index: 0;

	// Helper to handle setting up color/background for different button modes.
	@mixin setupColor(
		$color,
		$backgroundColor,
		$hoverBackground,
		$hoverColor: $color
	) {
		color: $color;
		background-color: $backgroundColor;

		.loadingSpinner {
			fill: $color;
		}

		// antd has an internal hover selector that overrides our colors if we don't explitly define hover colors
		&:hover:not(.disabled) {
			color: $hoverColor;
			background-color: $hoverBackground;
		}
	}

	@include setupColor(
		var(--text-inverse),
		var(--button-primary),
		var(--button-hover-primary)
	);

	// ~~~~ BUTTON OPTIONS ~~~~

	&.defaultSize {
		height: 32px;
		padding: 0px 32px;
		border-radius: 16px;
	}

	&.smallSize {
		height: 24px;
		padding: 0px 24px;
		border-radius: 12px;

		&.icon {
			padding-left: 12px;
		}
	}
	&.squared {
		border-radius: 3px;
		padding-left: 12px;
		padding-right: 12px;
	}

	&.disabled {
		color: var(--text-tertiary);
		background-color: var(--button-disabled);
		pointer-events: none;
		cursor: not-allowed;

		.loadingSpinner {
			fill: var(--text-tertiary);
		}
	}

	&.stretch {
		width: 100%;
		.GenemodButtonLayout {
			margin: auto;
		}
	}

	// ~~~~ BUTTON TYPES ~~~~

	&.ghost {
		border: 1px solid var(--button-primary);
		font-weight: var(--font-weight-regular);

		@include setupColor(
			var(--button-primary),
			transparent,
			var(--button-primary),
			var(--text-inverse)
		);
		&.disabled {
			border-color: var(--button-disabled);
		}
		&:hover {
			.buttonIcon__ghost {
				fill: var(--text-inverse);
				stroke: var(--text-inverse);
			}
		}
	}

	// NOTE: link is always small
	&.link {
		box-shadow: none;
		font-weight: var(--font-weight-regular);
		padding: 0px 4px;
		&.icon {
			padding-left: 4px;
		}
		@include setupColor(
			var(--link-primary),
			transparent,
			transparent,
			var(--link-primary-hover)
		);
		&:hover {
			.buttonIcon__link {
				fill: var(--link-primary-hover);
				stroke: var(--link-primary-hover);
			}
		}
		&.disabled {
			color: var(--button-disabled);
		}
	}

	// NOTE: link is always small
	&.text {
		box-shadow: none;
		font-weight: var(--font-weight-regular);
		padding: 0px 4px;
		&.icon {
			padding-left: 4px;
		}
		@include setupColor(
			var(--button-text),
			transparent,
			transparent,
			var(--button-hover-text)
		);
	}

	&.danger {
		@include setupColor(
			var(--text-on-color),
			var(--button-primary-danger),
			var(--button-hover-danger)
		);
		&.disabled {
			color: var(--text-tertiary);
			background-color: var(--button-disabled);
			pointer-events: none;
			cursor: not-allowed;
		}
	}

	&.layer {
		border: var(--border-subtle-solid);
		font-weight: var(--font-weight-regular);
		@include setupColor(
			var(--text-secondary),
			var(--next-layer),
			var(--next-layer-hover)
		);

		&.disabled {
			border-color: var(--button-disabled);
		}
	}

	// inner layout inside the ant button
	.GenemodButtonLayout {
		align-items: center;
		align-content: center;
		justify-content: center;
		display: flex;
		width: 100%;
		z-index: 3;

		.buttonTextLayout {
			display: block;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			&__loading {
				visibility: hidden;
			}

			&__disabled {
				color: var(--text-ghost);
			}
		}

		.imageFile {
			width: 18px;
			height: 18px;
			margin-right: 4px;
		}

		.imageFile > * {
			width: 18px;
			height: 18px;
		}
	}

	:global {
		.ant-spin-nested-loading {
			max-width: 100%;
		}
		.ant-spin-container::after {
			content: none;
		}
		.ant-spin-blur {
			opacity: 1;
		}
	}
}

.demoDisplay {
	display: flex;

	.buttonLayout {
		margin-right: 24px;

		.layerContainer {
			padding: 16px;
		}
	}
}

.loadingSpinner {
	animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.buttonIcon {
	&__primary {
		fill: var(--text-inverse);
		stroke: var(--text-inverse);
	}
	&__ghost {
		fill: var(--button-primary);
		stroke: var(--button-primary);
	}
	&__text {
		fill: var(--button-text);
		stroke: var(--button-text);
	}
	&__link {
		fill: var(--link-primary);
		stroke: var(--link-primary);
	}
	&__danger {
		fill: var(--text-on-color);
		stroke: var(--text-on-color);
	}
	&__layer {
		fill: var(--text-secondary);
		stroke: var(--text-secondary);
	}
	&__disabled {
		fill: var(--text-tertiary) !important;
		stroke: var(--text-tertiary) !important;
	}
}
