.gradientContainer {
	$page-background-gradient: rgba(var(--background-rgb), 0);
	$layer-01-gradient: rgba(var(--layer-01-rgb), 0);
	$layer-02-gradient: rgba(var(--layer-02-rgb), 0);
	$layer-03-gradient: rgba(var(--layer-03-rgb), 0);

	.topGradient {
		z-index: 10;
		position: sticky;
		top: -1px;
		&__background {
			background-image: linear-gradient(
				var(--background),
				$page-background-gradient
			);
		}
		&__layer-01 {
			background-image: linear-gradient(
				var(--layer-01),
				$layer-01-gradient
			);
		}
		&__layer-02 {
			background-image: linear-gradient(
				var(--layer-02),
				$layer-02-gradient
			);
		}
		&__layer-03 {
			background-image: linear-gradient(
				var(--layer-03),
				$layer-03-gradient
			);
		}
	}
	.bottomGradient {
		position: sticky;
		bottom: -2px;
		border-radius: 6px;
		z-index: 10;
		&__background {
			background-image: linear-gradient(
				$page-background-gradient,
				var(--background)
			);
		}
		&__layer-01 {
			background-image: linear-gradient(
				$layer-01-gradient,
				var(--layer-01)
			);
		}
		&__layer-02 {
			background-image: linear-gradient(
				$layer-02-gradient,
				var(--layer-02)
			);
		}
		&__layer-03 {
			background-image: linear-gradient(
				$layer-03-gradient,
				var(--layer-03)
			);
		}
	}
}
.gradientDemoWrapper {
	height: 240px;
	width: 400px;
	position: relative;
	overflow: hidden;
	overflow-y: scroll;
}
