$border-color: var(--border-subtle);
.demo {
	border: 2px solid $border-color;
	border-radius: 6px;
	padding: 10px;
	position: relative;
	margin-bottom: 20px;
	width: fit-content;
	flex: 1 1 100%;
	color: var(--text-secondary);
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--text-primary);
	}
}
.demoThemeToggle {
	border-radius: 50%;
	width: 60px;
	height: 60px;
	border: 2px solid var(--border-subtle);
	position: absolute;
	top: 32px;
}
.demoContent {
	padding: 10px;
}

.demoDescription {
	border-top: 1px solid $border-color;
	padding: 10px;
}

.demo-section {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--text-primary) !important;
	}
}

.demo-section-content {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	color: var(--text-secondary);
}

.demoDescriptionHeader {
	color: var(--text-primary);
}

.demoWrapper {
	height: 100%;
	width: 100%;
	display: flex;
}

.wrapperLeft {
	flex: 0 0 80%;
	height: 100%;
}

.wrapperRight {
	flex: 0 0 20%;
	padding: 10px;
	display: flex;
	flex-direction: column;
}
