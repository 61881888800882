.row {
	display: flex;
	justify-content: space-between;

	.half {
		width: calc(50% - 12px);
	}
}

.flexrow {
	display: flex;
	align-items: center;
}

// Change plan modal
.changePlanModal {
	:global {
		.ant-modal-body {
			border-radius: 8px;
		}

		.ant-modal-content {
			padding: 0px !important;
			width: 100%;
		}

		.ant-modal-footer {
			display: none;
			margin-top: 0px !important;
		}
	}

	.changePlanContainer {
		width: 100%;
		display: flex;

		.exit {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			margin-bottom: 40px;
		}

		.left {
			width: 55%;
			padding: 32px 48px;
		}

		.right {
			border-left: var(--border-subtle-solid);
			padding: 32px 48px;
			width: 45%;

			.billAmount {
				padding-bottom: 15px;
				border-bottom: 1px solid var(--text-secondary);
			}

			.subtotalAndDetails {
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 15px 0px;
				border-bottom: 1px solid var(--text-secondary);
			}

			.dueToday {
				padding: 15px 0px 42px 0px;
			}
		}
	}
}

.messageContainer {
	display: flex;
	flex-direction: row;
	border-width: 1px;
	border-style: solid;
	border-color: var(--yellow);
	border-radius: 4px;
	width: 100%;
	padding: 16px 16px 16px 12px;
	margin-bottom: 12px;
	gap: 12px;

	.messageIcon {
		margin-top: 3px;
		width: 24px;
		height: 24px;
	}
}
