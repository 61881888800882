.filters {
	display: flex;
	column-gap: 16px;
	justify-content: space-between;
	position: relative;
	margin-bottom: 32px;
	margin-right: 24px;

	.left {
		display: flex;
		column-gap: 16px;
	}
}

.orderList {
	overflow-y: hidden;
}

.cards {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
	height: calc(100vh - 300px);
	overflow: scroll;
}

.cardPlaceholder {
	width: 100%;
	height: 136px;
	padding: 16px;
	margin-bottom: 12px;
	border-radius: 4px;
	background-color: rgba(68, 144, 87, 0.05);
}

.demo {
	display: flex;
	flex-direction: column;
	// Subtract height of the header
	height: calc(100vh - 227px);
	padding-right: 24px;
	padding-bottom: 48px;
	position: relative;
}

.demoImage {
	flex: 1;
	overflow: hidden;

	img {
		width: 100%;
	}
}

.pageNumbersWrapper {
	display: flex;
	height: 48px;
	justify-content: center;
	align-items: center;
}
