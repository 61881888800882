.shareLinkContainer {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	gap: 16px;
	.copyText {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}
}
