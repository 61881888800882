@keyframes loading {
	0% {
		left: var(--animation-start);
	}
	100% {
		left: var(--animation-end);
	}
}

.genemodSkeleton {
	position: relative;
	overflow: hidden;
	background-color: rgba(var(--neutral-gray-rgb), 0.1);

	&::after {
		content: "";
		position: absolute;
		height: 100%;
		width: var(--loading-width);
		top: 0;
		left: 0;
		background: linear-gradient(
			to right,
			rgba(var(--neutral-gray-rgb), 0),
			rgba(var(--neutral-gray-rgb), 0.3),
			rgba(var(--neutral-gray-rgb), 0)
		);
		animation: loading var(--loading-speed) linear infinite;
		border-radius: 4px;
	}

	&__circle {
		border-radius: 50%;

		&::after {
			border-radius: 50%;
		}
	}
	&__round {
		border-radius: 1em;
	}
	&__default {
		border-radius: 2px;
	}
}

.typography {
	border-radius: 4px;
	background-color: rgba(var(--neutral-gray-rgb), 0.3);
}

.circle {
	border-radius: 50%;

	&::after {
		border-radius: 50%;
	}
}

.title {
	height: 24px;
}

.headline {
	height: 16px;
}

.body {
	height: 12px;
}

.caption {
	height: 8px;
}

.large {
	height: 32px;
	width: 32px;
}

.medium {
	height: 24px;
	width: 24px;
}

.small {
	height: 18px;
	width: 18px;
}
