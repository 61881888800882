.genemod-payment-form {
	.StripeElement {
		height: 36px;
		background-color: var(--current-layer-input-background);
		padding: 10px 16px;
		margin-bottom: 24px;
		vertical-align: middle;
		position: relative;
		transition: 0.2s;
		border-radius: 4px;
		border: var(--border-subtle-solid);

		&:before {
			content: "";
			width: 100%;
			height: 2px;
			background-color: var(--border-active);
			position: absolute;
			bottom: 0;
			left: 0;
			opacity: 0;
			transition: 0.2s;
		}

		&--focus {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			&:before {
				opacity: 1;
			}
		}

		&--invalid {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			border: 1px solid var(--red);
			&:before {
				opacity: 1;
				background-color: var(--red);
			}
		}
	}
}
