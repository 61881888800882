.container {
	padding: 32px 24px 40px 32px;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 32px;
		justify-content: space-between;
	}

	.headerBlock {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.displayWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}

.content {
	&__preview {
		pointer-events: none;
	}

	.boxes {
		width: 100%;
		display: grid;
		background-color: var(--layer-02);
		border-radius: 8px;
		padding: 16px;
		column-gap: 16px;
		row-gap: 16px;
	}
}

.boxCard {
	height: 68px;
	border-radius: 8px;
	border: 1px solid var(--border-subtle-v2);
	border-radius: 8px;

	&:hover {
		border: 1px solid var(--button-primary);
	}

	// For preview let's replace the border with a custom svg border that scales better
	&__preview {
		border-width: 0px;
		background-color: var(--layer-accent-01);
	}

	// When a box exists at the BoxCard location
	&__occupied {
		background-color: var(--gray-02);
		border: none;
		padding: 12px;
		cursor: not-allowed;
		display: grid;
		place-items: center;
		overflow: hidden;

		// remove border set above in preview
		background-image: none;

		&:hover {
			border: none;
		}
	}

	// Styling for unoccupied spaces
	&:not(.boxCard__occupied) {
		cursor: pointer;

		// Always show the icon if unoccupied
		&:not(.boxCard__placed) .iconContainer,
		&:hover .iconContainer {
			opacity: 1;
		}
	}

	.boxName {
		text-align: center;
		display: -webkit-box !important;
		word-break: break-word;
		white-space: pre-wrap;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		line-height: 20px;
	}

	.iconContainer {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
	}

	// A box has been placed at this BoxCard
	&__placed {
		background-color: var(--layer-03);
		cursor: pointer;

		.iconContainer {
			display: none;
			opacity: 100%;
		}

		&:hover {
			border: 2px solid var(--button-primary);
			.boxName {
				display: none !important;
			}
			.iconContainer {
				display: flex;
			}
		}
	}

	&__disabled:not(.boxCard__placed) {
		opacity: var(--disabled-opacity);
		pointer-events: none;
	}

	&__stretch {
		width: 151px;
	}
}

.navigationItem {
	cursor: pointer;
	max-width: 100px;
}
