/**
* proportionalDivWrapper and proportionalDivInner are used in together to make a div with height proportional to width.
* Source: https://www.milessebesta.com/web-design/scaling-div-height-proportional-to-width-trick/
* Will be deprcated once enough browsers support "aspect-ratio": https://caniuse.com/mdn-css_properties_aspect-ratio
*/
@mixin proportionalDivWrapper($heightPercOfWidth: 33%) {
	width: 100%;
	background-size: contain;
	position: relative;
	padding-bottom: $heightPercOfWidth;
}
@mixin proportionalDivInner() {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	display: block;
}

/**
* Apply solid border line on absolute position using ::before
* It is because Safari doesn't support border-radius on Outline (11.01.22)
* Alos, If we using border instead of outline, we have to reset padding because of content shift
* To solve both issues, To apply border with absolute on ::before.
*/
@mixin absoluteBorder(
	$borderColor: var(--border-active),
	$borderRadius: 8px,
	$borderWidth: 2px,
	$borderZIndex: 1
) {
	border: none;
	outline: none;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: $borderWidth solid $borderColor;
		border-radius: $borderRadius;
		pointer-events: none;
		z-index: $borderZIndex;
	}
}

/** Apply link style */
@mixin linkStyle($color: var(--link-primary)) {
	> a {
		color: $color;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

/** Fade in animation */
@mixin fadeIn($duration: 0.3s, $delay: 0s) {
	animation: fadeIn $duration $delay forwards;
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
