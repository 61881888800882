.upgradeModal {
	:global {
		.ant-modal-content {
			padding: 0px !important;
			.ant-modal-body {
				border-radius: 8px;
			}
		}
	}
	.closeIcon {
		background-color: var(--layer-03);
		width: 32px;
		height: 32px;
		border-radius: 50%;
		position: absolute;
		top: -16px;
		right: -16px;
		box-shadow: var(--shadow-container);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.upgradeImage {
		width: 100%;
		height: 260px;
		object-fit: cover;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	.content {
		padding: 32px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		border-top: var(--border-subtle-solid);
	}
}
