.modal {
	:global(.ant-modal-content) {
		padding: 0 !important;
	}

	:global(.ant-modal-body) {
		border-radius: 8px !important;
	}

	:global(.ant-modal-close) {
		top: -16px;
		right: -16px;
		background: var(--layer-03);
		box-shadow: var(--shadow-container);
		border-radius: 50%;
		:global(.ant-modal-close-x) {
			width: 32px;
			height: 32px;
			> div {
				top: 4px !important;
				right: 4px !important;
			}
		}
	}
}

.content {
	padding: 32px 32px 8px 32px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.img {
	width: 100%;
	height: 240px;
	object-fit: cover;
	border-radius: 8px 8px 0px 0px;
}

.footerButton {
	width: 126px !important;
}
