.slots {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.rollContainer {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin: 30px 0;
}

.roll {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 60px;
	width: 100px;
	height: 100px;
	border: 2px solid gray;
}

.hideCloseButton {
	:global .ant-modal-close {
		display: none;
	}
}
