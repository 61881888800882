.itemInfo {
	height: 100%;
	width: 100%;
	border-radius: 16px;

	.spin {
		height: 100%;
		width: 100%;

		> :global(.ant-spin-container) {
			padding: 24px 0;
		}
	}

	:global {
		.ant-spin-nested-loading,
		.ant-spin-container {
			height: 100%;
			width: 100%;
		}
	}

	.header {
		padding: 0 32px;
		margin-bottom: 16px;
		display: flex;
		gap: 8px;
		align-items: center;

		.itemName {
			font: var(--headline3-v2);
			// Make the floating buttons visible
			z-index: 1;
			// Align with the header
			margin-left: -8px;
			min-width: 1px;

			> div {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.tabs {
		flex: 1;
		// Height must be applied for the scrollbar to appear within
		// the tabs content.
		height: calc(100% - 102px); // Subtract height of the header

		:global {
			.ant-tabs {
				height: 100%;
				display: flex;
				flex-direction: column;
			}
			.ant-tabs-tab:first-child {
				margin-left: 32px !important;
			}
			.ant-tabs-content-holder {
				flex: 1;
				height: 100%;
				overflow: auto;
			}
		}
	}

	.activityContainer {
		padding: 6px 32px;
	}
}
