.main {
	background-color: var(--layer-02-v2);
	border-radius: 8px;
	padding: 12px 16px;
}

.arrow {
	background-color: var(--layer-03-v2);
	height: 32px;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;

	& > div {
		transition: transform 0.2s ease;
	}
}

.arrowEnd {
	background-color: var(--layer-03-v2);
	height: 32px;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin-left: auto;

	& > div {
		transition: transform 0.2s ease;
	}
}

.flex {
	display: flex;
	gap: 12px;
	align-items: center;
}

.teamCard {
	padding: 8px 12px 8px 8px;

	&:not(:last-child) {
		border-bottom: 1px solid var(--border-subtle-v2);
	}
}

.collapsed {
	height: 0;
	visibility: hidden;
}

.expanded {
	height: min-content;
	visibility: visible;
}

.arrowExpanded {
	transform: rotate(90deg);
}

.verticleCenter {
	display: flex;
	align-items: center;
}

.disabledCheckbox {
	opacity: 0.4;
}
