.box-reagent-settings {
	.disabled {
		opacity: var(--disabled-opacity);
	}
	.sidePanel-infoView-leftSide {
		width: 144px !important;
	}
	#edit-description-label {
		margin-bottom: 8px;
	}
}

.item-list-settings {
	height: 100%;

	.item-list-settings-content {
		height: calc(100% - 32px);
		width: 100%;
		overflow-y: scroll;

		.table-customize-header {
			margin-top: 8px;
			margin-bottom: 16px;
		}
	}

	.item-list-settings-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
