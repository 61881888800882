.container {
	height: var(--navbar-height);
	width: 100%;
	background-color: var(--background);
	color: var(--text-primary);
	display: flex;
	align-items: center;
	padding: 0 24px;
	z-index: 1000;
	position: relative;
	border-bottom: 1px solid var(--border-subtle-v2);

	:global(.genemod-icon) {
		& div {
			transform: none;
		}
	}

	& svg {
		height: 16px !important;
		width: 16px !important;
	}
}

.navbarDividerContainer {
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbarDivider {
	width: 1px;
	height: 100%;
	margin: 0px;
	background-color: var(--border-invisible-v2);
	border: none;
}
