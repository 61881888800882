.checkboxWrapper{
    display: flex;
    gap: 8px;
    align-items: center;
}

.checkboxInput{
    display: none;
    &:checked{
        height: 10px;
        & ~ .checkbox{
           & .icon{
            visibility: visible;
           }
        }
    }
}

.checkbox{
    height: 14px;
    aspect-ratio: 1;
    border: 1px solid var(--text-tertiary);
    position: relative;
    border-radius: 2px;
    cursor: pointer;
}

.icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 1px), -50%);
    visibility: hidden;
}