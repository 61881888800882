.main {
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
}

.option {
	display: flex;
	height: 42px;
	width: 153px;
	align-items: center;
	justify-content: center;

	:global(.checkbox-label) {
		display: flex;
		align-items: center;
	}
}

.filterPopover {
	:global(.ant-popover-arrow) {
		display: none;
	}

	:global(.ant-popover-inner) {
		background: transparent;
	}

	:global(.ant-popover-inner-content) {
		box-shadow: none;
		padding: 0px;
		margin-top: -10px;
		background: var(--layer-03);
		padding: 4px 0;
		border-radius: 4px;
		border: var(--border-subtle-solid);
	}
}
