.actions {
	display: flex;
	align-items: center;
}

.modal {
	:global(.ant-modal-content) {
		padding: 0;
	}

	:global(.ant-modal-title) {
		padding: 32px 32px 0px 0px;
	}

	:global(.ant-modal-footer) {
		padding: 0px 32px 32px 32px;
	}
}

.list {
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-bottom: 1px solid var(--border-invisible);
}
