.shelfView {
	border: 1px solid var(--border-subtle);
	background-color: var(--layer-02);
	border-radius: 16px;
	overflow: hidden;
	height: 100%;
	max-width: 733px;
	margin-right: 8px;
}

.title {
	background-color: var(--layer-03);
	padding: 0 16px;
	height: 56px;
	display: flex;
	gap: 7px;
	align-items: center;
	border-bottom: 1px solid var(--border-subtle);
}

.shelfTable {
	padding-bottom: 40px;
	height: 100%;
	overflow-y: auto;
}

.left {
	grid-column: span 5;
	overflow: hidden;

	@media (min-width: 1920px) {
		grid-column: 2 / span 4;
	}
}

.clickToEdit {
	min-width: 120px;
}
