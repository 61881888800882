.guidance {
	background: var(--layer-accent-01-v2);
	padding: 24px;
	border-radius: 16px;
	min-width: 440px;
	max-width: 440px;
	height: fit-content;
	height: fit-content;
}

.legends {
	display: flex;
	gap: 24px;
}

.commonBox {
	border: var(--border-invisible-solid);
	padding: 4px;
}

.reactangleBox {
	width: 154px;
	height: 111px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	div {
		padding-top: 28px;
	}
}

.squareBox {
	width: 104px;
	height: 94px;
}
