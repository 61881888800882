@use "common/styles/mixins";

.card {
	width: calc((100% - 16px) / 3);
	margin-right: 8px;
	margin-bottom: 16px;
	border-radius: 4px;
	background-color: var(--layer-03);
	height: 108px;
	box-shadow: 0 2px 3px var(--shadow-container-color);

	&:hover,
	&__focused {
		@include mixins.absoluteBorder(var(--border-active), 4px);
		.options {
			opacity: 1;
		}
	}

	&:nth-of-type(3n) {
		margin-right: 0px;
	}

	@media (min-width: 1920px) {
		width: calc((100% - 72px) / 4);
		height: 136px;
		margin-right: 24px;
		&:nth-of-type(3n) {
			margin-right: 24px;
		}
		&:nth-of-type(4n) {
			margin-right: 0px;
		}
	}
}

.body {
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
}

.nameContainer {
	padding: 12px 16px 8px 16px;
	@media (min-width: 1920px) {
		padding: 24px 16px;
	}
}

.name {
	overflow-wrap: break-word;
	display: -webkit-box !important;
	white-space: pre-wrap;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 32px;
	padding: 0px 16px;
	border-radius: 0px 0px 4px 4px;
	border-top: 1px solid var(--border-subtle);

	.bottomText {
		color: var(--text-secondary);
	}
}

.options {
	position: absolute;
	top: 4px;
	right: 4px;
	opacity: 0;
}
