.interconectivityLinkTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
}

.interconectivityLinkContainer {
	display: flex;
	gap: 8px;
	align-items: flex-start;
	margin: 4px 0;
	width: min-content;
}

.interconectivityLink {
	position: relative;
	width: fit-content;
}

.interconectivityLinkTooltip {
	border-radius: 8px;
	background: var(--current-layer);
}

.interconectivityLinkText {
	max-width: 60vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.linkList {
	padding: 0;
}

.linkIcon {
	background: var(--current-layer);
	min-width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.linkTitle {
	width: 184px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.linkTitleContainer {
	display: flex;
	gap: 12px;
	align-items: center;
	margin-bottom: 12px;
}

.linkParent {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.itemParent {
	flex: 1;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 7px 8px;
	gap: 4px;
	min-height: 32px;
	text-align: left;
}

.itemIcon {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 2px 0px;
}

.itemText {
	flex: 1;
	position: relative;
	line-height: 18px;
}

.popover {
	:global(.ant-popover-inner) {
		padding: 16px;
		background: var(--current-layer);
	}
	:global {
		.ant-popover-content::before {
			content: "";
			display: block;
			width: 16px;
			height: 8px;
			position: absolute;
			top: 100%;
			left: 32px;
			background-color: var(--layer-02);
			box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
				0 3px 6px -4px rgba(0, 0, 0, 0.12),
				0 9px 28px 8px rgba(0, 0, 0, 0.05);
			clip-path: polygon(100% 0, 0 0, 50% 100%);
		}
		.ant-popover-arrow {
			display: none;
		}
	}
}
