.genemodDropdown {
	&__isCompact {
		:global {
			.ant-dropdown-menu-item,
			.ant-menu-item {
				height: 42px;
			}
			.ant-dropdown-menu {
				border-radius: 2px;
				padding: 4px 0;
			}
		}
	}

	&:global(.ant-dropdown-menu-submenu-popup) {
		ul {
			margin-left: -0.3em;
		}
	}

	:global {
		.ant-menu-vertical {
			background-color: var(--layer-popover);
			padding: 2px;
			border: none;
			.ant-menu-item:not(:last-child) {
				margin-bottom: 0px;
			}
		}

		.ant-dropdown-menu-item,
		.ant-menu-item {
			color: var(--text-secondary-v2) !important;
			background-color: var(--layer-popover);
			border: 1px solid var(--layer-popover);
			padding-left: 16px;
			padding-right: 16px;
			height: 32px;
			margin: 0;
			display: flex;
			align-items: center;
			font: var(--font-label);
			align-self: stretch;
			letter-spacing: 0.01px;
			&:hover {
				background-color: var(--layer-hover-popover) !important;
			}
		}

		.ant-dropdown-menu-item {
			&:hover {
				border-radius: 2px;
			}
		}

		.ant-dropdown-menu-item {
			color: var(--text-secondary-v2);
		}

		.ant-menu,
		.ant-dropdown-menu {
			background-color: var(--layer-popover);
			border-radius: 4px;
			min-width: 144px;
			max-width: 224px;
			padding: 2px;
			box-shadow: var(--shadow-container);
		}

		.ant-dropdown-menu-submenu-title:hover {
			background-color: var(--layer-hover-popover) !important;
		}

		/** Background color after the option gets selected */
		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background-color: transparent;
			&:hover {
				background-color: var(--layer-hover-popover) !important;
			}
		}

		.ant-divider-horizontal {
			display: none;
		}

		.ant-dropdown-menu-submenu-title {
			padding: 5px 16px;
			height: 48px;
			display: flex;
			align-items: center;
			.ant-dropdown-menu-submenu-arrow {
				display: none;
			}
		}

		.ant-dropdown-menu-item-disabled {
			background-color: var(--layer-popover);
			opacity: 0.6;

			&:hover {
				background-color: var(--layer-popover);
			}
		}

		.ant-dropdown-menu-submenu-disabled {
			opacity: 0.6;

			.ant-dropdown-menu-submenu-title {
				background-color: var(--layer-popover);
			}
		}
	}
}
.genemodDropdownIconBg {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 2px;
	background-color: transparent;
	cursor: pointer;
	&:hover,
	&:global(.ant-dropdown-open) {
		background-color: var(
			--genemodDropdownIconBg-hover-color,
			--layer-hover-01
		);
	}
}

// Repeated to increase specificity to override antd menu item link styles
.menuOption.menuOption {
	display: flex;
	gap: 8px;
	align-items: center;
	color: var(--text-secondary);
}

.menuDivider {
	border-top: var(--border-subtle-solid);
	gap: 8px;
}

.dropdown {
	:global(.ant-dropdown-menu-item-divider) {
		background-color: var(--border-invisible-v2) !important;
	}
}
