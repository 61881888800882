.emailTag {
	&:global(.ant-tag) {
		height: 32px;
		border-radius: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: fit-content;
		line-height: normal;
		padding: 0 8px 0 12px;
		margin-right: 8px;
		margin-bottom: 12px;
		background-color: rgba(var(--blue-contrast-rgb), 0.3);
		border: none;
		cursor: pointer;
		&:hover {
			background-color: rgba(var(--blue-contrast-rgb), 0.5);
		}
		:global {
			.ant-tag-close-icon {
				margin-left: 8px !important;
			}
		}
	}
	&__error {
		&:global(.ant-tag) {
			border: 1px solid var(--red);
			background-color: rgba(var(--red-rgb), 0.3);
			&:hover {
				background-color: rgba(var(--red-rgb), 0.5);
			}
		}
	}
}
.chipText {
	max-width: 233px;
}
