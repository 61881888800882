.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	.inner {
		position: absolute;
		.childrenWrapper {
			width: 100%;
			height: 100%;
			transform-origin: left top;
		}
	}
}
