$light-blue: rgba(var(--accent-subtle-rgb), 0.14);

$border-width: 1px;

@mixin cellSelectedBorder {
	box-shadow: inset 0 0 0 $border-width var(--accent-strong) !important;
	-webkit-box-shadow: inset 0 0 0 $border-width var(--accent-strong) !important;
	-moz-box-shadow: inset 0 0 0 $border-width var(--accent-strong) !important;
}

.tableFrame {
	position: relative;
}

.wrapper {
	cursor: default;
	margin: 12px 0;
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;

	&__selected {
		box-shadow: 0px 0px 0px 8px $light-blue;
		width: 100%;
	}

	.addRow {
		position: absolute;
		height: 24px;
		display: flex;
		align-items: center;
		bottom: 10px;
		left: 0px;
		padding-left: 12px;
		user-select: none;
		cursor: pointer;

		> * {
			transition: 0.1s;
			opacity: var(--disabled-opacity);
		}

		&Text {
			opacity: 0;
			margin-left: 8px;
		}

		&:hover {
			> * {
				opacity: 1;
			}

			&Text {
				opacity: 1;
			}
		}
	}
}

.tableContainer {
	width: 100%;
	overflow: hidden;
	overflow-x: auto;
	position: relative;

	table {
		min-width: 100%;
		width: 100%;
		table-layout: fixed;
		position: relative;
	}

	// first column (row headers)
	tr > th:first-child {
		position: sticky;
		left: 0;
		z-index: 5;
		width: 40px;
	}
}

.title {
	display: flex;
	gap: 8px;
	padding: 0;
	align-items: center;
	font: var(--font-label);
	font-weight: var(--font-weight-bold);
	font-size: 14px;
	color: var(--text-secondary);
	height: 32px;

	&:hover {
		.unselectedMenu {
			visibility: visible;
		}
	}
	&.title__function {
		padding: 0 8px;
		gap: 0px;
	}

	&:not(.title__editing) {
		user-select: none;
	}

	// When the table title is not edited
	&:not(.title__editing) .titleText {
		height: 36px;

		// adding ellipsis
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		// making the text centered vertically
		padding-top: 8px;
	}

	&.title__selected {
		background-color: rgba(var(--accent-subtle-rgb), 0.14);
	}

	input {
		color: var(--text-primary);
		border: none;
	}

	&Text {
		// white-space set to pre-wrap so we can type in space between words
		white-space: pre-wrap;
	}

	&Options {
		flex: 0 0 24px;
		padding-left: 8px; // Use this instead of flex gap
	}
}

// ~~~~~ Handle borders ~~~~~
.wrapper {
	&__withinWrapper {
		border: var(--border-subtle-solid);
		border-radius: 4px;
	}

	.tableContainer {
		$tableBottomSpace: 32px; // - 8px; // Subtract scrollbar height

		margin-bottom: 0px;
		padding-bottom: $tableBottomSpace;

		&.tableUnselected {
			border: var(--border-subtle-solid);
			border-radius: 4px;
			margin-bottom: 0px;
			padding-bottom: 0px;
		}

		table {
			border-collapse: collapse;
			border-spacing: 0.5px;
		}

		// ~~~~~ Cell borders ~~~~~
		td,
		th {
			border: var(--border-subtle-solid);
			border-width: 0.5px;
		}

		// There is a bug where the highlighted cell borders overlap the row header borders when scrolled.
		// This is a hacky fix to add an opaque mask behind the row headers to hide scrolled cells underneath.
		.headerBackgroundMask {
			position: absolute;
			width: calc(100% + 1px);
			height: 100%;
			top: 1px;
			left: -1px;
			z-index: -1;
		}

		// Remove borders from perimeter cells so we can wrap the table in its own border

		// remove top border from top row
		&.tableUnselected tr:first-child > * {
			border-top: none;
		}

		// remove left border from first column
		tr > *:first-child {
			border-left: none;
		}

		// remove right border from last column
		tr > *:last-child {
			border-right: none;
		}
	}
}

.title__function {
	background-color: var(--header-cell-fill);

	.titleText {
		flex: 0 0 0px;
		user-select: none;
		padding-left: 8px;
	}
}

.functionBar {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 4px 8px 4px 8px;

	.functionBarDivider {
		height: 100%;
		width: 2px;
		background-color: var(--border-subtle);
	}

	.functionBarContent {
		display: flex;
		align-items: center;
		height: 24px;
		width: 100%;
		background-color: var(--field-01);
	}

	.functionSelector {
		display: flex;
		border-left: var(--border-subtle-solid);
		border-right: var(--border-subtle-solid);
		border-color: var(--border);
		:global(.ant-menu-root) {
			max-width: unset !important;
		}
		background-color: var(--layer-02);
		&:hover {
			background-color: var(--layer-hover-02);
		}
		height: 24px;
		align-items: center;
		border-radius: 3px 0px 0px 3px;
	}

	.functionBarInputContainer {
		display: flex;
		width: 100%;
		align-items: center;
		border-radius: 0px 3px 3px 0px;
		background-color: var(--field-01);
	}

	.dropDown {
		:global {
			.ant-menu,
			.ant-dropdown-menu {
				overflow-y: scroll;
				max-height: 350px;
				max-width: 245px;
			}
		}
	}

	.functionBarInputTypography {
		width: calc(100% - 16px - 38px);
		.functionBarInput {
			margin-left: 4px;
			background-color: transparent;
			border-radius: 3px;
			height: 24px;
			width: 100%;
		}
	}

	.tableSettingsWrapper {
		margin-left: 8px;
		background-color: var(--layer-02);
	}

	.selectedCellIndicator {
		height: 18px;
		padding-left: 4px;
		padding-right: 4px;
		border-radius: 2px;
		background-color: var(--accent-strong);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 4px;
		min-width: 22px;
	}
}

.cell,
.header {
	&:last-child {
		min-height: 33px;
	}
}

.header {
	height: 100%;
	background-color: var(--header-cell-fill);
	opacity: 1;
	text-align: center;
	color: var(--text-tertiary);
	font-weight: normal;
	font: var(--font-label);
	font-size: 12px;
	user-select: none;
	cursor: pointer;
	position: relative;
	padding: 0px;

	&__disabled {
		cursor: initial;
	}

	&__selected {
		background-color: var(--darker-cell-fill);
	}

	&__placeholder {
		min-width: 88px !important;
		display: flex;
		align-items: center;

		> * {
			transition: 0.1s;
			opacity: var(--disabled-opacity);
		}

		&Text {
			opacity: 0;
			margin-left: 8px;
		}

		&:hover {
			* {
				opacity: 1;
			}

			&Text {
				opacity: 1;
			}
		}
	}

	&__resize {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 7px; // This value felt the most user friendly
		cursor: col-resize;
		transform: none !important; // Remove draggable library offset

		&:hover {
			background-color: var(--border-active);
		}
	}

	&:hover {
		.headerMenu {
			opacity: 1;
		}
	}
}

.headerMenu {
	position: absolute;
	opacity: 0;

	&__column {
		left: calc(50% + 6px);
		top: 4px;
	}

	&__row {
		right: 0px;
		top: 4px;
	}
}

.cell {
	cursor: pointer;
	&__disabled {
		cursor: initial;
	}
	min-width: 32px;
	height: 30px;

	position: relative;
	font: var(--font-label);
	font-size: 13px;

	span {
		white-space: pre;
	}
}

.unselectedTablePlaceholderCell {
	width: unset !important;
}

.cell__outer:not(.cell__editing) {
	white-space: pre;
	.cell__inner {
		overflow: hidden;
		caret-color: transparent;

		span,
		br {
			user-select: none;

			&::selection {
				background: transparent;
			}
		}
	}
}
.cell__inner {
	min-width: 2px;
	min-height: 18px;
	padding-left: 4px;

	span,
	br {
		min-width: 2px;
		min-height: 18px;
	}
}

.cell__outer {
	position: relative;
	.hideDueToAlternativeDisplay {
		opacity: 0;
		z-index: 2; // Allows selection events to happen correctly
	}

	.alternativeDisplay {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: transparent;
		user-select: none;
		overflow: hidden;
		z-index: 1;
		pointer-events: none;
	}
}

.cell__editing {
	overflow: visible;
	position: absolute;
	top: 0;
	bottom: -1px;
	left: 0;
	display: flex;
	align-items: center;
	z-index: 2;
	background-color: var(--background);
	@include cellSelectedBorder;

	cursor: text;

	// Add box shadow around the cell when editing
	&:before {
		box-shadow: 0px 4px 15px var(--shadow-overlay) !important;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
	}
}

.cell__selected {
	position: relative;
	background-color: rgb(var(--accent-strong-rgb), 0.2);

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.cell__selected.cell__singleselection:before {
	background-color: transparent !important;
}

.cell__anchor {
	//when using the table in safari, there is a gap between the box shadow and the border, using border instead of box-shadow fixes this
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	@include cellSelectedBorder;
	// border: 3px solid var(--accent-strong) !important;
}

//if a single cell is selected, we can use a box shadow instead of a border, but if multiple cells are selected, we need to use a border otherwise the anchor cell looks smaller than the other cells
.cell__selected.cell__anchor.cell__singleselection {
	@include cellSelectedBorder;

	-webkit-appearance: none !important;
	appearance: none !important;

	//disable the border
	border: none !important;
}

.cell__placeholder {
	cursor: pointer;
	background-color: var(--ghost-cell-fill);
}

.cell__error {
	background-color: transparent;

	// add a triangle to the the top left
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-top: 6px solid var(--accent-strong);
		border-right: 6px solid transparent;
	}
	&:hover .formulaSuggestion {
		visibility: visible;
	}
}

.borderR {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
}
.borderT {
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
}
.borderL {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
}
.borderB {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
}

.formulaSuggestion {
	position: absolute;
	width: max-content;
	top: calc(100% + 4px);
	background-color: var(--layer-hover-03);
	color: var(--text-secondary);
	visibility: hidden;
	padding: 6px 14px;
	border-radius: 4px;
}

.suggestion__selected {
	background-color: var(--layer-hover-03) !important;
}

.placeholder {
	background-color: var(--ghost-cell-fill);
}

.unselectedMenu {
	margin-left: 6px;
	visibility: hidden;
}
