.mentionLine {
	display: flex;
	align-items: center;
	height: 24px;
	padding: 0 8px;
	gap: 8px;
	box-sizing: border-box;
}

.menuDate,
.menuExperiment,
.userRow,
.avatarName {
	@extend .mentionLine;
}

.menuDate .GenemodIcon,
.menuExperiment .GenemodIcon,
.avatarName .GenemodIcon,
.userRow .GenemodIcon {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
}

.userRows {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	padding-right: 8px;
}

.hoverSelect {
	background-color: var(--layer-hover-03) !important;
}

.mentionWrapper {
	position: relative;
	display: inline-block;
}

.mentionContainer {
	display: inline-flex;
	align-items: center;
	border-radius: 2px;
	padding: 2px;
	gap: 2px;
	transition: 0.2s all;
	line-height: 1;
	transform: translateY(-1px);

	&:hover,
	&.hover {
		background-color: var(--layer-hover-02-v2);
	}
}

.invertedIcon {
	flex-direction: row-reverse !important;
}

.userMentionPopoverContainer {
	display: flex;
	padding: 10px 16px;
	background-color: var(--layer-popover);
	align-items: center;
	gap: 12px;
}

.searchIcon {
	margin-right: 3.5px;
	vertical-align: sub;
}

.popover {
	padding-top: 0px;

	:global {
		.ant-popover-inner-content {
			padding: 0px;
			border-radius: 4px;
		}
		.ant-popover-arrow {
			display: none;
		}
	}

	&.disablePopover {
		display: none;
	}
}

.menu {
	max-height: 252px;
	overflow-y: auto;
	scrollbar-gutter: stable;

	&::-webkit-scrollbar-track {
		background: var(--layer-03);
	}
}

.multiMention {
	display: flex;
	align-items: center;
	gap: 5px;
	background-color: var(--layer-01);
	border-radius: 2px;
	padding-right: 4px;
	width: fit-content;
}

.multiMentionDropdown {
	:global(.ant-dropdown-menu) {
		max-width: 400px;
		min-width: 400px;
		max-height: 378px;
		overflow-y: scroll;
		border: none;
		background-color: var(--layer-01);
		scrollbar-gutter: stable;
	}

	:global(.ant-dropdown-menu-item) {
		padding: 0;
	}

	:global(.ant-dropdown-menu-item:hover) {
		background-color: var(--layer-hover-01) !important;
	}
}

.menuDate .GenemodIcon,
.menuExperiment .GenemodIcon {
	color: var(--text-tertiary-v2);
}

.linkPopup {
	display: flex;
	padding: 6px 8px;
	align-items: center;
	gap: 8px;
}

.linkText {
	max-width: 320px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	height: 16px;
}

.avatarName {
	width: 340px;

	> div > span {
		color: var(--text-secondary-v2);
	}
}

.smallVersion {
	:global(.ant-dropdown-menu) {
		max-height: 214px;
	}
}

.blockWrapper {
	display: inline-block;
	transform: translateY(3px);
}

.currentTypePlaceholder {
	white-space: nowrap;

	> div {
		color: var(--text-placeholder);
		line-height: normal;
	}
}
