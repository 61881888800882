.genemodDot {
	border-radius: 50%;
}

.bordered {
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}
