.topSuggestionsWrapper {
	margin-bottom: 32px;
}

.row {
	margin-top: 12px;
	display: flex;
	width: 100%;
	border-radius: 4px;
	cursor: pointer;
	border: solid 1px transparent;
	&:hover {
		border: solid 1px var(--border-active);
		.iconHover {
			display: block;
		}
	}
}
.suggestionsRow {
	cursor: pointer;
	margin-top: 12px;
	padding-bottom: 5px;
	display: flex;
	&:hover {
		.iconHover {
			display: block;
			svg {
				fill: var(--link-primary-hover);
			}
		}
		.suggestions {
			color: var(--link-primary-hover);
		}
	}
}
.header {
	margin-bottom: 32px;
	margin-left: 16px;
	display: flex;
}
.headerIcon {
	margin-top: 8px;
	cursor: default;
}
.icon {
	margin: 12px 8px 33px 12px;
}
.innerTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title {
	margin-top: 13px;
	display: flex;
	margin-bottom: 8px;
}
.iconHover {
	display: none;
	margin-right: 12px;
}
.desc {
	display: flex;
	margin-bottom: 12px;
}

.secondPartSec {
	cursor: pointer;
	display: flex;
	padding-top: 13px;
	padding-bottom: 12px;
	padding-left: 4px;

	&:hover {
		background-color: var(--current-layer-hover);
		border-radius: 4px;
		& .iconHover {
			display: block;
		}
	}

	.redDot {
		margin-top: 8px;
		margin-left: 15px;
	}
}
.secondTitle {
	margin-left: 8px;
}

.footer {
	border-top: var(--border-subtle-solid);
	margin-top: auto;
}
