.itemContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	cursor: pointer;
}

.itemContainer:hover {
	background-color: var(--layer-hover-01-v2);
	border-radius: 4px;
	.spacesAvailableCanHide {
		display: none;
	}
	.selectTextAvailable {
		display: block;
	}
}

.itemContainerDisabled {
	cursor: not-allowed;
}

.itemContentContainer {
	display: flex;
	align-items: center;
	gap: 4px;
}

.spacesAvailable {
	display: block;
}

.selectText {
	display: none;
}

.textHeight {
	line-height: 18px !important;
	max-width: 325px;
}